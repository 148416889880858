<div class="content" [matMenuTriggerFor]="profileDropdown">
    <div class="photo">
        <img [class.placeholder]="!userInfo?.photo"
            [src]="userInfo && userInfo.photo ? staticService.getStaticUrl(userInfo.photo.url) : '/assets/icons/user.svg'">
    </div>
    <div class="details">
        <div class="name">
            <span [style.color]="color === 'black' ? 'black' : 'white'">
                {{ userInfo?.firstName }}
            </span>
            <svg-icon src="assets/icons/chevron-down.svg"
                [svgStyle]="{ 'fill': color === 'black' ? '#DEDCD3' : 'white', 'height.px' : 12 }"></svg-icon>
        </div>
        <div class="role" [style.color]="color === 'black' ? '#5e6369' : 'white'"> {{ userInfo?.roles?.[0]?.publicName
            }}</div>
    </div>
</div>

<mat-menu #profileDropdown="matMenu">
    <ul class="dropdown">
        <li class="dropdown-item" routerLink="/profile">
            <svg-icon src="assets/icons/my_profile.svg" [svgStyle]="{'height.px' : 18 }"></svg-icon>
            <span class="title">{{ 'header.profile.myProfile' | translate }}</span>
        </li>
        <li class="dropdown-item" (click)="onLogout()">
            <svg-icon src="assets/icons/logout.svg" [svgStyle]="{'height.px' : 18 }"></svg-icon>
            <span class="title">{{ 'header.profile.logout' | translate }}</span>
        </li>
    </ul>
</mat-menu>