<div class="content">
  <div class="logo">
    <a [routerLink]="'/'">
      <img
        class="ghost"
        src="assets/images/logo_white.svg"
        alt="logo"
      />
      <img
        class="logoImg"
        [ngClass]="isHamburgerMenuOpen ? 'visible' : color === 'white' ? 'hidden' : ''"
        src="assets/images/logo_color.svg"
        alt="logo"
      />
      <img
      class="logoImg"
        *ngIf="color === 'white' && !isHamburgerMenuOpen"
        [ngClass]="!isHamburgerMenuOpen ? 'visible' : 'hidden'"
        src="assets/images/logo_white.svg"
        alt="logo"
      />
    </a>
  </div>
  <div class="hamburger" (click)="onHamburgerMenu()" [ngClass]="isHamburgerMenuOpen ? 'open '+color : 'closed '+color">
    <span></span>
  </div>
  <div [class]="isHamburgerMenuOpen ? 'menu mobile isOpen' : 'menu mobile'">
    <app-header-menu
        [color]="isHamburgerMenuOpen ? 'black' : color"
      [isAuthenticated]="isAuthenticated"
      [isMobile]="true"
    ></app-header-menu>
  </div>
  <div class="menu desktop" [ngClass]="color === 'black' ? 'black' : 'white'">
    <app-header-menu
      [color]="color"
      [isAuthenticated]="isAuthenticated"
    ></app-header-menu>
  </div>
  <div *ngIf="isAuthenticated" class="icons">
    <app-header-messages
      *ngIf="subscriptionPermissionService.canBeNotified$ | async"
      [color]="color"
    ></app-header-messages>
    <div class="icon search">
      <a routerLink="/board">
        <svg-icon
          src="assets/icons/search.svg"
          [svgStyle]="{ fill: color, 'height.px': 20 }"
        ></svg-icon
      ></a>
    </div>
  </div>
  <div *ngIf="isAuthenticated" class="profile">
    <app-header-profile [color]="isHamburgerMenuOpen ? 'black' : color"></app-header-profile>
  </div>
  <div
    *ngIf="isAuthenticated && permissionService.hasPermission('FullAdmin')"
    class="admin"
    (click)="onAdminClick()"
  >
    <app-header-admin [color]="isHamburgerMenuOpen ? 'black' : color"></app-header-admin>
  </div>
  <div *ngIf="!isAuthenticated" class="login">
    <app-header-login [color]="isHamburgerMenuOpen ? 'black' : color"></app-header-login>
  </div>
</div>
