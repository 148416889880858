<div class="content">
  <div class="container">
    <div class="columns">
      <div class="column">
        <div class="logo">
          <a routerLink="/board"><img src="assets/images/logo_color.svg" alt="logo" /></a>
        </div>
        <div class="social">
          <div class="title">{{ 'footer.social.title' | translate | uppercase }}</div>
          <div class="icons">
            <div class="icon">
              <a href="https://www.facebook.com/profile.php?id=100094963900837" target="_blank">
                <img src="assets/icons/facebook_black.svg" />
              </a>
            </div>
            <div class="icon">
              <a
                href="https://www.linkedin.com/company/onevalue-sp-zoo/?viewAsMember=true"
                target="_blank"
              >
                <img src="assets/icons/linkedIn_black.svg" />
              </a>
            </div>
            <div class="icon">
              <a href="https://www.instagram.com/" target="_blank">
                <img src="assets/icons/instagram_black.svg" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="title">{{ 'footer.about.title' | translate | uppercase }}</div>
        <div class="items">
          <div class="item">
            <a href="https://artguard.app/#blog" target="_blank">
              {{ 'footer.about.items.news' | translate }}
            </a>
          </div>
          <div class="item">
            <a href="https://artguard.app/#contact" target="_blank">
              {{ 'footer.about.items.contact' | translate }}
            </a>
          </div>
          <div class="item">
            <a [href]="'staticPages.urls.about.tos' | translate">
              {{ 'footer.about.items.termsAndConditions' | translate }}
            </a>
          </div>
          <div class="item">
            <a [href]="'staticPages.urls.about.privacyPolicy' | translate">
              {{ 'footer.about.items.privacyPolicy' | translate }}
            </a>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="title">{{ 'footer.market.title' | translate | uppercase }}</div>
        <div class="items">
          <div class="item">
            <a routerLink="/sales">{{ 'footer.market.items.sales' | translate }}</a>
          </div>
          <div class="item">
            <a routerLink="/gallery">{{ 'footer.market.items.galleries' | translate }}</a>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="title">{{ 'footer.collection.title' | translate | uppercase }}</div>
        <div class="items">
          <div class="item">
            <a routerLink="/collections">{{ 'footer.collection.items.collections' | translate }}</a>
          </div>
          <div class="item">
            <a routerLink="/wallet">{{ 'footer.collection.items.wallet' | translate }}</a>
          </div>
          <div class="item">
            <a routerLink="/reports">{{ 'footer.collection.items.reports' | translate }}</a>
          </div>
        </div>
      </div>
      <div class="column"></div>
      <div class="column"></div>
    </div>
  </div>
</div>
