import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe, NgOptimizedImage } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../theme/material.module';
import { AtomButtonComponent } from './components/atoms/atom-button/atom-button.component';
import { AtomInputComponent } from './components/atoms/atom-input/atom-input.component';
import { ToastrDialogComponent } from './dialogs/toastr-dialog/toastr-dialog.component';
import { HeaderComponent } from './components/header/header.component';
import { HeaderProfileComponent } from './components/header/header-profile/header-profile.component';
import { HeaderMenuComponent } from './components/header/header-menu/header-menu.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { ExpendableTableComponent } from './tables/expendable-table/expendable-table.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { AtomAvatarComponent } from './components/atoms/atom-avatar/atom-avatar.component';
import { AtomSpinnerComponent } from './components/atoms/atom-spinner/atom-spinner.component';
import { AtomAutocompleteComponent } from './components/atoms/atom-autocomplete/atom-autocomplete.component';
import { AtomSelectComponent } from './components/atoms/atom-select/atom-select.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { register } from 'swiper/element/bundle';
import { LightboxModule } from 'ngx-lightbox';
import { AtomTextareaComponent } from './components/atoms/atom-textarea/atom-textarea.component';
import { HeaderAdminComponent } from './components/header/header-admin/header-admin.component';
import { AdminSidebarComponent } from './components/admin-sidebar/admin-sidebar.component';
import { SimpleTableComponent } from './tables/simple-table/simple-table.component';
import { AtomFileSelectorComponent } from './components/atoms/atom-file-selector/atom-file-selector.component';
import { FilterSidebarComponent } from './components/filter-sidebar/filter-sidebar.component';
import { PercentageChangePipe } from './pipes/percentage-change.pipe';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { TranslateModule } from '@ngx-translate/core';
import { BackgroundGalleryComponent } from './components/background-gallery/background-gallery.component';
import { HeaderLoginComponent } from './components/header/header-login/header-login.component';
import { AtomDatePickerComponent } from './components/atoms/atom-date-picker/atom-date-picker.component';
import { SaleCardVerticalComponent } from './components/sale-card/sale-card-vertical/sale-card-vertical.component';
import { SaleCardHorizontalComponent } from './components/sale-card/sale-card-horizontal/sale-card-horizontal.component';
import { SaleCardLabelComponent } from './components/sale-card/sale-card-label/sale-card-label.component';
import { SaleCardGalleryComponent } from './components/sale-card/sale-card-gallery/sale-card-gallery.component';
import { SaleCardDataComponent } from './components/sale-card/sale-card-data/sale-card-data.component';

import { ArticleCardVerticalComponent } from './components/article-card/article-card-vertical/article-card-vertical.component';
import { ArticleCardDataComponent } from './components/article-card/article-card-data/article-card-data.component';
import { UserCardComponent } from './components/user-card/user-card.component';
import { GalleryCardComponent } from './components/gallery-card/gallery-card.component';
import { GalleriesCarouselComponent } from './components/galleries-carousel/galleries-carousel.component';
import { FooterComponent } from './components/footer/footer.component';
import { AtomDateTimePickerComponent } from './components/atoms/atom-date-time-picker/atom-date-time-picker.component';
import { AtomDateRangePickerComponent } from './components/atoms/atom-date-range-picker/atom-date-range-picker.component';
import { TileToastrComponent } from './components/tiles/tile-toastr/tile-toastr.component';
import { TileInfoComponent } from './components/tiles/tile-info/tile-info.component';
import { TileDetailsComponent } from './components/tiles/tile-details/tile-details.component';
import { TileButtonComponent } from './components/tiles/tile-button/tile-button.component';
import { TileUserInfoComponent } from './components/tiles/tile-user-info/tile-user-info.component';
import { TileSubjectsListComponent } from './components/tiles/tile-subjects-list/tile-subjects-list.component';
import { TileSingleImageComponent } from './components/tiles/tile-single-image/tile-single-image.component';
import { TileImageGalleryComponent } from './components/tiles/tile-image-gallery/tile-image-gallery.component';
import { TileActionsComponent } from './components/tiles/tile-actions/tile-actions.component';
import { InputAutoSizeDirective } from './directives/input-auto-size.directive';
import { TileDescriptionComponent } from './components/tiles/tile-description/tile-description.component';
import { HeaderMessagesComponent } from './components/header/header-messages/header-messages.component';
import { TileUserDetailsComponent } from './components/tiles/tile-user-details/tile-user-details.component';
import { TileTableComponent } from './components/tiles/tile-table/tile-table.component';
import { ArticlesComponent } from './components/articles/articles.component';
import { SubscriptionDialogComponent } from './dialogs/subscription-dialog/subscription-dialog.component';
import { RecommendedSalesComponent } from './components/recommended-sales/recommended-sales.component';
import { CopyrightComponent } from './components/copyright/copyright.component';
import { MatSliderModule } from '@angular/material/slider';
import { EuFundsComponent } from './components/eu-funds/eu-funds.component';

register();

const atoms = [
  AtomButtonComponent,
  AtomInputComponent,
  AtomAvatarComponent,
  AtomSpinnerComponent,
  AtomAutocompleteComponent,
  AtomSelectComponent,
  AtomTextareaComponent,
  AtomFileSelectorComponent,
  AtomDatePickerComponent,
  AtomDateTimePickerComponent,
  AtomDateRangePickerComponent,
];

const tiles = [
  TileToastrComponent,
  TileInfoComponent,
  TileDetailsComponent,
  TileButtonComponent,
  TileUserInfoComponent,
  TileSubjectsListComponent,
  TileSingleImageComponent,
  TileImageGalleryComponent,
  TileActionsComponent,
  TileDescriptionComponent,
  TileUserDetailsComponent,
  TileTableComponent,
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    ...atoms,
    ...tiles,
    ToastrDialogComponent,
    HeaderComponent,
    HeaderProfileComponent,
    HeaderMenuComponent,
    DropdownComponent,
    ExpendableTableComponent,
    BreadcrumbsComponent,
    ImageGalleryComponent,
    HeaderAdminComponent,
    AdminSidebarComponent,
    SimpleTableComponent,
    FilterSidebarComponent,
    PercentageChangePipe,
    LineChartComponent,
    BackgroundGalleryComponent,
    HeaderLoginComponent,
    AtomDatePickerComponent,
    SaleCardVerticalComponent,
    SaleCardHorizontalComponent,
    SaleCardLabelComponent,
    SaleCardGalleryComponent,
    SaleCardDataComponent,

    ArticleCardVerticalComponent,
    ArticleCardDataComponent,

    UserCardComponent,
    GalleryCardComponent,
    GalleriesCarouselComponent,
    FooterComponent,
    InputAutoSizeDirective,
    HeaderMessagesComponent,
    ArticlesComponent,
    SubscriptionDialogComponent,
    RecommendedSalesComponent,
    CopyrightComponent,
    EuFundsComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    MaterialModule,
    AngularSvgIconModule,
    LightboxModule,
    NgxChartsModule,
    TranslateModule.forChild(),
    MatSliderModule,
    NgOptimizedImage,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ...atoms,
    ...tiles,
    ToastrDialogComponent,
    HeaderComponent,
    ExpendableTableComponent,
    BreadcrumbsComponent,
    AngularSvgIconModule,
    ImageGalleryComponent,
    AdminSidebarComponent,
    SimpleTableComponent,
    FilterSidebarComponent,
    PercentageChangePipe,
    LineChartComponent,
    TranslateModule,
    BackgroundGalleryComponent,
    SaleCardVerticalComponent,
    SaleCardHorizontalComponent,
    SaleCardLabelComponent,
    SaleCardGalleryComponent,
    SaleCardDataComponent,
    ArticleCardVerticalComponent,
    ArticleCardDataComponent,
    UserCardComponent,
    GalleryCardComponent,
    GalleriesCarouselComponent,
    FooterComponent,
    InputAutoSizeDirective,
    ArticlesComponent,
    RecommendedSalesComponent,
    CopyrightComponent,
    EuFundsComponent,
  ],
  providers: [DecimalPipe],
})
export class SharedModule {}
