import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { debounceTime, startWith, tap } from 'rxjs';
import { DEBOUNCE_TIME, InputType } from 'src/app/shared/utils/common';
import { handleValidationErrorMessage } from 'src/app/shared/utils/validators/validators';
import { ArtworkService } from '../../../services/artwork.service';
import { AuthorService } from '../../../services/author.service';

@Component({
  selector: 'atom-autocomplete',
  templateUrl: './atom-autocomplete.component.html',
  styleUrls: ['./atom-autocomplete.component.scss'],
})
export class AtomAutocompleteComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() type: InputType = 'text';
  @Input() disabled?: any;
  @Input() control!: AbstractControl;
  @Input() optionsService!: ArtworkService | AuthorService;
  @Input() loadOptionsAuthorUid?: string;
  @Input() loadOptionsSearch?: string;

  filteredOptions!: any[];

  valueSelected: boolean = false;
  nameControl: FormControl = new FormControl();
  isDisabled: boolean = !this.disabled;
  isLoading: boolean = false;
  isLoadingMore: boolean = false;

  @ViewChild('addButton')
  addButton: ElementRef | undefined;
  @ViewChild('inputControl')
  inputControl: ElementRef | undefined;
  isAddingNew: boolean = false;

  get formControl() {
    return this.control as FormControl;
  }

  get isRequired() {
    return this.formControl.hasValidator(Validators.required);
  }

  async getServiceOptions(searchValue: string, perPage?: number) {
    typeof perPage === 'undefined' ? (this.isLoading = true) : (this.isLoadingMore = true);
    return await this.optionsService
      .getSelectOptions(searchValue, perPage || 8, this.loadOptionsAuthorUid as string)
      .then((response) => {
        const result = response.result;
        if (!result) {
          this.filteredOptions = [];
          return response;
        }
        if (!!perPage) {
          const moreOptions = result?.results?.slice(8) || [];
          this.filteredOptions.push(...moreOptions);
          return response;
        }
        if (typeof perPage === 'undefined' && result?.total > result?.pageSize) {
          setTimeout(() => this.getServiceOptions(searchValue, 58), 500);
        }
        this.filteredOptions = result?.results || [];
      })
      .finally(() => {
        this.isLoading = false;
        this.isLoadingMore = false;
      });
  }

  public errorHandler = handleValidationErrorMessage;

  ngOnInit(): void {
    this.nameControl.setValue(
      typeof this.formControl.value === 'string'
        ? this.formControl.value
        : this.formControl.value?.name
    );
    this.nameControl.valueChanges
      .pipe(
        startWith(''),
        debounceTime(DEBOUNCE_TIME),
        tap((val) => {
          return this.getServiceOptions(val);
        })
      )
      .subscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['control']) {
      this.nameControl.setValue(this.formControl.value?.name);
    }
    if (changes['loadOptionsAuthorUid']) {
      if (!this.loadOptionsAuthorUid) {
        this.formControl.setValue('');
        this.nameControl.setValue('');
      }
    }
  }

  onClear() {
    this.formControl.setValue('');
    this.nameControl.setValue('');
    this.isAddingNew = false;
  }

  onValueSelect(event: MatAutocompleteSelectedEvent) {
    this.valueSelected = true;
    this.nameControl.setValue(event.option.value.name);
    if (this.loadOptionsAuthorUid) {
      this.formControl.setValue(event.option.value.uid);
    } else {
      this.formControl.setValue({ uid: event.option.value.uid, name: event.option.value.name });
    }
  }

  onManualChange() {
    this.valueSelected = false;
    if (this.loadOptionsAuthorUid) {
      this.formControl.setValue(this.nameControl.value);
    } else {
      this.formControl.setValue({ uid: this.nameControl.value, name: this.nameControl.value });
    }
    if (this.nameControl.value === '') {
      this.isAddingNew = false;
    }
  }

  onAddButton() {
    if (this.isAddingNew) {
      this.onClear();
    } else {
      this.isAddingNew = true;
      this.inputControl?.nativeElement.focus();
    }
  }
  onInputBlur(event: FocusEvent) {
    if (!this.valueSelected) {
      this.isAddingNew || event.relatedTarget === this.addButton?.nativeElement
        ? this.onManualChange()
        : this.onClear();
    }
  }
}
