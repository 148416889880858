import { Component, Input } from '@angular/core';
import {
  SALE_TYPE_AUCTION_LIVE_INTERNAL_NAME,
  SALE_TYPE_AUCTION_STANDARD_INTERNAL_NAME,
  SALE_TYPE_BUY_NOW_INTERNAL_NAME,
  SALE_TYPE_RENT_INTERNAL_NAME,
} from 'src/app/shared/utils/common';

@Component({
  selector: 'app-sale-card-label',
  templateUrl: './sale-card-label.component.html',
  styleUrls: ['./sale-card-label.component.scss'],
})
export class SaleCardLabelComponent {
  @Input() saleTypeIN!: string;
  iconUrl!: string;
  iconColor!: string;

  constructor() {}

  ngOnInit(): void {
    switch (this.saleTypeIN) {
      case SALE_TYPE_BUY_NOW_INTERNAL_NAME:
        this.iconUrl = '/assets/icons/sell.svg';
        this.iconColor = '#030405';
        break;
      case SALE_TYPE_AUCTION_STANDARD_INTERNAL_NAME:
        this.iconUrl = 'assets/icons/gavel.svg';
        this.iconColor = '#030405';
        break;
      case SALE_TYPE_AUCTION_LIVE_INTERNAL_NAME:
        this.iconUrl = 'assets/icons/gavel.svg';
        this.iconColor = '#DB0B14';
        break;
      case SALE_TYPE_RENT_INTERNAL_NAME:
        this.iconUrl = 'assets/icons/calendar-range.svg';
        this.iconColor = '#030405';
        break;
    }
  }
}
