<div class="avatar" [style.width.px]="sizePx" [style.height.px]="sizePx" [style.fontSize.px]="fontSizePx">
    <ng-container *ngIf="avatarType === 'firstLetter'">
        {{label.split(" ")[0] | slice:0:1}}
    </ng-container>
    <ng-container *ngIf="avatarType === 'initials'">
        {{label.split(" ")[0] | slice:0:1}}{{label.split(" ")[1] | slice:0:1}}
    </ng-container>
    <ng-container *ngIf="avatarType === 'image'">
        <img class="avatar-image" [ngClass]="{'placeholder': !imageSrc}"
            [src]="imageSrc ? staticService.getStaticUrl(imageSrc) : '/assets/icons/user.svg'" alt="avatar">
    </ng-container>
</div>