import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { SubjectDetailsResponse } from 'src/app/cms/features/collections/models/subject.model';
import Swiper, { SwiperOptions } from 'swiper';
import { StaticService } from '../../services/static.service';

@Component({
  selector: 'app-galleries-carousel',
  templateUrl: './galleries-carousel.component.html',
  styleUrls: ['./galleries-carousel.component.scss'],
})
export class GalleriesCarouselComponent {
  @Input() galleries: SubjectDetailsResponse[] = [];
  @ViewChild('swiperRef')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  constructor(private staticService: StaticService) {}

  ngAfterViewInit(): void {
    this.initSwiper();
  }

  initSwiper(): void {
    const options: SwiperOptions = {
      navigation: true,
      slidesPerView: 1,
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
      },
      breakpoints: {
        1024: {
          slidesPerView: 2,
        },
      },
      loop: true,
      speed: 1000,
      spaceBetween: 16,

      injectStyles: [
        `
        .swiper-button-prev, .swiper-button-next {
          background: transparent;
          border-radius: 4px;
          border: 1px solid #DEDCD3;
          padding: 0 5px;
          height: 40px;
          top: 12px;
          left: auto;
        }
        .swiper-button-prev {
          right: 60px;
        }
        .swiper-button-next {
          right: 10px;
        }
        .swiper-button-next:after, .swiper-button-prev:after {
          content: "";
          display: inline-block;
          background-size: contain !important;
          width: 16px;
          height: 16px;
          padding: 10px;
          box-sizing: border-box;
        }
        .swiper-button-prev:after {
          background: url("assets/icons/arrow-left_black.svg") no-repeat center;
        }
        .swiper-button-next:after {
          background: url("assets/icons/arrow-right_black.svg") no-repeat center;
        }
        `,
      ],
    };

    const swiperElement = this.swiperRef?.nativeElement;
    Object.assign(swiperElement, options);

    swiperElement.initialize();
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }
}
