import { Component, Input } from '@angular/core';
import { TileSubjectsListData } from 'src/app/shared/models/tile.model';
import { StaticService } from 'src/app/shared/services/static.service';

@Component({
  selector: 'tile-subjects-list',
  templateUrl: './tile-subjects-list.component.html',
  styleUrls: ['./tile-subjects-list.component.scss'],
})
export class TileSubjectsListComponent {
  @Input() data!: TileSubjectsListData;

  constructor(private staticService: StaticService) {}

  getStaticUrl(url: string): string {
    if (!url) return '/assets/images/placeholder.svg';
    return this.staticService.getStaticUrl(url);
  }
}
