import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Subscription, tap } from 'rxjs';
import { PermissionGroup } from '../permissions/permissions';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  private userGroups: PermissionGroup[] = [];

  private subscription = new Subscription();

  constructor(private authService: AuthService) {
    this.subscription = this.authService._userData
      .pipe(
        tap((userData) => {
          if (userData) {
            this.userGroups = userData.accessGroups.map(
              (group) => group as PermissionGroup
            );
          } else {
            this.userGroups = [];
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  hasPermission(permissionGroup: PermissionGroup): boolean {
    if (this.userGroups.includes('FullAdmin')) {
      return true;
    } else {
      if (this.userGroups.includes(permissionGroup)) {
        return true;
      } else {
        return false;
      }
    }
  }
}
