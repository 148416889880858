import { Component, Input } from '@angular/core';
import { SaleResponse } from 'src/app/cms/features/sales/models/sale.model';
import { GalleryImage } from 'src/app/shared/models/gallery-image.model';
import { StaticService } from 'src/app/shared/services/static.service';
import { SubjectDetailsResponse } from 'src/app/cms/features/collections/models/subject.model';

@Component({
  selector: 'app-sale-card-vertical',
  templateUrl: './sale-card-vertical.component.html',
  styleUrls: ['./sale-card-vertical.component.scss'],
})
export class SaleCardVerticalComponent {
  @Input() saleData!: SaleResponse;
  @Input() saleType!: string;
  galleryImages: GalleryImage[] = [];

  constructor(public staticService: StaticService) {}

  ngOnInit(): void {
    this.galleryImages = this.saleData.subjects.map((subject: SubjectDetailsResponse) => {
      if (subject.mainPhoto) {
        return {
          url: this.staticService.getStaticUrl(subject.mainPhoto.miniatureUrl),
          filename: subject.mainPhoto.filename,
        };
      } else {
        return {
          url: '/assets/icons/placeholder.svg',
          filename: subject.name,
        };
      }
    });
  }
}
