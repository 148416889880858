import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { handleValidationErrorMessage } from 'src/app/shared/utils/validators/validators';

@Component({
  selector: 'atom-textarea',
  templateUrl: './atom-textarea.component.html',
  styleUrls: ['./atom-textarea.component.scss']
})
export class AtomTextareaComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() control!: AbstractControl;
  @Input() rows: number = 4;

  get formControl() {
    return this.control as FormControl;
  }

  get isRequired() {
    return this.formControl.hasValidator(Validators.required);
  }

  public errorHandler = handleValidationErrorMessage;

}
