<div class="field-wrapper">
  <div class="field">
    <label *ngIf="label"
      >{{ label | translate }}
      <span *ngIf="isRequired" class="required">*</span>
    </label>
    <mat-select
      [formControl]="formControl"
      [placeholder]="placeholder | translate"
      [multiple]="multiple"
    >
      <mat-option *ngIf="nullable && (options?.length || (apiOptions | async)?.length)">{{
        'common.all' | translate
      }}</mat-option>
      <ng-container *ngFor="let option of options">
        <mat-option
          [value]="
            optionsValueProp && option[optionsValueProp]
              ? option[optionsValueProp]
              : option.uid
                ? option.uid
                : option.value
          "
        >
          {{ option.publicName ? option.publicName : option.name }}
        </mat-option>
      </ng-container>
      <ng-container *ngFor="let apiOption of apiOptions | async">
        <mat-option
          [value]="
            optionsValueProp && apiOption[optionsValueProp]
              ? apiOption[optionsValueProp]
              : apiOption.uid
          "
        >
          {{ apiOption.publicName }}
        </mat-option>
      </ng-container>
    </mat-select>
  </div>
  <div class="error">
    <mat-error *ngIf="control?.touched && control.status === 'INVALID'">
      {{ errorHandler(control) | translate }}
    </mat-error>
  </div>
</div>
