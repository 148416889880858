import { Component, Input } from '@angular/core';
import { TileSingleImageData } from 'src/app/shared/models/tile.model';
import { StaticService } from 'src/app/shared/services/static.service';

@Component({
  selector: 'tile-single-image',
  templateUrl: './tile-single-image.component.html',
  styleUrls: ['./tile-single-image.component.scss'],
})
export class TileSingleImageComponent {
  @Input() data!: TileSingleImageData;

  constructor(private staticService: StaticService) {}

  getStaticUrl(url: string): string {
    if (!url) return '/assets/images/placeholder.svg';
    return this.staticService.getStaticUrl(url);
  }
}
