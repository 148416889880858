import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ScrapersService } from '../../services/scraper.service';
import {
  ScraperArticleRequest,
  ScraperArticleResponse,
} from '../../models/scraper.model';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Subscription } from 'rxjs';
import { UserInfoResponse } from 'src/app/auth/models/user.model';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.scss'],
})
export class ArticlesComponent {
  @Input() mode: 'expandable' | 'details' = 'expandable';
  @Output() contentLoaded = new EventEmitter();
  isLoading: boolean = false;

  userData!: UserInfoResponse;
  articles: ScraperArticleResponse[] = [];
  subscription: Subscription = new Subscription();

  constructor(
    private scraperService: ScrapersService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.authService._userData.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
          this.getArticles();
        }
      })
    );
  }

  getArticles() {
    this.isLoading = true;
    const request: ScraperArticleRequest = {
      tags: ['ExtraCategory', 'DefaultCategory', 'CreationTechnique'], // TODO do zmiany na wartość z parametru komponentu nadrzędnego
      lang: this.userData.defaultLanguage.internalName,
    };
    this.scraperService
      .getArticles(request, {})
      .then((response) => {
        this.articles = response.data;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  goToArticle(article: ScraperArticleResponse) {
    window.open(article.url, '_blank');
  }
}
