import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ScaleType } from '@swimlane/ngx-charts';
import { ChartData, ChartResult } from '../../models/chart.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.scss'],
})
export class LineChartComponent implements OnChanges {
  @Input() heightPx: number = 100;
  @Input() chartTitle: string = '';
  @Input() chartData!: ChartData;
  @Input() colorHex!: string;
  @Input() valueType: string = 'value';

  results: ChartResult[] = [];
  dateFrom!: string;
  dateTo!: string;

  colorScheme = {
    name: '',
    selectable: true,
    group: ScaleType.Ordinal,
    domain: ['#2E9546'],
  };

  constructor(private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['chartData']) {
      if (this.colorHex) {
        this.colorScheme.domain = [this.colorHex];
      }
      this.initChart();
    }
  }

  initChart() {
    const sortedValues = this.chartData.values.sort(
      (a, b) =>
        new Date(a.dateOfValue).getTime() - new Date(b.dateOfValue).getTime()
    );

    this.dateFrom = this.formatDate(new Date(sortedValues[0].dateOfValue));
    this.dateTo = this.formatDate(
      new Date(sortedValues[sortedValues.length - 1].dateOfValue)
    );

    const series = sortedValues.map((item) => {
      return {
        name: this.formatDate(new Date(item.dateOfValue)),
        value: this.valueType === 'value' ? item.value : item.percentageValue,
      };
    });

    this.results = [
      {
        name: this.translateService.instant(this.chartTitle),
        series: series,
      },
    ];
  }

  formatDate(date: Date): string {
    const formattedDateDay = String(date.getDate()).padStart(2, '0');
    const formattedDateMonth = String(date.getMonth() + 1).padStart(2, '0');
    const formattedDateYear = date.getFullYear();
    return `${formattedDateDay}.${formattedDateMonth}.${formattedDateYear}`;
  }
}
