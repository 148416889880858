<a class="content" [href]="articlesData.url" target="_blank">
  <div class="image-gallery-wrapper">
    <div class="image-gallery">
      <div class="slide-container">
        <img class="blurred" [src]="articlesData.imageUrl" />
        <img [src]="articlesData.imageUrl" />
      </div>
    </div>
  </div>
  <div class="data">
    <app-article-card-data
      [articlesData]="articlesData"
      [borderRadiusBottom]="true"
      [saleType]="saleType"
    ></app-article-card-data>
  </div>
</a>
