import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TileMainInfoData } from 'src/app/shared/models/tile.model';
import { StaticService } from 'src/app/shared/services/static.service';

@Component({
  selector: 'tile-info',
  templateUrl: './tile-info.component.html',
  styleUrls: ['./tile-info.component.scss'],
})
export class TileInfoComponent {
  @Input() data!: TileMainInfoData;
  @Output() edit = new EventEmitter<void>();
  @Output() delete = new EventEmitter<void>();
  @Output() other = new EventEmitter<void>();

  constructor(private staticService: StaticService) {}

  getStaticUrl(url: string) {
    return this.staticService.getStaticUrl(url);
  }

  onOther() {
    this.other.emit();
  }

  onEdit() {
    this.edit.emit();
  }

  onDelete() {
    this.delete.emit();
  }
}
