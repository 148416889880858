import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, from, map, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/auth/services/auth.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private translate: TranslateService
  ) {}

  private getHeadersAsync(): Observable<HttpHeaders> {
    return from(this.authService.getJwtTokenAsync()).pipe(
      map((token) => {
        if (!token) this.authService.clearAuthData(true);
        let headers = new HttpHeaders();
        if (token) {
          headers = headers.set('Authorization', `Bearer ${token}`);
        }
        headers = headers.set('Accept-Language', this.translate.currentLang || 'pl');
        headers = headers.set('Frontend-Version', `Web - ${environment.version}`);
        return headers;
      })
    );
  }


  private async getHeadersArticles(): Promise<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept-Language', this.translate.currentLang || 'pl');
    headers = headers.set('Authorization', `Bearer ${environment.scrapersApi.token}`);
    return headers;
  }

  public post<T>(url: string, body: any): Observable<T> {
    return from(this.getHeadersAsync()).pipe(
      switchMap((headers) => {
        const httpOptions = {
          headers,
        };
        return this.http.post<T>(environment.apiUrl + url, body, httpOptions);
      })
    );
  }

  public postArticles<T>(url: string, body: any): Observable<T> {
    return from(this.getHeadersArticles()).pipe(
      switchMap((headers) => {
        const httpOptions = {
          headers,
        };
        return this.http.post<T>(environment.scrapersApi.url + url, body, httpOptions);
      })
    );
  }

  public get<T>(url: string, queryParams: any): Observable<T> {
    return from(this.getHeadersAsync()).pipe(
      switchMap((headers) => {
        const httpOptions = {
          headers,
          params: queryParams,
        };
        return this.http.get<T>(environment.apiUrl + url, httpOptions);
      })
    );
  }

  public getBlob(url: string, queryParams: any): Observable<Blob> {
    return from(this.getHeadersAsync()).pipe(
      switchMap((headers) => {
        const httpOptions = {
          headers,
          params: queryParams,
          responseType: 'blob' as const,
        };
        return this.http.get(environment.apiUrl + url, httpOptions);
      })
    );
  }

  public put<T>(url: string, body: any): Observable<T> {
    return from(this.getHeadersAsync()).pipe(
      switchMap((headers) => {
        const httpOptions = {
          headers,
          body,
        };
        return this.http.put<T>(environment.apiUrl + url, body, httpOptions);
      })
    );
  }

  public patch<T>(url: string, body: any): Observable<T> {
    return from(this.getHeadersAsync()).pipe(
      switchMap((headers) => {
        const httpOptions = {
          headers,
        };
        return this.http.patch<T>(environment.apiUrl + url, body, httpOptions);
      })
    );
  }

  public delete<T>(url: string, body?: any): Observable<any> {
    return from(this.getHeadersAsync()).pipe(
      switchMap((headers) => {
        const httpOptions = {
          headers,
          body,
        };
        return this.http.delete<T>(environment.apiUrl + url, httpOptions);
      })
    );
  }
}
