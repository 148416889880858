import { Component, Input } from '@angular/core';
import { TileImageGalleryData } from 'src/app/shared/models/tile.model';

@Component({
  selector: 'tile-image-gallery',
  templateUrl: './tile-image-gallery.component.html',
  styleUrls: ['./tile-image-gallery.component.scss'],
})
export class TileImageGalleryComponent {
  @Input() data!: TileImageGalleryData;
}
