import { DEFAULT_ERROR_MESSAGES, ErrorMessages } from './error-messages';
import {
  FormControl,
  FormGroup,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
  FormArray,
} from '@angular/forms';

export const isRequiredField = (abstractControl: AbstractControl): boolean => {
  if (!abstractControl) return false;
  if (abstractControl.validator) {
    const validator = abstractControl.validator({} as AbstractControl);
    if (validator && validator['required']) {
      return true;
    }
    return false;
  }

  if (abstractControl instanceof FormGroup) {
    for (const controlName in abstractControl['controls']) {
      if (abstractControl['controls'][controlName]) {
        if (isRequiredField(abstractControl['controls'][controlName])) {
          return true;
        }
      }
    }
  }
  return false;
};

export const atLeastOne =
  (validator: ValidatorFn, controls: string[] = []) =>
  (group: FormGroup): ValidationErrors | null => {
    if (!controls) {
      controls = Object.keys(group.controls);
    }
    const hasAtLeastOne =
      group &&
      group.controls &&
      controls.some((k) => !validator(group.controls[k]));
    return hasAtLeastOne
      ? null
      : {
          atLeastOne: true,
        };
  };

export const alwaysInvalid =
  (control: FormControl) =>
  (group: FormGroup): ValidationErrors | null => {
    return { alwaysInvalid: true };
  };

export function emailValidator(control: FormControl): ValidatorResult {
  if (!control.value) {
    return null;
  }
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(control.value) ? null : { notSymbols: true };
}

export function notSymbolsValidator(control: FormControl): ValidatorResult {
  return /^[a-zA-Z0-9]+$/.test(control.value) ? null : { notSymbols: true };
}

export function equalToFieldValueValidator(fieldToCompare: FormControl): any {
  return (control: FormControl) => {
    return fieldToCompare.value && fieldToCompare.value != control.value
      ? { equalToFieldValue: true }
      : null;
  };
}

export function passwordValidator(control: AbstractControl) {
  return !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=\S+$).{8,}$/.test(control.value)
    ? { password: true }
    : null;
}

export function handleValidationErrorMessage(control: AbstractControl): string {
  let message = '';

  const fieldMessages = ErrorMessages;
  const defaultMessages = DEFAULT_ERROR_MESSAGES;

  const formGroup = control?.parent?.controls as {
    [p: string]: AbstractControl<any, any>;
  };
  if (!formGroup) return '';
  const fieldName =
    Object.keys(formGroup as Object).find(
      (name) => control === formGroup[name]
    ) || null;
  const errorKey = Object.keys(control?.errors!)[0];
  const errorValue = Object.values(control?.errors!)[0];
  if (control?.invalid && control?.touched) {
    const validationMessagePack = fieldMessages.find(
      (messageObject) => messageObject.field === fieldName
    );
    if (validationMessagePack) {
      const validationMessage = validationMessagePack.errors.find(
        (errorPack) => errorPack.error === errorKey
      );
      message = validationMessage ? validationMessage.message : '';
    } else if (Object.hasOwn(defaultMessages, errorKey)) {
      // @ts-ignore
      message = defaultMessages[errorKey](errorValue?.requiredLength);
    }
  }

  return message;
}

export const minLengthFormArray = (min: number) => {
  return (c: AbstractControl): { [key: string]: any } => {
    if (c.value.length >= min) return {};

    return { MinLengthArray: true };
  };
};

export type ValidatorResult = { [key: string]: boolean } | null;
