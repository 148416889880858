<div
  class="content"
  [style.width]="isMobile ? '100%' : widthPx + 'px'"
  [style.backgroundColor]="backgroundColor"
  [style.cursor]="navigateToList ? 'pointer' : 'auto'"
  [routerLink]="
    navigateToList
      ? [
          '/' +
            navigateToList +
            (navigateToList === 'sales' ? '/seller/' : '/owner/') +
            userData.uid,
        ]
      : []
  "
>
  <div class="image">
    <img
      [src]="
        userData.mainPhoto
          ? staticService.getStaticUrl(userData.mainPhoto.url)
          : '/assets/icons/user.svg'
      "
    />
  </div>
  <div class="data">
    <!-- <div class="role"></div> -->
    <div class="name">{{ userData.anonymizedName }}</div>
    <div *ngIf="userData.saleSubcategories" class="subcategories">
      {{ userData.saleSubcategories }}
    </div>
  </div>
</div>
