<mat-sidenav-container>
  <mat-sidenav-content>
    <div class="container">
      <div class="header">
        <app-header color="black" (adminClicked)="adminSidebar.toggle()"></app-header>
      </div>
      <div class="main">
        <mat-sidenav-container>
          <mat-sidenav-content>
            <div class="content">
              <router-outlet></router-outlet>
            </div>
          </mat-sidenav-content>
          <mat-sidenav #adminSidebar position="end">
            <app-admin-sidebar (click)="adminSidebar.close()"></app-admin-sidebar>
          </mat-sidenav>
        </mat-sidenav-container>
      </div>
      <footer>
        <app-eu-funds></app-eu-funds>
        <app-copyright></app-copyright>
      </footer>
    </div>
  </mat-sidenav-content>
  <mat-sidenav #filtersSidebar position="end">
    <app-filters-sidebar
      (sidebarOpened)="filtersSidebar.open()"
      (sidebarClosed)="filtersSidebar.close()"
    ></app-filters-sidebar>
  </mat-sidenav>
</mat-sidenav-container>
