import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { GalleryImage } from 'src/app/shared/models/gallery-image.model';
import { StaticService } from 'src/app/shared/services/static.service';
import Swiper, { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-sale-card-gallery',
  templateUrl: './sale-card-gallery.component.html',
  styleUrls: ['./sale-card-gallery.component.scss'],
})
export class SaleCardGalleryComponent {
  @Input() images: GalleryImage[] = [];
  @Input() borderRadiusTop: boolean = false;
  @Input() borderRadiusBottom: boolean = false;
  @ViewChild('swiperRef')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;

  constructor(private staticService: StaticService) {}

  ngAfterViewInit(): void {
    this.initSwiper();
  }

  initSwiper(): void {
    const options: SwiperOptions = {
      slidesPerView: 1,
      autoplay: {
        delay: 1000,
      },
      loop: true,
      effect: 'fade',
      speed: 1000,
    };

    const swiperElement = this.swiperRef?.nativeElement;
    Object.assign(swiperElement, options);

    swiperElement.initialize();
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }
}
