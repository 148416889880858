<div
  [class]="isHorizontal ? 'content isHorizontal' : 'content'"
  [class.border-radius-top]="borderRadiusTop"
  [class.border-radius-bottom]="borderRadiusBottom"
  [style.width]="isHorizontal ? '' : width"
>
  <div class="top">
    <div class="authors">{{ saleData.authorsNames }}</div>
    <div class="subjects">{{ saleData.saleSubjectNames }}</div>
  </div>
  <div class="bottom">
    <div class="info">
      <div class="price">{{ saleData.initialPrice }} {{ saleData.baseCurrency.internalName }}</div>
    </div>
  </div>
</div>
