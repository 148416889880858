import { Component, Input } from '@angular/core';
import { HeaderElementsColor } from 'src/app/shared/utils/common';

@Component({
  selector: 'app-header-admin',
  templateUrl: './header-admin.component.html',
  styleUrls: ['./header-admin.component.scss'],
})
export class HeaderAdminComponent {
  @Input() color: HeaderElementsColor = 'black';
}
