import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import {
  SubjectCreateRequest,
  SubjectDetailsResponse,
  SubjectHistoryResponse,
  SubjectMetadataCreateRequest,
  SubjectMetadataUpdateRequest,
  SubjectThinWithLastHistoryEntryResponse,
  SubjectUpdateRequest,
} from '../models/subject.model';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { lastValueFrom } from 'rxjs';
import { ApiPagingResponseModel } from 'src/app/core/api/api-paging-response.model';

@Injectable({
  providedIn: 'root',
})
export class SubjectApiService extends ApiService {
  apiPrefix = '/api/v1/subject';

  createSubject(
    subjectData: SubjectCreateRequest
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.post<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}`,
      subjectData
    );

    return lastValueFrom(request);
  }

  getSubject(
    subjectUid: string
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.get<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/uid/${subjectUid}`,
      null
    );

    return lastValueFrom(request);
  }

  getSubjectsThin(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>
    >(`${this.apiPrefix}/thin` + query, null);

    return lastValueFrom(request);
  }

  

  getSubjects(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>
    >(`${this.apiPrefix}` + query, null);

    return lastValueFrom(request);
  }

  getMySubjects(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>
    >(`${this.apiPrefix}/mine` + query, null);

    return lastValueFrom(request);
  }

  deleteSubject(
    subjectUid: string
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.delete<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}`
    );

    return lastValueFrom(request);
  }

  createMainPhoto(
    subjectUid: string,
    file: File
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    let formData = new FormData();
    formData.append('file', file, file.name);
    const request = this.post<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/photo/main`,
      formData
    );

    return lastValueFrom(request);
  }

  updateSubject(
    subjectUid: string,
    subjectData: SubjectUpdateRequest
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.put<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}`,
      subjectData
    );
    return lastValueFrom(request);
  }

  addSubjectDocuments(
    subjectUid: string,
    documentUids: string[]
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.post<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/document`,
      {
        documentUidsToAdd: documentUids,
      }
    );
    return lastValueFrom(request);
  }

  deleteSubjectDocuments(
    subjectUid: string,
    documentUids: string[]
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.delete<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/document`,
      {
        documentUidsToRemove: documentUids,
      }
    );
    return lastValueFrom(request);
  }

  addSubjectPhotos(
    subjectUid: string,
    photoUids: string[]
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.post<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/photo`,
      {
        photoUidsToAdd: photoUids,
      }
    );
    return lastValueFrom(request);
  }

  deleteSubjectPhotos(
    subjectUid: string,
    photoUids: string[]
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.delete<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/photo`,
      {
        photoUidsToRemove: photoUids,
      }
    );
    return lastValueFrom(request);
  }

  updateSubjectMainPhoto(
    subjectUid: string,
    photoUid: string
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.put<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/main-photo/${photoUid}`,
      null
    );
    return lastValueFrom(request);
  }

  addSubjectMetadata(
    subjectUid: string,
    data: SubjectMetadataCreateRequest
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.post<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/metadata`,
      data
    );
    return lastValueFrom(request);
  }

  updateSubjectMetadata(
    subjectUid: string,
    metadataUid: string,
    data: SubjectMetadataUpdateRequest
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.put<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/metadata/${metadataUid}`,
      data
    );
    return lastValueFrom(request);
  }

  deleteSubjectMetadata(
    subjectUid: string,
    metadataUid: string
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.delete<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/metadata/${metadataUid}`
    );
    return lastValueFrom(request);
  }

  toggleSubjectPrivatePublic(
    subjectUid: string
  ): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.post<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${subjectUid}/toggle-private-public`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectsHistory(
    queryParams: string | null
  ): Promise<
    ApiResponseModel<
      ApiPagingResponseModel<SubjectThinWithLastHistoryEntryResponse>
    >
  > {
    const request = this.get<
      ApiResponseModel<
        ApiPagingResponseModel<SubjectThinWithLastHistoryEntryResponse>
      >
    >(`${this.apiPrefix}/history/all` + queryParams, null);

    return lastValueFrom(request);
  }
}
