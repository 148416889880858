import { Component, Input } from '@angular/core';
import { TileDetailData } from 'src/app/shared/models/tile.model';

@Component({
  selector: 'tile-details',
  templateUrl: './tile-details.component.html',
  styleUrls: ['./tile-details.component.scss'],
})
export class TileDetailsComponent {
  @Input() data: TileDetailData[] = [];
}
