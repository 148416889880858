import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from '../theme/material.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { LayoutsModule } from '../layouts/layouts.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    MaterialModule,
    AngularSvgIconModule.forRoot(),
    LayoutsModule,
  ],
  exports: [HttpClientModule],
})
export class CoreModule {}
