import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { PhotoResponse } from '../../models/photo.model';
import { StaticService } from '../../services/static.service';
import Swiper, { SwiperOptions } from 'swiper';
import { IAlbum, Lightbox, LightboxConfig } from 'ngx-lightbox';
import { GalleryImage } from '../../models/gallery-image.model';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss'],
})
export class ImageGalleryComponent implements OnInit {
  @Input() images: GalleryImage[] = [];
  @ViewChild('swiperRef')
  swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  ligthboxAlbum: IAlbum[] = [];

  constructor(
    private staticService: StaticService,
    private lightbox: Lightbox,
    private lightboxConfig: LightboxConfig
  ) {}

  ngOnInit(): void {
    if (this.images.length) {
      this.ligthboxAlbum = this.images.map((image) => ({
        src: this.getStaticUrl(image),
        caption: image.filename,
        thumb: this.getStaticUrl(image),
      }));
    }
  }

  ngAfterViewInit(): void {
    this.initSwiper();
  }

  initSwiper(): void {
    const options: SwiperOptions = {
      navigation: true,
      slidesPerView: 1,

      injectStyles: [
        `
        .swiper-button-prev, .swiper-button-next {
          background: #f7f7f7;
          border-radius: 4px;
          padding: 0 5px;
        }
        .swiper-button-prev {
          left: 20px;
        }
        .swiper-button-next {
          right: 20px;
        }
        .swiper-button-next:after, .swiper-button-prev:after {
          content: "";
          display: inline-block;
          background-size: contain !important;
          width: 16px;
          height: 16px;
          padding: 10px;
          box-sizing: border-box;
        }
        .swiper-button-prev:after {
          background: url("assets/icons/arrow-left_black.svg") no-repeat center;
        }
        .swiper-button-next:after {
          background: url("assets/icons/arrow-right_black.svg") no-repeat center;
        }
        `,
      ],
    };

    const swiperElement = this.swiperRef?.nativeElement;
    Object.assign(swiperElement, options);

    swiperElement.initialize();
    this.swiper = this.swiperRef?.nativeElement.swiper;
  }

  getStaticUrl(image: GalleryImage): string {
    return this.staticService.getStaticUrl(image.url);
  }

  openLightbox(index: number): void {
    this.lightbox.open(this.ligthboxAlbum, index, {
      wrapAround: true,
      centerVertically: true,
    });
  }

  closeLightbox(): void {
    this.lightbox.close();
  }
}
