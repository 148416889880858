import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { handleValidationErrorMessage } from 'src/app/shared/utils/validators/validators';

@Component({
  selector: 'atom-date-picker',
  templateUrl: './atom-date-picker.component.html',
  styleUrls: ['./atom-date-picker.component.scss'],
})
export class AtomDatePickerComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() control!: AbstractControl;
  @Input() disabled: boolean = false;

  get formControl() {
    return this.control as FormControl;
  }

  get isRequired() {
    return this.formControl.hasValidator(Validators.required);
  }

  public errorHandler = handleValidationErrorMessage;
}
