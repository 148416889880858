import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[inputAutoSize]',
})
export class InputAutoSizeDirective {
  @Input('inputAutoSize') formControl!: FormControl;
  subscription = new Subscription();

  constructor(private el: ElementRef) {}

  ngOnInit() {
    this.changeInputSize();
    this.subscription.add(
      this.formControl.valueChanges.subscribe(() => {
        this.changeInputSize();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  changeInputSize() {
    const inputValueLength = this.el.nativeElement.value.length;
    this.el.nativeElement.style.width = inputValueLength * 9 + 'px';
  }
}
