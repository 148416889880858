import { Component, Input } from '@angular/core';
import { TileUserDetailsData } from 'src/app/shared/models/tile.model';

@Component({
  selector: 'tile-user-details',
  templateUrl: './tile-user-details.component.html',
  styleUrls: ['./tile-user-details.component.scss'],
})
export class TileUserDetailsComponent {
  @Input() data!: TileUserDetailsData;
}
