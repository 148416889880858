export const environment = {
  envName: 'prod',
  version: '1.0.0',
  apiUrl: 'https://test-api.artguard.pl',
  // apiUrl: 'https://api.artguard.pl',
  staticUrl: 'https://test-static.artguard.pl',
  docsUrl: 'https://test-docs.artguard.pl',
  storageHashKey:
    'AAAAB3NzaC1yc2EAAAADAQABAAABAQCXSBxDUlnjTXPbSETk61WR9AkmjjjhkGyZ4cCLo0Kjczb+xwTRn+FkCI8YKsGQV9KGjisWYCAB6dQDmuVCBLsowYMssmVNt7rjlmqU2CwHLEOcydCk1LaxtULNUhhYh+PCEV4PuSbfUe74DeDFs0sMpugPQEopmu6NRydnMrOT95CHuPFY5hMo1azc7UZ2B12VqT1DSl1CNf55LdNbAT/xzsweJfTPqJ8Hmx9SEJWDOydalVljkozJwv8GpT7qlDlYkLKK0DAnIYbg7FKPjdK0+ParoZWJhTrj6TGeMVY/tpcgCOqb/Vcscw8aw1eRQjBH4B/DuCkKgFl9KdkHKkOl',
  googleClientId:
    '556417115003-ctspel3rk803m7l9kq37vd49bhdhpr4v.apps.googleusercontent.com',
  facebookAppId: '117821257980590',
  apple: {
    clientId: 'com.ag.artguard.prod',
    redirectURI:
      'https://api.artguard.pl/api/v1/oauth2/authorization/apple-return',
    responseType: 'code id_token',
    scope: 'name email',
    responseMode: 'form_post',
  },
  scrapersApi: {
    url: 'https://scrapers.artguard.app/v1/',
    token: 'EmpGCnRQeL2RxRMNCbLHtyr1jnjQ69n26rWQ2xbMhT6EoYTjr8',
  },
  stripe: {
    publishableKey:
      'pk_live_51NkRcwECUAdlbL5PLNC6o2ECSzS5n1w0U33OuFWPje7C4JoCrurs5fp0VnvkwMDJZia4JfFig3rLoor2uBV6K5Xb00iYnoH5wu',
    paymentLinks: {
      active_monthly: 'https://buy.stripe.com/3cs8zP2uy9022J2cMT',
      active_annual: 'https://buy.stripe.com/3cs8zP2uy9022J2cMT',
      avenue_monthly: 'https://buy.stripe.com/7sI4jz1qu0twgzS4go',
      avenue_annual: 'https://buy.stripe.com/7sI17n4CGa46fvOfZ7',
      avenuePlus_monthly: 'https://buy.stripe.com/5kA7vL4CG1xAfvOeV4',
      avenuePlus_annual: 'https://buy.stripe.com/8wMaHXb146RU3N628j',
      image_monthly: 'https://buy.stripe.com/aEUg2h5GKdgi83meUU',
      image_annual: 'https://buy.stripe.com/7sI8zP4CG2BEgzS5kl',
      original_monthly: 'https://buy.stripe.com/7sI8zPd9cdgi6ZifZb',
      original_annual: 'https://buy.stripe.com/bIY17nc586RUbfy14g',
      passion_monthly: 'https://buy.stripe.com/7sI17nd9ca46dnG7su',
      passion_annual: 'https://buy.stripe.com/5kAbM17OS1xAgzS9AD',
      royal_monthly: 'https://buy.stripe.com/bIY7vL3yCekm5VecMQ',
      royal_annual: 'https://buy.stripe.com/7sIdU92uycce3N6aEJ',
    },
  },
};
