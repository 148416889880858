<div class="content">
  <form *ngIf="form" [formGroup]="form" (ngSubmit)="applyFilters()">
    <div class="sidebar-header">
      <div class="sidebar-title">{{ 'common.filters.plural' | translate }}</div>
      <div class="sidebar-close" (click)="closeSidebar()">
        <svg-icon
          src="/assets/icons/x.svg"
          [svgStyle]="{ fill: '#000', 'height.px': 20 }"
        ></svg-icon>
      </div>
    </div>
    <div class="filters-list">
      <ng-template ngFor let-filter [ngForOf]="filters" let-index="index">
        <div
          *ngIf="!filter.hidden ?? true"
          class="filters-item"
          [class.filters-item-range]="
            filter.internalName.startsWith('from') || filter.internalName.startsWith('to')
          "
        >
          <ng-container *ngIf="filter.options || filter.apiOptions">
            <atom-select
              [label]="filter.publicName | translate"
              [control]="form.controls[filter.internalName]"
              [options]="
                filter.internalName == 'styleUid'
                  ? (subjectStyles$ | async)
                  : filter.options ?? null
              "
              [apiOptions]="filter.apiOptions"
              [nullable]="filter.nullable ?? false"
              [optionsValueProp]="filter.optionsValueProperty"
            ></atom-select>
          </ng-container>
          <ng-container *ngIf="!filter.options && !filter.apiOptions">
            <atom-input
              [label]="filter.publicName | translate"
              [control]="form.controls[filter.internalName]"
              [type]="filter.type"
              [disabled]="filter.disabled ?? false"
            ></atom-input>
          </ng-container>
        </div>
      </ng-template>
    </div>
    <div class="sidebar-footer">
      <atom-button
        label="Zastosuj"
        type="submit"
        theme="submit"
        [disabled]="form.invalid"
        [isLoading]="isLoading"
      ></atom-button>
    </div>
  </form>
</div>
