<div class="content">
    <atom-spinner *ngIf="isLoading else table"></atom-spinner>
    <ng-template #table>
        <div class="table-container">
            <table #tableRef class="table" mat-table multiTemplateDataRows [dataSource]="dataSource">
                <ng-container *ngIf="checkboxMode !== 'none'" matColumnDef="checkbox">
                    <th class="column-cell checkbox" mat-header-cell *matHeaderCellDef>
                        <mat-checkbox *ngIf="checkboxMode === 'multiple'" (change)="onCheckboxChangeAll()"
                            [checked]="this.selectedRowUids.length === this.data.length"></mat-checkbox>
                    </th>
                    <td class="row-cell checkbox" mat-cell *matCellDef="let row">
                        <mat-checkbox (change)="onCheckboxChange(row.uid)"
                            [checked]="selectedRowUids.includes(row.uid)"></mat-checkbox>
                    </td>
                </ng-container>
                <ng-container [matColumnDef]="column.fieldName" *ngFor="let column of columns; let i = index">
                    <ng-container *ngIf="column.type === 'image'">
                        <th class="column-cell image" mat-header-cell *matHeaderCellDef #columnRef>
                            <div class="column-header">
                                <div class="column-name">
                                    <span>{{ column.title! | translate }}</span>
                                </div>
                            </div>
                        </th>
                    </ng-container>
                    <ng-container *ngIf="column.type !== 'image'">
                        <th class="column-cell" mat-header-cell *matHeaderCellDef #columnRef>
                            <div class="column-header">
                                <div class="column-name">
                                    <span>{{ column.title! | translate }}</span>
                                </div>
                            </div>
                        </th>
                    </ng-container>
                    <ng-container [ngSwitch]="column.type">
                        <ng-container *ngSwitchCase="'image'">
                            <td class="row-cell image" mat-cell *matCellDef="let row">
                                <div class="image" [style.width.px]="checkboxMode === 'none' ? '30' : '40'"
                                    [style.height.px]="checkboxMode === 'none' ? '30' : '40'">
                                    <img *ngIf="row[column.fieldName]"
                                        [src]="staticService.getStaticUrl(row[column.fieldName].url)" />
                                    <svg-icon *ngIf="!row[column.fieldName]" src="/assets/icons/placeholder.svg"
                                        [svgStyle]="{ 'fill': '#5e6369', 'height.px' : 20, 'width.px': 20 }"></svg-icon>
                                </div>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'text'">
                            <td class="row-cell" mat-cell *matCellDef="let row">
                                <span>{{ row[column.fieldName] }}</span>
                            </td>
                        </ng-container>
                        <ng-container *ngSwitchCase="'date'">
                            <td class="row-cell" mat-cell *matCellDef="let row">
                                <span>{{ row[column.fieldName] | date: 'dd.MM.yyyy' }}</span>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="allowActions" matColumnDef="actions">
                    <th class="column-cell actions" mat-header-cell *matHeaderCellDef #columnRef>
                    </th>
                    <td class="row-cell actions" mat-cell *matCellDef="let row">
                        <div class="actions" [matMenuTriggerFor]="actionsMenu" (click)="actionRowUid = row.uid">
                            <svg-icon class="actions-icon" src="/assets/icons/more.svg"
                                [svgStyle]="{ 'fill': '#000', 'height.px' : 18}"></svg-icon>
                        </div>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="row"></tr>
            </table>
        </div>
    </ng-template>
</div>

<mat-menu #actionsMenu="matMenu">
    <ul class="dropdown">
        <li *ngIf="allowEdit" class="dropdown-item" (click)="onEdit()">
            <svg-icon src="assets/icons/edit_yellow.svg" [svgStyle]="{'height.px' : 18 }"></svg-icon>
            <span class="title">{{ 'common.buttons.edit' | translate }}</span>
        </li>
        <li *ngIf="allowDelete" class="dropdown-item" (click)="onDelete()">
            <svg-icon src="assets/icons/delete_red.svg" [svgStyle]="{'height.px' : 18 }"></svg-icon>
            <span class="title">{{ 'common.buttons.delete' | translate }}</span>
        </li>
    </ul>
</mat-menu>