import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SaleResponse } from 'src/app/cms/features/sales/models/sale.model';
import { SaleService } from 'src/app/cms/features/sales/services/sale.service';
import { TableStateService } from '../../utils/services/table-state.service';
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_TYPE,
  DEFAULT_PAGE,
  DEFAULT_SEARCH,
} from '../../utils/common';
import { TableState } from '../../models/table.model';
import { StaticService } from '../../services/static.service';
import { GalleryImage } from '../../models/gallery-image.model';

@Component({
  selector: 'app-recommended-sales',
  templateUrl: './recommended-sales.component.html',
  styleUrls: ['./recommended-sales.component.scss'],
})
export class RecommendedSalesComponent {
  @Input() mode: 'expandable' | 'details' = 'expandable';
  @Output() contentLoaded = new EventEmitter();

  isLoading: boolean = false;
  recommendedSales: SaleResponse[] = [];
  recommendedSalesImages: GalleryImage[][] = [];
  tableState: TableState = {
    search: DEFAULT_SEARCH,
    page: DEFAULT_PAGE,
    pageSize: 6,
    total: 0,
    orderBy: DEFAULT_ORDER_BY,
    orderType: DEFAULT_ORDER_TYPE,
  };

  constructor(
    private saleService: SaleService,
    private tableStateService: TableStateService,
    private staticService: StaticService
  ) {}

  ngOnInit(): void {
    this.getRecommendedSales();
  }

  getRecommendedSales(): void {
    this.isLoading = true;
    const params = this.tableStateService.createQueryParams(this.tableState);
    this.saleService
      .getRecommendedSales(params)
      .then((response) => {
        this.recommendedSales = response.result.results;
        this.recommendedSalesImages = this.recommendedSales.map((sale) => {
          return sale.mainPhotoUrlsMiniatures.length > 0
            ? sale.mainPhotoUrlsMiniatures.map((url) => ({
                url: this.staticService.getStaticUrl(url),
                filename: url,
              }))
            : [
                {
                  url: 'assets/icons/placeholder.svg',
                  filename: 'placeholder',
                },
              ];
        });
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
