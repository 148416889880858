import { Component, Input } from '@angular/core';

import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { InputType } from 'src/app/shared/utils/common';
import { handleValidationErrorMessage } from 'src/app/shared/utils/validators/validators';

@Component({
  selector: 'atom-input',
  templateUrl: './atom-input.component.html',
  styleUrls: ['./atom-input.component.scss'],
})
export class AtomInputComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() type: InputType = 'text';
  @Input() control!: AbstractControl;
  @Input() disabled: boolean = false;
  @Input() autocomplete: string = 'off';

  get formControl() {
    return this.control as FormControl;
  }

  get isRequired() {
    return this.formControl.hasValidator(Validators.required);
  }

  public errorHandler = handleValidationErrorMessage;
}
