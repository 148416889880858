import { Component, Input } from '@angular/core';
import { ArticlesResponse } from 'src/app/cms/features/articles/models/sale.model';
import { SaleService } from 'src/app/cms/features/sales/services/sale.service';
import { ApiHelper } from 'src/app/core/api/api-helper';
import { ToastrService } from 'src/app/shared/utils/services/toastr.service';

@Component({
  selector: 'app-article-card-data',
  templateUrl: './article-card-data.component.html',
  styleUrls: ['./article-card-data.component.scss'],
})
export class ArticleCardDataComponent {
  @Input() articlesData!: ArticlesResponse;
  @Input() borderRadiusTop: boolean = false;
  @Input() borderRadiusBottom: boolean = false;
  @Input() width: string = 'auto';
  @Input() saleType!: string;

  constructor(
    private saleService: SaleService,
    private toastrService: ToastrService
  ) {}

  ngOnInit(): void {}

}
