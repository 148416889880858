<div class="tile padding">
  <div class="content">
    <div class="details">
      <div class="detail" *ngFor="let detail of data">
        <div class="key">
          <span>{{ detail.key | translate }}</span>
        </div>
        <div class="value" [class.highlighted]="detail.highlighted">
          <span *ngIf="detail.type == 'text'">
            {{ detail.value }}
          </span>
          <span *ngIf="detail.type == 'date'">
            {{ detail.value | date: 'dd.MM.yyyy' }}
          </span>
          <span *ngIf="detail.type == 'datetime'">
            {{ detail.value | date: 'dd.MM.yyyy HH:MM' }}
          </span>
        </div>
      </div>
    </div>
  </div>
</div>
