<div class="content">
    <div class="wrapper">
        <label *ngIf="label">{{label | translate}}</label>
        <div class="input" (click)="picker.open()">
            <span *ngIf="!formControlStart.value && !formControlEnd.value">{{ placeholder | translate }}</span>
            <ng-container *ngIf="formControlStart.value || formControlEnd.value">
                <span *ngIf="formControlStart.value">
                    {{ formControlStart.value | date: 'dd.MM.yyyy' }}
                </span>
                -
                <span *ngIf="formControlEnd.value">
                    {{ formControlEnd.value | date: 'dd.MM.yyyy' }}
                </span>
            </ng-container>
        </div>
        <div [style.display]="'none'">
            <mat-date-range-input [rangePicker]="picker">
                <input [formControl]="formControlStart" matStartDate>
                <input [formControl]="formControlEnd" matEndDate>
            </mat-date-range-input>
        </div>
        <mat-date-range-picker #picker></mat-date-range-picker>
    </div>
</div>