import { Injectable } from '@angular/core';
import { DictionaryApiService } from './dictionary.api.service';
import { SubscriptionApiService } from './subscription.api.service';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { DictionaryWord } from '../models/dictionary.model';
import { SubscriptionResponse } from '../models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  constructor(
    private subscriptionApiService: SubscriptionApiService,
    private dictionaryApiService: DictionaryApiService
  ) {}

  getSubscriptions(): Promise<ApiResponseModel<DictionaryWord[]>> {
    return this.dictionaryApiService.getSubscriptions();
  }

  getSubscriptionLimits(
    subscriptionInternalName: string
  ): Promise<ApiResponseModel<SubscriptionResponse>> {
    return this.subscriptionApiService.getSubscriptionLimits(
      subscriptionInternalName
    );
  }

  cancelSubscriptions(): Promise<ApiResponseModel<DictionaryWord[]>> {
    return this.subscriptionApiService.cancelSubscriptions();
  }
}
