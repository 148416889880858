import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  _publishableKey: string = environment.stripe.publishableKey;
  activeMonthly: string = environment.stripe.paymentLinks.active_monthly;
  activeAnnual: string = environment.stripe.paymentLinks.active_annual;
  avenueMonthly: string = environment.stripe.paymentLinks.avenue_monthly;
  avenueAnnual: string = environment.stripe.paymentLinks.avenue_annual;
  avenuePlusMonthly: string =
    environment.stripe.paymentLinks.avenuePlus_monthly;
  avenuePlusAnnual: string = environment.stripe.paymentLinks.avenuePlus_annual;
  imageMonthly: string = environment.stripe.paymentLinks.image_monthly;
  imageAnnual: string = environment.stripe.paymentLinks.image_annual;
  originalMonthly: string = environment.stripe.paymentLinks.original_monthly;
  originalAnnual: string = environment.stripe.paymentLinks.original_annual;
  passionMonthly: string = environment.stripe.paymentLinks.passion_monthly;
  passionAnnual: string = environment.stripe.paymentLinks.passion_annual;
  royalMonthly: string = environment.stripe.paymentLinks.royal_monthly;
  royalAnnual: string = environment.stripe.paymentLinks.royal_annual;

  buyMonthly(subscriptionInternalName: string) {
    let paymentLink = '';

    switch (subscriptionInternalName) {
      case 'Active':
        paymentLink = this.activeMonthly;
        break;
      case 'Avenue':
        paymentLink = this.avenueMonthly;
        break;
      case 'AvenuePlus':
        paymentLink = this.avenuePlusMonthly;
        break;
      case 'Image':
        paymentLink = this.imageMonthly;
        break;
      case 'Original':
        paymentLink = this.originalMonthly;
        break;
      case 'Passion':
        paymentLink = this.passionMonthly;
        break;
      case 'Royal':
        paymentLink = this.royalMonthly;
        break;
      default:
        paymentLink = '';
        break;
    }

    return paymentLink;
  }

  buyAnnual(subscriptionInternalName: string) {
    let paymentLink = '';

    switch (subscriptionInternalName) {
      case 'Active':
        paymentLink = this.activeAnnual;
        break;
      case 'Avenue':
        paymentLink = this.avenueAnnual;
        break;
      case 'AvenuePlus':
        paymentLink = this.avenuePlusAnnual;
        break;
      case 'Image':
        paymentLink = this.imageAnnual;
        break;
      case 'Original':
        paymentLink = this.originalAnnual;
        break;
      case 'Passion':
        paymentLink = this.passionAnnual;
        break;
      case 'Royal':
        paymentLink = this.royalAnnual;
        break;
      default:
        paymentLink = '';
        break;
    }

    return paymentLink;
  }
}
