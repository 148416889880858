import { Injectable } from '@angular/core';
import { SaleApiService } from './sale.api.service';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { ApiPagingResponseModel } from 'src/app/core/api/api-paging-response.model';
import {
  SaleAuctionBidRequest,
  SaleForMultipleSubjectsCreateRequest,
  SaleForSingleSubjectCreateRequest,
  SaleForWholeCollectionCreateRequest,
  SaleLightResponse,
  SaleOfferResponse,
  SaleRentRequest,
  SaleResponse,
  SaleResponseMapped,
} from '../models/sale.model';
import { UserWithSubcategoriesResponse } from 'src/app/shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SaleService {
  constructor(private saleApiService: SaleApiService) {}

  mapSaleResponse(saleResponse: SaleResponse): SaleResponseMapped {
    return {
      uid: saleResponse.uid,
      createdAt: saleResponse.createdAt,
      initialPrice: saleResponse.initialPrice,
      minimalPrice: saleResponse.minimalPrice,
      currentBidPrice: saleResponse.currentBidPrice,
      startDate: saleResponse.startDate,
      authorsNames: saleResponse.authorsNames,
      subjectNames: saleResponse.saleSubjectNames,
      collectionNames: saleResponse.saleCollectionNames,
      typePublicName: saleResponse.type.publicName,
      typeInternalName: saleResponse.type.internalName,
      statusPublicName: saleResponse.saleStatus.publicName,
      statusInternalName: saleResponse.saleStatus.internalName,
      baseCurrencyPublicName: saleResponse.baseCurrency.publicName,
      mainPhotoUrls: saleResponse.mainPhotoUrls,
      mainPhotoUrlsMiniatures: saleResponse.mainPhotoUrlsMiniatures,
      additionalPhotoUrls: saleResponse.additionalPhotoUrls,
      subjects: saleResponse.subjects,
      collectionUid: saleResponse.collectionUid,
      rentStartDate: saleResponse.rentStartDate,
      rentEndDate: saleResponse.rentEndDate,
      reservedFor: saleResponse.reservedFor || null,
    };
  }

  getSales(query: string | null): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    return this.saleApiService.getSales(query);
  }

  getMySales(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    return this.saleApiService.getMySales(query);
  }

  getMyFavoriteSales(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    return this.saleApiService.getMyFavoriteSales(query);
  }

  getRecommendedSales(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    return this.saleApiService.getRecommendedSales(query);
  }

  getSalesByType(
    saleTypeUid: string,
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    return this.saleApiService.getSalesByType(saleTypeUid, query);
  }

  getSale(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.getSale(saleUid);
  }

  updateSale(saleUid: string, data: any): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.updateSale(saleUid, data);
  }

  deleteSale(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.deleteSale(saleUid);
  }

  createSaleForWholeCollection(
    saleData: SaleForWholeCollectionCreateRequest
  ): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.createSaleForWholeCollection(saleData);
  }

  createSaleForMultipleSubjects(
    saleData: SaleForMultipleSubjectsCreateRequest
  ): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.createSaleForMultipleSubjects(saleData);
  }

  createSaleForSingleSubject(
    saleData: SaleForSingleSubjectCreateRequest
  ): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.createSaleForSingleSubject(saleData);
  }

  getPopularSellers(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<UserWithSubcategoriesResponse>>> {
    return this.saleApiService.getPopularSellers(query);
  }

  toggleFavorite(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.toggleFavorite(saleUid);
  }

  sendRentRequest(saleUid: string, data: SaleRentRequest): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.sendRentRequest(saleUid, data);
  }

  buyNow(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.buyNow(saleUid);
  }

  setReserved(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.setReserved(saleUid);
  }

  cancelReservation(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.cancelReservation(saleUid);
  }
  markAsSold(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.markAsSold(saleUid);
  }

  sendAuctionBid(
    saleUid: string,
    data: SaleAuctionBidRequest
  ): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.sendAuctionBid(saleUid, data);
  }

  getSaleLight(saleUid: string): Promise<ApiResponseModel<SaleLightResponse>> {
    return this.saleApiService.getSaleLight(saleUid);
  }

  auctionFirstCall(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.auctionFirstCall(saleUid);
  }

  auctionSecondCall(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.auctionSecondCall(saleUid);
  }

  auctionThirdCall(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.auctionThirdCall(saleUid);
  }

  auctionSoldCall(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    return this.saleApiService.auctionSoldCall(saleUid);
  }
}
