import { Injectable } from '@angular/core';
import { TableState, TableStateArticles } from '../../models/table.model';
import { FilterQueryParam } from '../../models/filter.model';

@Injectable({
  providedIn: 'root',
})
export class TableStateService {
  constructor() {}

  createQueryParams(tableState: TableState, filters?: FilterQueryParam[]): any {
    const params = new URLSearchParams();
    tableState.search && params.set('search', tableState.search);
    params.set('page', tableState.page.toString());
    params.set('pageSize', tableState.pageSize.toString());
    tableState.authorUid && params.set('authorUid', tableState.authorUid.toString());
    tableState.styleUid && params.set('styleUid', tableState.styleUid.toString());
    tableState.saleStatusInternalName &&
      params.set('saleStatusInternalName', tableState.saleStatusInternalName.toString());
    params.set('orderBy', tableState.orderBy);
    params.set('orderType', tableState.orderType);
    if (filters) {
      filters.forEach((filter: FilterQueryParam) => {
        const values = filter.value.split(',');
        values.forEach((value: string) => {
          params.append(filter.internalName, value);
        });
      });
    }
    return `?${params.toString()}`;
  }
}
