import { Injectable } from '@angular/core';
import { LoginRequest, LoginResponse } from 'src/app/auth/models/login.model';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ForgotPasswordRequest } from '../models/forgot-password.model';
import { UserResponse } from 'src/app/shared/models/user.model';
import { ResetPasswordRequest } from '../models/reset-password.model';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { UserInfoResponse } from '../models/user.model';
import { ToastrService } from 'src/app/shared/utils/services/toastr.service';
import {
  DEFAULT_ADMIN_USER_ROLE_INTERNAL_NAME,
  DEFAULT_BUSINESS_OWNER_PUBLIC_ROLE_INTERNAL_NAME,
} from 'src/app/shared/utils/common';
import { TranslateService } from '@ngx-translate/core';

const TOKEN = 'token';
const REFRESH_TOKEN = 'refresh_token';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  apiPrefix = '/api/v1/auth';

  private token: string | null = null;
  private tokenExpirationDate: Date | null = null;
  private refreshToken: string | null = null;

  private userInfo: UserInfoResponse | null = null;
  private activeAuthState: AuthState = 'loading';

  public _userData: BehaviorSubject<UserInfoResponse | null> = new BehaviorSubject(this.userInfo);
  public _authState: BehaviorSubject<AuthState> = new BehaviorSubject(this.activeAuthState);

  get isAuthenticated() {
    return this.activeAuthState === 'authenticated';
  }

  get isRegistrationComplete() {
    return this.userInfo?.finishedRegistration;
  }

  get isBusinessOwner() {
    return this.userInfo?.roles.some(
      (role) => role.internalName === DEFAULT_BUSINESS_OWNER_PUBLIC_ROLE_INTERNAL_NAME
    );
  }

  get userData() {
    return this.userInfo;
  }

  constructor(
    private httpClient: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private toastr: ToastrService,
    private translate: TranslateService
  ) {
    this._authState.subscribe((state) => {
      this.activeAuthState = state;
    });

    this.readUserFromStorage();
  }

  async isRegistrationCompleteAsync(): Promise<boolean> {
    const isAuthenticated = await this.isAuthenticatedAsync();
    if (!isAuthenticated) return false;
    const userInfo = await this.getUserInfo();
    return userInfo.result.finishedRegistration;
  }

  async isAuthenticatedAsync(): Promise<boolean> {
    const token = this.storageService.read<string>(TOKEN);
    const refreshToken = this.storageService.read<string>(REFRESH_TOKEN);

    if (!token) {
      return false;
    }

    const decodedToken = jwtDecode<ApiJwtPayload>(token);
    if (!decodedToken) {
      return false;
    }

    const expirationDate = new Date(decodedToken.exp * 1000);
    if (expirationDate < new Date()) {
      if (refreshToken) {
        try {
          const response = await this.refreshTokens();
          this.setTokens(response.result.token, response.result.refreshToken);
          return true;
        } catch (error) {
          this.clearAuthData(true);
          return false;
        }
      } else {
        this.clearAuthData(true);
        return false;
      }
    }
    return true;
  }

  async getJwtTokenAsync() {
    this.readUserFromStorage();
    if (!this.token || !this.tokenExpirationDate) return null;
    if (new Date() > this.tokenExpirationDate) {
      if (this.refreshToken) {
        try {
          const response = await this.refreshTokens();
          this.setTokens(response.result.token, response.result.refreshToken);
          return this.token;
        } catch (error) {
          this.clearAuthData(true);
          return null;
        }
      } else {
        this.clearAuthData(true);
        return null;
      }
    }
    return this.token;
  }

  private refreshTokens() {
    const refreshTokenRequest = this.httpClient.post<ApiResponseModel<LoginResponse>>(
      environment.apiUrl + `${this.apiPrefix}/refresh`,
      {
        token: this.token,
        refreshToken: this.refreshToken,
      }
    );

    return lastValueFrom(refreshTokenRequest);
  }

  private readUserFromStorage() {
    const token = this.storageService.read<string>(TOKEN);
    const refreshToken = this.storageService.read<string>(REFRESH_TOKEN);

    if (!token) return this.clearAuthData(true);
    if (token === this.token && refreshToken === this.refreshToken) return;
    this.setTokens(token, refreshToken ? refreshToken : '');
  }

  login(data: LoginRequest): Promise<ApiResponseModel<LoginResponse>> {
    const tokenRequest = this.httpClient.post<ApiResponseModel<LoginResponse>>(
      environment.apiUrl + `${this.apiPrefix}/token`,
      data
    );

    return lastValueFrom(tokenRequest).then((response) => {
      this.setTokens(response.result.token, response.result.refreshToken);
      return response;
    });
  }

  setTokens(token: string, refreshToken: string, propagate: boolean = true) {
    if (token === null || token === '') return this.clearAuthData(propagate);

    const decodedToken = jwtDecode<ApiJwtPayload>(token);

    if (!decodedToken) return this.clearAuthData(propagate);

    const expirationDate = new Date(decodedToken.exp * 1000);

    if (expirationDate < new Date()) return this.clearAuthData(propagate);

    this.token = token;
    this.tokenExpirationDate = expirationDate;

    if (refreshToken !== null && refreshToken !== '') {
      this.refreshToken = refreshToken;
    } else {
      this.refreshToken = null;
      this.storageService.remove(REFRESH_TOKEN);
    }

    this.saveUserToStorage();

    if (propagate) {
      this._authState.next('authenticated');
    }

    if (this.activeAuthState === 'loading' || !this.activeAuthState) {
      this.activeAuthState = 'authenticated';
    }

    this.loadUserData(propagate);
  }

  clearAuthData(propagate: boolean) {
    if (propagate) {
      this._authState.next('unauthenticated');
      this._userData.next(null);
    }

    this.storageService.remove(TOKEN);
    this.storageService.remove(REFRESH_TOKEN);

    this.token = null;
    this.tokenExpirationDate = null;
    this.userInfo = null;
  }

  private saveUserToStorage() {
    this.storageService.save(TOKEN, this.token);
    this.refreshToken && this.storageService.save(REFRESH_TOKEN, this.refreshToken);
  }

  private loadUserData(propagate: boolean) {
    const tokenRequest = this.httpClient.get<ApiResponseModel<UserInfoResponse>>(
      environment.apiUrl + AUTH_URLS.userInfo,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    );

    return lastValueFrom(tokenRequest).then((response) => {
      this.setUser(response.result, propagate);
      this.setLanguage(response.result);
    });
  }

  private setUser(userData: UserInfoResponse, propagate: boolean = true) {
    this.userInfo = userData;
    if (propagate) {
      this._userData.next(userData);
    }
  }

  private setLanguage(userData: UserInfoResponse) {
    this.userInfo = userData;
    this.translate.use(this.userInfo.defaultLanguage.internalName);
  }

  logout() {
    this.clearAuthData(true);
    this.router.navigateByUrl('');
    window.location.reload();
  }

  forgotPassword(data: ForgotPasswordRequest): Promise<any> {
    const forgotPasswordRequest = this.httpClient.post<any>(
      environment.apiUrl + `${this.apiPrefix}/password`,
      data
    );

    return lastValueFrom(forgotPasswordRequest);
  }

  resetPassword(data: ResetPasswordRequest): Promise<any> {
    const resetPasswordRequest = this.httpClient.put<any>(
      environment.apiUrl + `${this.apiPrefix}/password`,
      data
    );
    return lastValueFrom(resetPasswordRequest);
  }

  getUserInfo(): Promise<ApiResponseModel<UserInfoResponse>> {
    const userInfoRequest = this.httpClient.get<ApiResponseModel<UserInfoResponse>>(
      environment.apiUrl + AUTH_URLS.userInfo,
      {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      }
    );

    return lastValueFrom(userInfoRequest);
  }

  refreshUserInfo(): Promise<ApiResponseModel<UserInfoResponse>> {
    return this.getUserInfo()
      .then((response) => {
        this.setUser(response.result);
        return response;
      })
      .catch((error) => {
        this.toastr.error(error.error.message);
        return error;
      });
  }
}

export type AuthState = 'loading' | 'reloading' | 'authenticated' | 'unauthenticated';

export interface ApiJwtPayload extends JwtPayload {
  email: string;
  exp: number;
  iat: number;
  jti: string;
  sub: string;
  type: string;
  uuid: string;
}

export const AUTH_URLS = {
  login: '/api/v1/auth/token',
  remindPassword: '/api/v1/auth/password',
  resetPassword: '/api/v1/auth/password',
  userInfo: '/api/v1/auth/me',
};
