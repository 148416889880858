import { Injectable } from '@angular/core';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { ApiService } from 'src/app/core/api/api.service';
import { MessageResponse } from '../models/message.model';
import { lastValueFrom } from 'rxjs';
import { ApiPagingResponseModel } from 'src/app/core/api/api-paging-response.model';

@Injectable({
  providedIn: 'root',
})
export class MessageApiService extends ApiService {
  apiPrefix: string = '/api/v1/message';

  getMyMessages(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<MessageResponse>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<MessageResponse>>
    >(`${this.apiPrefix}/mine` + query, null);
    return lastValueFrom(request);
  }

  getMessagesCount(query: string | null): Promise<ApiResponseModel<number>> {
    const request = this.get<ApiResponseModel<number>>(
      `${this.apiPrefix}/count${query}`,
      null
    );
    return lastValueFrom(request);
  }

  markAsRead(messageUid: string): Promise<ApiResponseModel<MessageResponse>> {
    const request = this.post<ApiResponseModel<MessageResponse>>(
      `${this.apiPrefix}/read/${messageUid}`,
      null
    );
    return lastValueFrom(request);
  }
}
