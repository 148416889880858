import { Injectable } from '@angular/core';
import { DictionaryWord } from '../models/dictionary.model';
import { DictionaryApiService } from './dictionary.api.service';
import {
  DEFAULT_MAIN_PHOTO_TYPE_INTERNAL_NAME,
  DEFAULT_OTHER_PHOTO_TYPE_INTERNAL_NAME,
  DEFAULT_RANKING_PERIOD_INTERNAL_NAME,
  DEFAULT_SALE_ACTIVE_STATUSES_INTERNAL_NAMES,
  DEFAULT_SALE_ENDED_STATUSES_INTERNAL_NAMES,
  DEFAULT_SUBJECT_CATEGORY_INTERNAL_NAME,
  DEFAULT_SUBJECT_STATUS_INTERNAL_NAME,
  DEFAULT_SUBJECT_TYPE_INTERNAL_NAME,
} from '../utils/common';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DictionaryService {
  private defaultSubjectMetadataTypesSource = new BehaviorSubject<DictionaryWord[]>([]);
  defaultSubjectMetadataTypes$ = this.defaultSubjectMetadataTypesSource.asObservable();

  defaultSubjectTypeInternalName: string = DEFAULT_SUBJECT_TYPE_INTERNAL_NAME;
  defaultSubjectStatusInternalName: string = DEFAULT_SUBJECT_STATUS_INTERNAL_NAME;
  defaultMainPhotoTypeInternalName: string = DEFAULT_MAIN_PHOTO_TYPE_INTERNAL_NAME;
  defaultOtherPhotoTypeInternalName: string = DEFAULT_OTHER_PHOTO_TYPE_INTERNAL_NAME;
  defaultSubjectCategoryInternalName: string = DEFAULT_SUBJECT_CATEGORY_INTERNAL_NAME;
  defaultSaleActiveStatusesInternalNames: string[] = DEFAULT_SALE_ACTIVE_STATUSES_INTERNAL_NAMES;
  defaultSaleEndedStatusesInternalNames: string[] = DEFAULT_SALE_ENDED_STATUSES_INTERNAL_NAMES;
  defaultRankingPeriodInternalName: string = DEFAULT_RANKING_PERIOD_INTERNAL_NAME;

  private _currencies$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _subjectStyles$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _subjectStatuses$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _photoTypes$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _subjectCategories$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _subjectSubcategories$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _subjectMetadataTypes$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _saleStatuses$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _saleTypes$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _saleUnitTypes$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _businessRoles$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _publicRoles$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _languages$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _accessGroups$ = new BehaviorSubject<DictionaryWord[]>([]);
  private _rankingPeriods$ = new BehaviorSubject<DictionaryWord[]>([]);

  private _defaultMainPhotoTypeUid: string | undefined;
  private _defaultOtherPhotoTypeUid: string | undefined;
  private _defaultSubjectCategoryUid: string | undefined;
  private _defaultSubjectStatusUid: string | undefined;
  private _defaultSaleActiveStatusesUids: string[] = [];
  private _defaultSaleEndedStatusesUids: string[] = [];
  private _defaultRankingPeriodUid: string | undefined;
  private _defaultRankingPeriodUid$ = new BehaviorSubject<string | undefined>('');

  get currencies$(): Observable<DictionaryWord[]> {
    return this._currencies$.asObservable();
  }

  get subjectStyles$(): Observable<DictionaryWord[]> {
    return this._subjectStyles$.asObservable();
  }

  get subjectStatuses$(): Observable<DictionaryWord[]> {
    return this._subjectStatuses$.asObservable();
  }

  get photoTypes$(): Observable<DictionaryWord[]> {
    return this._photoTypes$.asObservable();
  }

  get subjectCategories$(): Observable<DictionaryWord[]> {
    return this._subjectCategories$.asObservable();
  }

  get subjectSubcategories$(): Observable<DictionaryWord[]> {
    return this._subjectSubcategories$.asObservable();
  }

  get subjectMetadataTypes$(): Observable<DictionaryWord[]> {
    return this._subjectMetadataTypes$.asObservable();
  }

  get saleStatuses$(): Observable<DictionaryWord[]> {
    return this._saleStatuses$.asObservable();
  }

  get saleTypes$(): Observable<DictionaryWord[]> {
    return this._saleTypes$.asObservable();
  }

  get saleUnitTypes$(): Observable<DictionaryWord[]> {
    return this._saleUnitTypes$.asObservable();
  }

  get businessRoles$(): Observable<DictionaryWord[]> {
    return this._businessRoles$.asObservable();
  }

  get publicRoles$(): Observable<DictionaryWord[]> {
    return this._publicRoles$.asObservable();
  }

  get languages$(): Observable<DictionaryWord[]> {
    return this._languages$.asObservable();
  }

  get accessGroups$(): Observable<DictionaryWord[]> {
    return this._accessGroups$.asObservable();
  }

  get rankingPeriods$(): Observable<DictionaryWord[]> {
    return this._rankingPeriods$.asObservable();
  }

  // default values

  get defaultMainPhotoTypeUid(): string | undefined {
    return this._defaultMainPhotoTypeUid;
  }

  get defaultOtherPhotoTypeUid(): string | undefined {
    return this._defaultOtherPhotoTypeUid;
  }

  get defaultSubjectStatusUid(): string | undefined {
    return this._defaultSubjectStatusUid;
  }

  get defaultSubjectCategoryUid(): string | undefined {
    return this._defaultSubjectCategoryUid;
  }

  get defaultSaleActiveStatusesUids(): string[] {
    return this._defaultSaleActiveStatusesUids;
  }

  get defaultSaleEndedStatusesUids(): string[] {
    return this._defaultSaleEndedStatusesUids;
  }

  get defaultRankingPeriodUid(): string | undefined {
    return this._defaultRankingPeriodUid;
  }

  get defaultRankingPeriodUid$(): Observable<string | undefined> {
    return this._defaultRankingPeriodUid$.asObservable();
  }

  constructor(private dictionaryApiService: DictionaryApiService) {
    this.getDictionaryWords();
  }

  public refreshDictionaryWords() {
    this.getDictionaryWords();
  }

  private getDictionaryWords() {
    this.getSubjectStyles();
    this.getSubjectStatuses();
    this.getPhotoTypes();
    this.getCurrencies();
    this.getSubjectCategories();
    this.getSubjectSubcategories();
    this.getSubjectMetadataTypes();
    this.getSaleStatuses();
    this.getSaleTypes();
    this.getSaleUnitTypes();
    this.getBusinessRoles();
    this.getLanguages();
    this.getAccessGroups();
    this.getRankingPeriods();
    this.getPublicRoles();
  }

  private getCurrencies() {
    this.dictionaryApiService.getCurrencies().then((response) => {
      this._currencies$.next(response.result);
    });
  }

  private getSubjectStyles() {
    this.dictionaryApiService.getSubjectStyles().then((response) => {
      this._subjectStyles$.next(response.result);
    });
  }

  private getSubjectStatuses() {
    this.dictionaryApiService.getSubjectStatuses().then((response) => {
      this._subjectStatuses$.next(response.result);
      this._defaultSubjectStatusUid = response.result.find(
        (status) => status.internalName === this.defaultSubjectStatusInternalName
      )?.uid;
    });
  }

  private getPhotoTypes() {
    this.dictionaryApiService.getPhotoTypes().then((response) => {
      this._photoTypes$.next(response.result);
      this._defaultMainPhotoTypeUid = response.result.find(
        (type) => type.internalName === this.defaultMainPhotoTypeInternalName
      )?.uid;
      this._defaultOtherPhotoTypeUid = response.result.find(
        (type) => type.internalName === this.defaultOtherPhotoTypeInternalName
      )?.uid;
    });
  }

  private getSubjectCategories() {
    this.dictionaryApiService.getSubjectCategories().then((response) => {
      this._subjectCategories$.next(response.result);
      this._defaultSubjectCategoryUid = response.result.find(
        (category) => category.internalName === this.defaultSubjectCategoryInternalName
      )?.uid;
    });
  }

  private getSubjectSubcategories() {
    this.dictionaryApiService.getSubjectSubcategories().then((response) => {
      this._subjectSubcategories$.next(response.result);
    });
  }

  private getSubjectMetadataTypes() {
    return this.dictionaryApiService.getSubjectMetadataTypes().then((response) => {
      this._subjectMetadataTypes$.next(response.result.results);
    });
  }

  private getSaleStatuses() {
    return this.dictionaryApiService.getSaleStatuses().then((response) => {
      this._saleStatuses$.next(response.result);
      this._defaultSaleActiveStatusesUids = response.result
        .filter((status) =>
          this.defaultSaleActiveStatusesInternalNames.includes(status.internalName)
        )
        .map((status) => status.uid);
      this._defaultSaleEndedStatusesUids = response.result
        .filter((status) =>
          this.defaultSaleEndedStatusesInternalNames.includes(status.internalName)
        )
        .map((status) => status.uid);
    });
  }

  private getSaleTypes() {
    return this.dictionaryApiService.getSaleTypes().then((response) => {
      this._saleTypes$.next(response.result);
    });
  }

  private getSaleUnitTypes() {
    return this.dictionaryApiService.getSaleUnitTypes().then((response) => {
      this._saleUnitTypes$.next(response.result);
    });
  }

  getSubjectSubcategoriesByCategory(categoryUid: string) {
    return this.dictionaryApiService
      .getSubjectSubcategoriesByCategory(categoryUid)
      .then((response) => {
        return response.result;
      });
  }

  getSubjectMetadataTypesBySubcategory(subcategoryUid: string) {
    return this.dictionaryApiService
      .getSubjectMetadataTypesBySubcategory(subcategoryUid)
      .then((response) => {
        if (response) {
          return response.result;
        } else return [];
      });
  }

  private getBusinessRoles() {
    return this.dictionaryApiService.getBusinessRoles().then((response) => {
      this._businessRoles$.next(response.result);
    });
  }

  private getPublicRoles() {
    return this.dictionaryApiService.getPublicRoles().then((response) => {
      this._publicRoles$.next(response.result);
    });
  }

  private getLanguages() {
    return this.dictionaryApiService.getLanguages().then((response) => {
      this._languages$.next(response.result);
    });
  }

  private getAccessGroups() {
    return this.dictionaryApiService.getAccessGroups().then((response) => {
      this._accessGroups$.next(response.result);
    });
  }

  private getRankingPeriods() {
    return this.dictionaryApiService.getRankingPeriods().then((response) => {
      this._rankingPeriods$.next(response.result);
      this._defaultRankingPeriodUid$.next(
        response.result.find(
          (period) => period.internalName === this.defaultRankingPeriodInternalName
        )?.uid
      );
      this._defaultRankingPeriodUid = response.result.find(
        (period) => period.internalName === this.defaultRankingPeriodInternalName
      )?.uid;
    });
  }
}
