<label *ngIf="label">{{ label | translate }}</label>
<div
  class="file-selector {{fileType}}"
  (drop)="onDrop($event)"
  (dragover)="onDragOver($event)"
  (dragleave)="onDragLeave($event)"
  (click)="filePicker.click()"
>
  <div class="content">
    <div class="icon">
      <svg-icon
        *ngIf="fileType == 'image' || fileType == 'avatar' "
        src="assets/icons/picture.svg"
        [svgStyle]="{ fill: '#5e6369', 'height.px': 30 }"
      >
      </svg-icon>
      <svg-icon
        *ngIf="fileType === 'document'"
        src="assets/icons/add_from_disc.svg"
        [svgStyle]="{ fill: '#5e6369', 'height.px': 30 }"
      ></svg-icon>
      <svg-icon
        *ngIf="fileType === 'import'"
        src="assets/icons/add_from_disc.svg"
        [svgStyle]="{ fill: '#5e6369', 'height.px': 30 }"
      ></svg-icon>
    </div>
    <div class="title">{{ "fileSelector.title" | translate }}</div>
    <div class="subtitle">
      <span>* {{ "fileSelector.description.type" | translate }}: </span>
      <span>{{
        fileType === "image" ? "jpg, jpeg, png" : fileType === "avatar" ? "jpg, jpeg, png"  : fileType === "import" ? "csv" : "doc, docx, pdf"
      }}</span>
      <ng-template [ngIf]="fileType === 'avatar'">
        <br/>
        <span [innerHTML]="'fileSelector.description.size' | translate: {dimensions: '360 x 360'}"></span>
      </ng-template>
    </div>
  </div>
  <input
    hidden
    type="file"
    [multiple]="multiple"
    #filePicker
    (change)="onFilePicked($event)"
  />
</div>

<div
  *ngIf="array?.value?.length"
  class="uploaded-files"
  (click)="$event.stopPropagation()"
  [ngClass]="{ image: fileType === 'image', document: fileType === 'document' }"
>
  <ng-container *ngFor="let uploadedFile of array.value; let i = index">
    <div
      *ngIf="!uploadedFile?.isDeleted"
      class="uploaded-file"
      [class.main]="uploadedFile?.isMain && maxFiles > 1"
      (click)="handleMainFileChange(i)"
    >
      <div class="thumbnail">
        <img [src]="uploadedFile?.url" />
      </div>
      <div class="name">
        <span>{{ uploadedFile?.name }}</span>
      </div>
      <div
        class="remove"
        (click)="$event.stopPropagation(); onUploadedFileRemove(i)"
      >
        <!-- <span>Usuń</span> -->
        <svg-icon src="assets/icons/bin.svg"></svg-icon>
      </div>
    </div>
  </ng-container>
</div>
