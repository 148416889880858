import {
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { TableColumn, TableState } from '../../models/table.model';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import {
  DEBOUNCE_TIME,
  DEFAULT_PAGE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZES,
} from '../../utils/common';
import { Subject, debounceTime } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UserConfigService } from '../../services/user-config.service';
import { StaticService } from '../../services/static.service';
import { PermissionService } from 'src/app/auth/services/permission.service';
import { PermissionGroup } from 'src/app/auth/permissions/permissions';

@Component({
  selector: 'expendable-table',
  templateUrl: './expendable-table.component.html',
  styleUrls: ['./expendable-table.component.scss'],
})
export class ExpendableTableComponent implements OnChanges, OnDestroy {
  @Input() data: any[] = [];
  @Input() columns: TableColumn[] = [];
  @Input() isLoading: boolean = false;
  @Input() tableState!: TableState;
  @Input() showSelect: boolean = false;
  @Input() showFavorite: boolean = false;
  @Input() showAddButton: boolean = true;
  @Input() showAddBulkButton: boolean = false;
  @Input() showSearch: boolean = true;
  @Input() userConfigRoute!: string;
  @Input() expandedRowUid: string = '';
  @Input() allowFilters: boolean = false;
  @Input() showExpand: boolean = true;
  @Input() userConfigColumns!: TableColumn[];
  @Input() userConfigPageSize!: number;
  @Input() rowRouteEnabled: boolean = false;
  @Input() permissionGroupRequiredToAdd!: PermissionGroup;

  @Output() refresh = new EventEmitter<any>();
  @Output() tableStateChanged = new EventEmitter<TableState>();
  @Output() addButtonClicked = new EventEmitter<any>();
  @Output() addBulkButtonClicked = new EventEmitter<any>();
  @Output() filterSidebarOpened = new EventEmitter<any>();
  @Output() rowRouteClicked = new EventEmitter<string>();

  @Output() userConfigChanged = new EventEmitter<any>();
  @Output() userConfigPageSizeChanged = new EventEmitter<number>();
  @Output() userConfigColumnsChanged = new EventEmitter<TableColumn[]>();

  @ContentChild('expandedContent')
  expandedContentTemplateRef!: TemplateRef<any>;
  @ContentChild('summary') summaryTemplateRef!: TemplateRef<any>;

  private searchSubject = new Subject<string>();
  private columnsSubject = new Subject<TableColumn[]>();
  private pageSizeSubject = new Subject<number>();

  dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
  pageSizes = PAGE_SIZES;

  displayedColumns: string[] = [];
  visiblePages: number[] = [];

  columnResizePressed: boolean = false;
  columnResizeDragging: boolean = false;
  columnResizeStartX!: number;
  columnResizeStartWidth!: number;
  columnsResizeRef!: ElementRef['nativeElement'];
  columnResizeListeners: Function[] = [];
  @ViewChild(MatTable, { read: ElementRef }) private tableRef!: ElementRef;

  constructor(
    private renderer: Renderer2,
    private userConfigService: UserConfigService,
    public staticService: StaticService,
    public permissionService: PermissionService
  ) {
    this.searchSubject.pipe(debounceTime(DEBOUNCE_TIME)).subscribe((search) => {
      this.tableState.search = search;
      this.tableState.page = DEFAULT_PAGE;
      this.tableStateChanged.emit(this.tableState);
    });
    this.columnsSubject
      .pipe(debounceTime(DEBOUNCE_TIME))
      .subscribe((columns) => {
        this.userConfigColumnsChanged.emit(columns);
        this.userConfigService.saveColumnsConfig(
          this.userConfigRoute,
          columns,
          this.userConfigColumns
        );
      });
    this.pageSizeSubject.subscribe((pageSize) => {
      this.userConfigPageSizeChanged.emit(pageSize);
      this.userConfigService.savePageSizeConfig(
        this.userConfigRoute,
        pageSize,
        this.userConfigPageSize
      );
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['data']) {
      this.initDataSource();
      if (this.expandedRowUid) {
        this.initExpandedRow();
      }
    }
    if (changes['columns']) {
      this.initColumns();
    }
    if (changes['tableState']) {
      this.initPages();
    }
  }

  ngOnDestroy() {
    this.searchSubject.unsubscribe();
    this.columnsSubject.unsubscribe();
    this.pageSizeSubject.unsubscribe();
  }

  initDataSource() {
    this.dataSource = new MatTableDataSource<any[]>(this.data);
  }

  // columns
  initColumns() {
    this.displayedColumns = this.columns.reduce(
      (acc: string[], column: TableColumn) => {
        if (column.displayed) {
          acc.push(column.fieldName);
        }
        return acc;
      },
      []
    );
    if (this.showExpand) {
      this.displayedColumns = ['expand', ...this.displayedColumns];
    }
    if (this.showSelect) {
      this.displayedColumns = ['select', ...this.displayedColumns];
    }
    if (this.showFavorite) {
      this.displayedColumns = ['favorite', ...this.displayedColumns];
    }
  }

  // columnsMenu
  onColumnDrop(event: CdkDragDrop<TableColumn[]>) {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
  }

  onColumnToggle(column: TableColumn) {
    column.displayed = !column.displayed;
  }

  onColumnsUpdate() {
    this.columnsSubject.next(this.columns);
    this.initColumns();
  }

  // column resize
  onColumnMouseDown(
    event: MouseEvent,
    columnRef: ElementRef['nativeElement'],
    field: string
  ) {
    this.columnResizePressed = true;
    this.columnResizeDragging = false;

    this.columnResizeStartX = event.pageX;
    this.columnsResizeRef = columnRef;
    this.columnResizeStartWidth = columnRef.offsetWidth;
    this.columnResizeListeners = [
      this.renderer.listen(
        this.tableRef.nativeElement,
        'mousemove',
        (event: MouseEvent) => this.onColumnMouseMove(event, field)
      ),
      this.renderer.listen(
        'document',
        'mouseup',
        this.onColumnMouseUp.bind(this)
      ),
    ];
    event.stopPropagation();
  }

  onColumnMouseMove(event: MouseEvent, field: string) {
    if (!(this.columnResizePressed && event.buttons)) return;

    if (this.columnResizePressed) {
      this.columnResizeDragging = true;

      event.stopPropagation();
      const minWidth = 150;
      const toLeft = event.movementX < 0;
      const tableColumns = Array.from(
        this.tableRef.nativeElement.querySelectorAll('.column-cell')
      ) as ElementRef['nativeElement'][];

      const actualIndex = tableColumns.findIndex(
        (column) => column === this.columnsResizeRef
      );

      if (toLeft) {
        const prevRightWidth = tableColumns[actualIndex].offsetWidth;
        this.renderer.setStyle(tableColumns[actualIndex], 'width', `auto`);
        const currentRightWidth = tableColumns[actualIndex].offsetWidth;
        if (prevRightWidth - currentRightWidth > 50) {
          this.renderer.setStyle(tableColumns[actualIndex], 'width', `200px`);
        }
      } else {
        tableColumns.forEach((column, index) => {
          if (column.offsetWidth < minWidth) {
            this.renderer.setStyle(column, 'width', `${minWidth}px`);
          }
        });
      }

      let width =
        this.columnResizeStartWidth + (event.pageX - this.columnResizeStartX);
      let newWidth = width > minWidth ? width : minWidth;

      // zapisanie zmiany szerokości w kolumnie
      this.columns.find((column) => column.fieldName === field)!.width =
        newWidth;
    }
  }

  onColumnMouseUp(event: MouseEvent) {
    this.columnResizePressed = false;

    if (this.columnResizeDragging) {
      this.columnsSubject.next(this.columns);
      this.columnResizeDragging = false;
    }

    this.removeColumnResizeListeners();
  }

  removeColumnResizeListeners() {
    this.columnResizeListeners.forEach((listener) => listener());
  }

  initExpandedRow() {
    if (this.expandedRowUid) {
      this.data.forEach((row) => {
        if (row.uid === this.expandedRowUid) {
          row.expanded = true;
        } else {
          row.expanded = false;
        }
      });
    }
  }

  onToggleRow(rowUid: string) {
    if (this.expandedRowUid === rowUid) {
      this.expandedRowUid = '';
      this.data.forEach((row) => (row.expanded = false));
    } else {
      this.data.forEach((row) => {
        if (row.uid === this.expandedRowUid) {
          row.expanded = false;
        }
      });

      const row = this.data.find((row) => row.uid === rowUid);
      if (row) {
        row.expanded = true;
        this.expandedRowUid = rowUid;
      }
    }
  }

  // odświeżanie
  onRefresh() {
    this.refresh.emit();
  }

  openFilterSidebar() {
    this.filterSidebarOpened.emit();
  }

  // state
  onSearchChange(search: string) {
    this.searchSubject.next(search);
  }

  onSearchClear() {
    this.tableState.search = '';
    this.tableState.page = DEFAULT_PAGE;
    this.tableStateChanged.emit(this.tableState);
  }

  onPageSizeChange(pageSize: number) {
    this.tableState.pageSize = pageSize;
    this.tableStateChanged.emit(this.tableState);
    this.pageSizeSubject.next(pageSize);
  }

  onSortChange(field: string) {
    if (this.columnResizePressed || this.columnResizeDragging) return;

    if (this.tableState.orderBy === field) {
      if (this.tableState.orderType === 'desc') {
        this.tableState.orderType = 'asc';
      } else if (this.tableState.orderType === 'asc') {
        this.tableState.orderBy = '';
        this.tableState.orderType = '';
      }
    } else {
      this.tableState.orderBy = field;
      this.tableState.orderType = 'desc';
    }

    this.tableStateChanged.emit(this.tableState);
  }

  initPages() {
    let totalPages = Math.ceil(
      this.tableState.total / this.tableState.pageSize
    );
    let currentPage = this.tableState.page;

    let startPage: number;
    let endPage: number;

    if (currentPage === 0) {
      startPage = currentPage;
      endPage = Math.min(totalPages - 1, currentPage + 2);
    } else if (currentPage === totalPages - 1) {
      startPage = Math.max(0, currentPage - 2);
      endPage = currentPage;
    } else {
      startPage = currentPage - 1;
      endPage = currentPage + 1;
    }

    this.visiblePages = [];
    for (let i = startPage; i <= endPage; i++) {
      this.visiblePages.push(i + 1);
    }
  }

  onPageChange(page: number) {
    this.tableState.page = page - 1;
    this.initPages();
    this.tableStateChanged.emit(this.tableState);
  }

  goToFirstPage() {
    let totalPages = Math.ceil(
      this.tableState.total / this.tableState.pageSize
    );
    if (this.tableState.page !== 0 && totalPages > 1) {
      this.onPageChange(1);
    }
  }

  goToLastPage() {
    let totalPages = Math.ceil(
      this.tableState.total / this.tableState.pageSize
    );
    if (this.tableState.page !== totalPages - 1 && totalPages > 1) {
      this.onPageChange(totalPages);
    }
  }

  goToPreviousPage() {
    if (this.tableState.page > 0) {
      this.onPageChange(this.tableState.page);
    }
  }

  goToNextPage() {
    let totalPages = Math.ceil(
      this.tableState.total / this.tableState.pageSize
    );
    if (this.tableState.page < totalPages - 1) {
      this.onPageChange(this.tableState.page + 2);
    }
  }

  goToPage(page: number) {
    this.onPageChange(page);
  }

  onAdd() {
    this.addButtonClicked.emit();
  }

  onAddBulk() {
    this.addBulkButtonClicked.emit();
  }

  goToDetails(rowUid: string) {
    this.rowRouteClicked.emit(rowUid);
  }
}
