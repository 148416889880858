import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { HeaderElementsColor } from '../../utils/common';
import { PermissionService } from 'src/app/auth/services/permission.service';
import { SubscriptionPermisssionService } from 'src/app/auth/services/subscription-permisssion.service';
import { Subscription } from 'rxjs';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  @Input() color: HeaderElementsColor = 'black';
  @Output() adminClicked = new EventEmitter<void>();
  public canBeNotified: boolean = false;
  isHamburgerMenuOpen: boolean = false;

  get isAuthenticated() {
    return this.authService.isAuthenticated;
  }

  constructor(
    private authService: AuthService,
    private _renderer: Renderer2,
    public permissionService: PermissionService,
    public subscriptionPermissionService: SubscriptionPermisssionService
  ) {}

  onAdminClick() {
    this.adminClicked.emit();
  }

  onHamburgerMenu() {
    if (this.isHamburgerMenuOpen) {
      this._renderer.removeStyle(document.body, 'overflow');
      this.isHamburgerMenuOpen = false;
    } else {
      this._renderer.setStyle(document.body, 'overflow', 'hidden');
      this.isHamburgerMenuOpen = true;
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
  }
}
