import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription, tap } from 'rxjs';
import { AuthService } from './auth.service';
import { SubscriptionService } from 'src/app/shared/services/subscription.service';
import { SubscriptionResponse } from 'src/app/shared/models/subscription.model';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPermisssionService {
  private userSubscriptions: string[] = [];
  private subscriptionLimits: SubscriptionResponse[] = [];

  // zaimplementowane w wykresach portfela
  private _allowedCharts = new BehaviorSubject<string[]>([]);
  allowedCharts$ = this._allowedCharts.asObservable();

  // zaimplementowane na poszczególnych raportach i w menu
  private _allowedReports = new BehaviorSubject<string[]>([]);
  allowedReports$ = this._allowedReports.asObservable();

  // zaimplementowane w dialogu dodawania sale'a i przycisku dodawania sale'a w przypadku braku jakiejkolkwiek dozwolonej transakcji
  private _allowedTransactionTypes = new BehaviorSubject<string[]>([]);
  allowedTransactionTypes$ = this._allowedTransactionTypes.asObservable();

  // na razie nie ma zaimplementowanej możliwości eksportu historii transakcji
  private _canExportTransactionHistory = new BehaviorSubject<boolean>(false);
  canExportTransactionHistory$ =
    this._canExportTransactionHistory.asObservable();

  // zaimplementowane poprzez niewyświetlanie ikony powiadomień w menu
  private _canBeNotified = new BehaviorSubject<boolean>(false);
  canBeNotified$ = this._canBeNotified.asObservable();

  // zaimplementowane na backendzie
  private _maxNumberOfCollections = new BehaviorSubject<number>(-1);
  maxNumberOfCollections$ = this._maxNumberOfCollections.asObservable();

  // zaimplementowane na backendzie
  private _maxNumberOfSubjects = new BehaviorSubject<number>(-1);
  maxNumberOfSubjects$ = this._maxNumberOfSubjects.asObservable();

  // zaimplementowane na backendzie
  private _maxNumberOfTransactionsPerMonth = new BehaviorSubject<number>(-1);
  maxNumberOfTransactionsPerMonth$ =
    this._maxNumberOfTransactionsPerMonth.asObservable();

  private subscription = new Subscription();

  constructor(
    private authService: AuthService,
    private subscriptionService: SubscriptionService
  ) {
    this.subscription = this.authService._userData
      .pipe(
        tap((userData) => {
          if (userData) {
            // pobieramy subskrypcje użytkownika
            this.userSubscriptions = userData.currentActiveSubscriptions;
            this.loadSubscriptions();
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  // ładujemy subskrypcje wraz z ich limitami
  loadSubscriptions() {
    this.subscriptionService.getSubscriptions().then((response) => {
      response.result.forEach((subscription) => {
        this.subscriptionService
          .getSubscriptionLimits(subscription.internalName)
          .then((response) => {
            this.subscriptionLimits.push(response.result);
            this.setUserLimits();
          });
      });
    });
  }

  // ustawiamy limity użytkownika
  setUserLimits() {
    const allowedCharts = this.subscriptionLimits.find((subscription) =>
      this.userSubscriptions.includes(subscription.subscriptionInternalName)
    )?.allowedCharts!;
    this._allowedCharts.next(allowedCharts);

    const allowedReports = this.subscriptionLimits.find((subscription) =>
      this.userSubscriptions.includes(subscription.subscriptionInternalName)
    )?.allowedReports!;
    this._allowedReports.next(allowedReports);

    const allowedTransactionTypes = this.subscriptionLimits.find(
      (subscription) =>
        this.userSubscriptions.includes(subscription.subscriptionInternalName)
    )?.allowedTransactionTypes!;
    this._allowedTransactionTypes.next(allowedTransactionTypes);

    const canExportTransactionHistory = this.subscriptionLimits.find(
      (subscription) =>
        this.userSubscriptions.includes(subscription.subscriptionInternalName)
    )?.exportTransactionHistory!;
    this._canExportTransactionHistory.next(canExportTransactionHistory);

    const canBeNotified = this.subscriptionLimits.find((subscription) =>
      this.userSubscriptions.includes(subscription.subscriptionInternalName)
    )?.canBeNotified!;
    this._canBeNotified.next(canBeNotified);

    const maxNumberOfCollections = this.subscriptionLimits.find(
      (subscription) =>
        this.userSubscriptions.includes(subscription.subscriptionInternalName)
    )?.maxNumberOfCollections!;
    this._maxNumberOfCollections.next(maxNumberOfCollections);

    const maxNumberOfSubjects = this.subscriptionLimits.find((subscription) =>
      this.userSubscriptions.includes(subscription.subscriptionInternalName)
    )?.maxNumberOfSubjects!;
    this._maxNumberOfSubjects.next(maxNumberOfSubjects);

    const maxNumberOfTransactionsPerMonth = this.subscriptionLimits.find(
      (subscription) =>
        this.userSubscriptions.includes(subscription.subscriptionInternalName)
    )?.maxNumberOfTransactionsPerMonth!;
    this._maxNumberOfTransactionsPerMonth.next(maxNumberOfTransactionsPerMonth);
  }
}
