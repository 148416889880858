<div class="tile">
    <div class="tile-header">{{ 'common.entities.subject.plural' | translate }}</div>
    <div class="content">
        <div class="list">
            <div class="item" *ngFor="let subject of data.subjects">
                <div class="image">
                    <img [src]="getStaticUrl(subject.mainPhoto?.url!)">
                </div>
                <div class="data">
                    <div class="title">
                        <span>{{ subject.authors[0].name }}</span>
                    </div>
                    <div class="subtitle">
                        <span>{{ subject.name }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>