<div class="content">
  <ng-container *ngIf="isLoading">
    <atom-spinner></atom-spinner>
  </ng-container>

  <div [style.display]="isLoading ? 'none' : 'block'">
    <div class="header">
      <div
        *ngIf="showAddButton && permissionService.hasPermission(permissionGroupRequiredToAdd)"
        class="button"
        (click)="onAdd()"
      >
        <button>
          <svg-icon
            src="../../../../assets/icons/plus.svg"
            [svgStyle]="{ fill: 'white', 'height.px': 18 }"
          ></svg-icon>
          <span>{{ 'common.buttons.add' | translate }}</span>
        </button>
      </div>
      <div
        *ngIf="showAddBulkButton && permissionService.hasPermission(permissionGroupRequiredToAdd)"
        class="button"
        (click)="onAddBulk()"
      >
        <button>
          <svg-icon
            src="../../../../assets/icons/plus.svg"
            [svgStyle]="{ fill: 'white', 'height.px': 18 }"
          ></svg-icon>
          <span>{{ 'common.buttons.addBulk' | translate }}</span>
        </button>
      </div>
      <div *ngIf="showSearch" class="search">
        <input
          [placeholder]="'common.table.search' | translate"
          [(ngModel)]="tableState.search"
          (ngModelChange)="onSearchChange($event)"
        />
        <svg-icon
          *ngIf="tableState.search"
          class="search-clear"
          (click)="onSearchClear()"
          src="/assets/icons/x.svg"
          [svgStyle]="{ fill: '#000', 'height.px': 18, 'width.px': 18 }"
        ></svg-icon>
      </div>
      <div class="summary"><ng-template *ngTemplateOutlet="summaryTemplateRef"></ng-template></div>
      <div class="table-sections">
        <div class="total table-section">
          <div>{{ 'common.table.total' | translate | uppercase }}:</div>
          <div class="table-label circle">
            <span> {{ tableState.total }} </span>
          </div>
        </div>
        <div class="page-size table-section">
          <div class="div">{{ 'common.table.show' | translate | uppercase }}:</div>
          <div
            class="table-label circle"
            *ngFor="let size of pageSizes"
            [class.active]="size === tableState.pageSize"
            (click)="onPageSizeChange(size)"
          >
            <span> {{ size }}</span>
          </div>
        </div>
        <div class="pagination table-section group">
          <div class="table-label" (click)="goToFirstPage()">
            <svg-icon
              src="/assets/icons/chevrons.svg"
              [svgStyle]="{ fill: '#000', 'height.px': 6, 'width.px': 6 }"
            ></svg-icon>
          </div>
          <div class="table-label" (click)="goToPreviousPage()">
            <svg-icon
              src="/assets/icons/chevron.svg"
              [svgStyle]="{ fill: '#000', 'height.px': 8, 'width.px': 8 }"
            ></svg-icon>
          </div>
          <div
            class="table-label"
            *ngFor="let page of visiblePages"
            [class.active]="page === tableState.page + 1"
            (click)="goToPage(page)"
          >
            <span> {{ page }} </span>
          </div>
          <div class="table-label" (click)="goToNextPage()">
            <svg-icon
              src="/assets/icons/chevron.svg"
              [svgStyle]="{
                fill: '#000',
                'height.px': 8,
                'width.px': 8,
                transform: 'rotate(180deg)',
              }"
            ></svg-icon>
          </div>
          <div class="table-label" (click)="goToLastPage()">
            <svg-icon
              src="/assets/icons/chevrons.svg"
              [svgStyle]="{
                fill: '#000',
                'height.px': 6,
                'width.px': 6,
                transform: 'rotate(180deg)',
              }"
            ></svg-icon>
          </div>
        </div>

        <div class="refresh table-section">
          <div class="table-label" (click)="onRefresh()" [ngClass]="{ loading: isLoading }">
            <svg-icon
              src="/assets/icons/refresh.svg"
              [svgStyle]="{ fill: '#000', 'height.px': 18, 'width.px': 18 }"
            ></svg-icon>
          </div>
        </div>

        <div *ngIf="allowFilters" class="filter table-section">
          <div class="table-label" (click)="openFilterSidebar()">
            <svg-icon
              src="/assets/icons/filter.svg"
              [svgStyle]="{ fill: '#000', 'height.px': 18, 'width.px': 18 }"
            ></svg-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="table-container">
      <table #tableRef class="table" mat-table multiTemplateDataRows [dataSource]="dataSource">
        <ng-container *ngIf="showSelect" matColumnDef="select">
          <th class="column-cell select" mat-header-cell *matHeaderCellDef>
            <mat-checkbox></mat-checkbox>
          </th>
          <td
            class="row-cell select"
            mat-cell
            *matCellDef="let row"
            (click)="rowRouteEnabled ? goToDetails(row.uid) : null"
            [class.clickable]="rowRouteEnabled"
          >
            <mat-checkbox></mat-checkbox>
          </td>
        </ng-container>
        <ng-container *ngIf="showExpand" matColumnDef="expand">
          <th
            class="column-cell expand"
            mat-header-cell
            *matHeaderCellDef
            [matMenuTriggerFor]="columnsMenu"
            #columnsMenuTrigger="matMenuTrigger"
          >
            <div class="column-order">
              <svg-icon
                src="/assets/icons/column_order.svg"
                [svgStyle]="{ fill: '#000', 'height.px': 14, 'width.px': 14 }"
              ></svg-icon>
            </div>
          </th>
          <td class="row-cell expand" mat-cell *matCellDef="let row">
            <div class="expand">
              <svg-icon
                (click)="onToggleRow(row.uid)"
                src="/assets/icons/chevron.svg"
                [svgStyle]="{
                  fill: '#000',
                  'height.px': 13,
                  'width.px': 13,
                  transform: expandedRowUid === row.uid ? 'rotate(270deg)' : 'rotate(180deg)',
                }"
              ></svg-icon>
            </div>
          </td>
        </ng-container>
        <ng-container *ngIf="showFavorite" matColumnDef="favorite">
          <th
            class="column-cell expand"
            mat-header-cell
            *matHeaderCellDef
            [matMenuTriggerFor]="columnsMenu"
            #columnsMenuTrigger="matMenuTrigger"
          >
            <div class="column-order">
              <svg-icon
                src="/assets/icons/column_order.svg"
                [svgStyle]="{ fill: '#000', 'height.px': 14, 'width.px': 14 }"
              ></svg-icon>
            </div>
          </th>
          <td
            class="row-cell favorite"
            mat-cell
            *matCellDef="let row"
            (click)="rowRouteEnabled ? goToDetails(row.uid) : null"
            [class.clickable]="rowRouteEnabled"
          >
            <div class="toggle-favorite">
              <svg-icon
                src="/assets/icons/heart_full_blue.svg"
                [svgStyle]="{ 'height.px': 15 }"
              ></svg-icon>
            </div>
          </td>
        </ng-container>
        <ng-container
          [matColumnDef]="column.fieldName"
          *ngFor="let column of columns; let i = index"
        >
          <ng-container *ngIf="column.type === 'image'">
            <th class="column-cell image" mat-header-cell *matHeaderCellDef #columnRef>
              <div class="column-header">
                <div class="column-name">
                  <span>{{ column.title! | translate }}</span>
                </div>
              </div>
            </th>
          </ng-container>
          <ng-container *ngIf="column.type !== 'image'">
            <th
              class="column-cell"
              mat-header-cell
              *matHeaderCellDef
              #columnRef
              (mousedown)="onColumnMouseDown($event, columnRef, column.fieldName)"
              [style.width.px]="column.width"
              (click)="onSortChange(column.fieldName)"
            >
              <span class="resize-holder"></span>
              <div class="column-header">
                <div class="column-name">
                  <span>{{ column.title! | translate }}</span>
                </div>
                <div class="column-sort">
                  <svg-icon
                    *ngIf="tableState.orderBy !== column.fieldName"
                    src="/assets/icons/sort1.svg"
                    [svgStyle]="{ fill: '#000', 'height.px': 13, 'width.px': 13 }"
                  ></svg-icon>
                  <ng-container *ngIf="tableState.orderBy === column.fieldName">
                    <svg-icon
                      *ngIf="tableState.orderType === 'desc'"
                      src="/assets/icons/sort2.svg"
                      [svgStyle]="{ fill: '#000', 'height.px': 13, 'width.px': 13 }"
                    ></svg-icon>
                    <svg-icon
                      *ngIf="tableState.orderType === 'asc'"
                      src="/assets/icons/sort3.svg"
                      [svgStyle]="{ fill: '#000', 'height.px': 13, 'width.px': 13 }"
                    ></svg-icon>
                  </ng-container>
                </div>
              </div>
            </th>
          </ng-container>
          <td
            class="row-cell"
            [class.image]="column.type === 'image'"
            mat-cell
            *matCellDef="let row"
            (click)="rowRouteEnabled ? goToDetails(row.uid) : null"
            [class.clickable]="rowRouteEnabled"
            [style.width.px]="column.width"
            (click)="onToggleRow(row.uid)"
          >
            <ng-container [ngSwitch]="column.type">
              <ng-container *ngSwitchCase="'image'">
                <div class="image">
                  <img
                    *ngIf="row[column.fieldName]"
                    [src]="
                      staticService.getStaticUrl(
                        row[column.fieldName].miniatureUrl ?? row[column.fieldName].url
                      )
                    "
                  />
                  <svg-icon
                    *ngIf="!row[column.fieldName]"
                    src="/assets/icons/placeholder.svg"
                    [svgStyle]="{ fill: '#5e6369', 'height.px': 20, 'width.px': 20 }"
                  ></svg-icon>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'text'">
                <span [style.width.px]="column.width">{{ row[column.fieldName] }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'user'">
                <span [style.width.px]="column.width" *ngIf="row[column.fieldName]"
                  >{{ row[column.fieldName].firstName }} {{ row[column.fieldName].lastName }}</span
                >
              </ng-container>
              <ng-container *ngSwitchCase="'date'">
                <span [style.width.px]="column.width">{{
                  row[column.fieldName] | date: 'dd.MM.yyyy'
                }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'percentageChange'">
                <span
                  [style.width.px]="column.width"
                  class="percentage-change"
                  [ngClass]="{
                    positive: row[column.fieldName] > 0,
                    negative: row[column.fieldName] < 0,
                  }"
                  >{{ row[column.fieldName] | percentageChange }}</span
                >
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="expandedContent">
          <td
            class="expanded-cell"
            mat-cell
            *matCellDef="let row"
            [attr.colspan]="displayedColumns.length"
            [class.expanded]="expandedRowUid === row.uid"
          >
            <div
              *ngIf="row.expanded"
              class="expanded-cell-content"
              [style.width]="'100%'"
              [class.expanded]="expandedRowUid === row.uid"
            >
              <ng-container *ngIf="expandedRowUid === row.uid">
                <ng-template
                  *ngTemplateOutlet="expandedContentTemplateRef; context: { row: row }"
                ></ng-template>
              </ng-container>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns" class="header-row"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" class="row"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: ['expandedContent']"
          [class.expanded]="expandedRowUid === row.uid"
          class="expanded-row"
        ></tr>
      </table>
    </div>
  </div>

  <!-- MENU FOR SHOW/HIDE COLUMNS -->
  <mat-menu #columnsMenu="matMenu" class="dropdown" (close)="onColumnsUpdate()">
    <div cdkDropList (cdkDropListDropped)="onColumnDrop($event)" class="hide-columns-list">
      <div
        *ngFor="let column of columns; let i = index"
        class="dropdown-item column"
        (click)="$event.stopPropagation()"
        cdkDrag
      >
        <div class="drag-btn">
          <img src="/assets/icons/drag-drop.svg" height="18" />
        </div>
        <mat-checkbox
          (click)="$event.stopPropagation(); onColumnToggle(column)"
          [checked]="column.displayed"
        >
          {{ column.title! | translate }}
        </mat-checkbox>
      </div>
    </div>
  </mat-menu>
</div>
