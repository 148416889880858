import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ApiService } from 'src/app/core/api/api.service';
import {
  SaleAuctionBidRequest,
  SaleForMultipleSubjectsCreateRequest,
  SaleForSingleSubjectCreateRequest,
  SaleForWholeCollectionCreateRequest,
  SaleLightResponse,
  SaleOfferResponse,
  SaleRentRequest,
  SaleResponse,
  SaleUpdateRequest,
} from '../models/sale.model';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { ApiPagingResponseModel } from 'src/app/core/api/api-paging-response.model';
import { UserResponse, UserWithSubcategoriesResponse } from 'src/app/shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class SaleApiService extends ApiService {
  apiPrefix = '/api/v1/sale';

  createSaleForWholeCollection(
    saleData: SaleForWholeCollectionCreateRequest
  ): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/whole-collection`,
      saleData
    );
    return lastValueFrom(request);
  }

  createSaleForMultipleSubjects(
    saleData: SaleForMultipleSubjectsCreateRequest
  ): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/selected-subjects`,
      saleData
    );
    return lastValueFrom(request);
  }

  createSaleForSingleSubject(
    saleData: SaleForSingleSubjectCreateRequest
  ): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/single-subject`,
      saleData
    );
    return lastValueFrom(request);
  }

  getSales(query: string | null): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    const request = this.get<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>>(
      `${this.apiPrefix}` + query,
      null
    );
    return lastValueFrom(request);
  }

  getMySales(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    const request = this.get<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>>(
      `${this.apiPrefix}/mine` + query,
      null
    );
    return lastValueFrom(request);
  }

  getMyFavoriteSales(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    const request = this.get<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>>(
      `${this.apiPrefix}/mine/favourites` + query,
      null
    );
    return lastValueFrom(request);
  }

  getRecommendedSales(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    const request = this.get<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>>(
      `${this.apiPrefix}/recommended` + query,
      null
    );
    return lastValueFrom(request);
  }

  getSalesByType(
    saleTypeUid: string,
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>> {
    const request = this.get<ApiResponseModel<ApiPagingResponseModel<SaleResponse>>>(
      `${this.apiPrefix}/by-sale-type/${saleTypeUid}` + query,
      null
    );
    return lastValueFrom(request);
  }

  getSale(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.get<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/uid/${saleUid}`,
      null
    );
    return lastValueFrom(request);
  }

  updateSale(saleUid: string, data: SaleUpdateRequest): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.put<ApiResponseModel<SaleResponse>>(`${this.apiPrefix}/${saleUid}`, data);
    return lastValueFrom(request);
  }

  deleteSale(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.delete<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}`,
      null
    );
    return lastValueFrom(request);
  }

  getPopularSellers(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<UserWithSubcategoriesResponse>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<UserWithSubcategoriesResponse>>
    >(`${this.apiPrefix}/popular-sellers` + query, null);
    return lastValueFrom(request);
  }

  toggleFavorite(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/toggle-favourite`,
      null
    );
    return lastValueFrom(request);
  }

  sendRentRequest(saleUid: string, data: SaleRentRequest): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/rent`,
      data
    );
    return lastValueFrom(request);
  }

  buyNow(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/buy`,
      null
    );
    return lastValueFrom(request);
  }
  setReserved(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/set-reserved`,
      null
    );
    return lastValueFrom(request);
  }
  cancelReservation(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/cancel-reservation`,
      null
    );
    return lastValueFrom(request);
  }
  markAsSold(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/accept-offer`,
      null
    );
    return lastValueFrom(request);
  }

  sendAuctionBid(
    saleUid: string,
    data: SaleAuctionBidRequest
  ): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/offer`,
      data
    );
    return lastValueFrom(request);
  }

  getSaleLight(saleUid: string): Promise<ApiResponseModel<SaleLightResponse>> {
    const request = this.get<ApiResponseModel<SaleLightResponse>>(
      `${this.apiPrefix}/uid/${saleUid}/light`,
      null
    );
    return lastValueFrom(request);
  }

  auctionFirstCall(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/first-call`,
      null
    );
    return lastValueFrom(request);
  }

  auctionSecondCall(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/second-call`,
      null
    );
    return lastValueFrom(request);
  }

  auctionThirdCall(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/third-call`,
      null
    );
    return lastValueFrom(request);
  }

  auctionSoldCall(saleUid: string): Promise<ApiResponseModel<SaleResponse>> {
    const request = this.post<ApiResponseModel<SaleResponse>>(
      `${this.apiPrefix}/${saleUid}/sold-call`,
      null
    );
    return lastValueFrom(request);
  }
}
