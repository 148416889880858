<atom-spinner *ngIf="isLoading else content"></atom-spinner>
<ng-template #content>
    <div *ngIf="recommendedSales.length" class="content" [ngClass]="mode">
        <div class="header">
            <div class="title">{{ 'recommendedSale.title' | translate }}</div>
            <div class="subtitle">{{ 'recommendedSale.subtitle' | translate }}</div>
        </div>
        <div class="sales">
            <div class="sale" *ngFor="let sale of recommendedSales; let i = index">
                <div class="image">
                    <app-sale-card-gallery [images]="recommendedSalesImages[i]"
                        [borderRadiusTop]="true"></app-sale-card-gallery>
                </div>
                <div class="text">
                    <div class="title">{{ sale.authorsNames }}</div>
                    <div class="subtitle">{{ sale.saleSubjectNames }}
                    </div>
                    <div class="current-bid">
                        {{ sale.currentBidPrice}} {{ sale.baseCurrency.publicName}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>