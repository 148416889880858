import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { StaticService } from 'src/app/shared/services/static.service';
import { HeaderElementsColor } from 'src/app/shared/utils/common';

@Component({
  selector: 'app-header-profile',
  templateUrl: './header-profile.component.html',
  styleUrls: ['./header-profile.component.scss'],
})
export class HeaderProfileComponent {
  @Input() color: HeaderElementsColor = 'black';

  get userInfo() {
    return this.authService.userData;
  }

  constructor(
    private authService: AuthService,
    private router: Router,
    public staticService: StaticService
  ) {}

  onLogout() {
    this.authService.logout();
  }
}
