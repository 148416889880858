<div class="content" [matMenuTriggerFor]="messagesDropdown" (click)="getUnreadMessages()">
    <div class="icon">
        <svg-icon src="assets/icons/bell.svg" [svgStyle]="{ 'fill': color, 'height.px' : 20 }"></svg-icon>
        <span class="badge" *ngIf="messagesCount > 0">{{ messagesCount }}</span>
    </div>
</div>

<mat-menu #messagesDropdown="matMenu">
    <atom-spinner *ngIf="isLoading else messagesMenu"></atom-spinner>
    <ng-template #messagesMenu>
        <ul class="messages">
            <li *ngFor="let message of messages" class="message" (click)="redirectToObjectPage(message)">
                <div class="icon-wrapper">
                    <div class="icon">
                        <svg-icon *ngIf="message.messageObjectEnum === messageObjectEnum.Sale"
                            src="assets/icons/offers.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                        <svg-icon *ngIf="message.messageObjectEnum === messageObjectEnum.Subject"
                            src="assets/icons/gallery_board.svg"
                            [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                        <svg-icon *ngIf="message.messageObjectEnum === messageObjectEnum.Report"
                            src="assets/icons/report.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    </div>
                </div>
                <div class="data">
                    <div class="title">{{ message.messageTranslatedBody }}</div>
                    <div class="subtitle">{{ message.foreignName }}</div>
                </div>
            </li>
        </ul>
    </ng-template>
</mat-menu>