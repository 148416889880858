import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { handleValidationErrorMessage } from 'src/app/shared/utils/validators/validators';

@Component({
  selector: 'atom-date-time-picker',
  templateUrl: './atom-date-time-picker.component.html',
  styleUrls: ['./atom-date-time-picker.component.scss'],
})
export class AtomDateTimePickerComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() control!: AbstractControl;
  @ViewChild('input') inputElement!: ElementRef;

  get formControl() {
    return this.control as FormControl;
  }

  get isRequired() {
    return this.formControl.hasValidator(Validators.required);
  }

  ngOnInit(): void {}

  onPickerClose() {
    this.formControl.value.setSeconds(0);
    this.inputElement.nativeElement.value =
      this.formControl.value.toLocaleString();
  }

  public errorHandler = handleValidationErrorMessage;
}
