import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription, skip, pairwise, startWith } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { Filter } from '../../models/filter.model';
import { DictionaryService } from 'src/app/shared/services/dictionary.service';

@Component({
  selector: 'app-filters-sidebar',
  templateUrl: './filter-sidebar.component.html',
  styleUrls: ['./filter-sidebar.component.scss'],
})
export class FilterSidebarComponent implements OnInit, OnDestroy {
  @Output() sidebarOpened = new EventEmitter<void>();
  @Output() sidebarClosed = new EventEmitter<void>();

  private _subscriptions = new Subscription();

  filters: Filter[] = [];
  form!: FormGroup;
  isLoading: boolean = false;
  subjectStyles$ = this.dictionaryService.subjectStyles$;

  constructor(
    private filterService: FilterService,
    private dictionaryService: DictionaryService
  ) {}

  ngOnInit(): void {
    this._subscriptions.add(
      this.filterService.sidebarOpen$.pipe(skip(1)).subscribe((filters: Filter[]) => {
        this.openSidebar(filters);
      })
    );
    this._subscriptions.add(
      this.filterService.sidebarClose$.subscribe(() => {
        this.closeSidebar();
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  openSidebar(filters: Filter[]) {
    this.filters = filters;
    this.initFiltersForm();
    this.sidebarOpened.emit();
  }

  initFiltersForm() {
    let group: any = {};

    this.filters.forEach((filter: Filter) => {
      group[filter.internalName] = new FormControl(filter.value);
      this.checkFilterDependencies(filter);
    });

    this.form = new FormGroup(group);

    this._subscriptions.add(
      this.form.valueChanges
        .pipe(startWith(this.form.value), pairwise())
        .subscribe(([oldValue, value]: [any, any]) => {
          this.filters.forEach((filter: Filter, index: number) => {
            if (oldValue[filter.internalName] !== value[filter.internalName]) {
              const control = this.form.get(filter.internalName);
              if (control) {
                filter.value = control.value;
              }
              this.checkFilterDependencies(filter, true);
            }
          });
        })
    );
  }

  async checkFilterDependencies(currFilter: Filter, updatedVal?: boolean) {
    const dependentFiltersIndexes: number[] = [];
    this.filters.forEach((el, index) => {
      if (
        el.dependsOn === currFilter.internalName ||
        el.showOn?.filterInternalName === currFilter.internalName
      ) {
        dependentFiltersIndexes.push(index);
      }
    });
    for (const depFilterIndex of dependentFiltersIndexes) {
      if (updatedVal) {
        const control = this.form.get(this.filters[depFilterIndex].internalName);
        if (control) {
          control.setValue('');
        }
        this.filters[depFilterIndex].value = '';
      }
      if (this.filters[depFilterIndex].showOn?.filterValues) {
        this.filters[depFilterIndex].hidden = !this.filters[
          depFilterIndex
        ].showOn?.filterValues.includes(currFilter.value);
      }
      this.filters[depFilterIndex].disabled = !currFilter.value;
      if (
        !!currFilter.value &&
        typeof this.filters[depFilterIndex].dependencyOptionsFn !== 'undefined'
      ) {
        this.filters[depFilterIndex].options = await this.filters[depFilterIndex]
          .dependencyOptionsFn!(currFilter.value);
      } else if (this.filters[depFilterIndex].options) {
        this.filters[depFilterIndex].options = [];
      }
    }
  }

  closeSidebar() {
    this.sidebarClosed.emit();
  }

  applyFilters() {
    this.filterService.applyFilters(this.filters);
    this.sidebarClosed.emit();
  }
}
