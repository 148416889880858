import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'percentageChange'
})
export class PercentageChangePipe implements PipeTransform {

  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any, digitsInfo: string = '1.2-2', locale: string = 'pl'): string | null {
    if (value > 0) {
      return '+' + this.decimalPipe.transform(value, digitsInfo, locale) + ' %';
    } else {
      return this.decimalPipe.transform(value, digitsInfo, locale) + ' %';
    }
  }

}
