<div class="content" [style.height]="heightPx + 'px'">
    <div class="header">
        <div class="title">
            <span>{{ chartTitle | translate }}</span>
        </div>
        <div class="date-range">
            <span> {{ dateFrom }} </span> - <span> {{ dateTo }} </span>
        </div>
    </div>
    <ngx-charts-line-chart [results]="results" [scheme]="colorScheme" [showGridLines]="false"
        [autoScale]="true"></ngx-charts-line-chart>
</div>