import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonTheme, ButtonType } from './atom-button.model';

@Component({
  selector: 'atom-button',
  templateUrl: './atom-button.component.html',
  styleUrls: ['./atom-button.component.scss'],
})
export class AtomButtonComponent {
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  @Input() type: ButtonType = 'button';
  @Input() theme: ButtonTheme = 'default';
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() isLoading: boolean = false;
  @Input() icon: string = '';

  onClick() {
    this.clicked.emit();
  }
}
