import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { PermissionService } from 'src/app/auth/services/permission.service';
import { SubscriptionPermisssionService } from 'src/app/auth/services/subscription-permisssion.service';
import { DropdownItem } from 'src/app/shared/models/dropdown.model';
import { HeaderElementsColor } from 'src/app/shared/utils/common';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent {
  @Input() color: HeaderElementsColor = 'black';
  @Input() isAuthenticated!: boolean;
  @Input() isMobile!: boolean;

  subscription = new Subscription();
  allowedReports: string[] = [];

  constructor(
    public permissionService: PermissionService,
    private subscriptionPermissionService: SubscriptionPermisssionService
  ) {}

  ngOnInit(): void {
    this.subscription.add(
      this.subscriptionPermissionService.allowedReports$.subscribe(
        (allowedReports) => {
          this.allowedReports = allowedReports;
        }
      )
    );
  }

  canAccessReports(): boolean {
    const reportStrings = [
      'WALLET_GENERAL',
      'WALLET_DETAIL',
      'TRANSACTIONS',
      'WALLET_MIX',
      'AVERAGE_TRANSACTIONS_IN_CATEGORY',
      'TOP_10_COLLECTIONS',
      'TOP_10_AUTHORS',
    ];
    if (reportStrings.some((report) => this.allowedReports?.includes(report))) {
      return true;
    } else {
      return false;
    }
  }

  salesMenuItems: DropdownItem[] = [
    {
      name: 'header.menu.sales.subMenu.board',
      route: '/sales',
      icon: 'notice_board',
    },
    {
      name: 'header.menu.sales.subMenu.purchaseHistory',
      route: '/sales/history',
      icon: 'purchase_history',
    },
    {
      name: 'header.menu.sales.subMenu.favorites',
      route: '/sales/favorites',
      icon: 'favourite',
    },
    {
      name: 'header.menu.sales.subMenu.myOffers',
      route: '/sales/mine',
      icon: 'offers',
    },
  ];

  galleriesMenuItems: DropdownItem[] = [
    {
      name: 'header.menu.galleries.subMenu.board',
      route: '/gallery',
      icon: 'gallery_board',
    },
    {
      name: 'header.menu.galleries.subMenu.myGallery',
      route: '/gallery/mine',
      icon: 'my_gallery',
    },
    {
      name: 'header.menu.galleries.subMenu.favorites',
      route: '/gallery/favorites',
      icon: 'favourite',
    },
  ];

  collectionsMenuItems: DropdownItem[] = [];

  walletMenuItems: DropdownItem[] = [];
}
