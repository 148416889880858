import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { handleValidationErrorMessage } from 'src/app/shared/utils/validators/validators';
import { Observable } from 'rxjs';
import { DictionaryWord } from '../../../models/dictionary.model';

@Component({
  selector: 'atom-select',
  templateUrl: './atom-select.component.html',
  styleUrls: ['./atom-select.component.scss'],
})
export class AtomSelectComponent {
  @Input() label: string = '';
  @Input() placeholder: string = '';
  @Input() control!: AbstractControl;
  @Input() options: any[] | null = [];
  @Input() optionsValueProp?: 'internalName';
  @Input() apiOptions?: Observable<DictionaryWord[]>;
  @Input() multiple: boolean = false;
  @Input() nullable?: boolean = false;
  @Input() disabled?: any;
  isDisabled: boolean = !this.disabled;

  get formControl() {
    return this.control as FormControl;
  }

  get isRequired() {
    return this.formControl.hasValidator(Validators.required);
  }

  public errorHandler = handleValidationErrorMessage;
}
