import { Injectable } from '@angular/core';
import { GalleryApiService } from './gallery-api.service';
import { SubjectApiService } from '../../collections/services/subject.api.service';
import { CollectionApiService } from '../../collections/services/collection.api.service';

@Injectable({
  providedIn: 'root',
})
export class GalleryService {
  constructor(
    private galleryApiService: GalleryApiService,
    private subjectApiService: SubjectApiService,
    private collectionApiService: CollectionApiService
  ) {}

  getGalleries(query: string | null) {
    return this.galleryApiService.getGalleries(query);
  }
  getRecommendedGalleries(query: string | null) {
    return this.galleryApiService.getRecommendedGalleries(query);
  }

  toggleFavorite(uid: string) {
    return this.galleryApiService.toggleFavorite(uid);
  }

  getPopularUsers(query: string | null) {
    return this.galleryApiService.getPopularUsers(query);
  }

  getFavoriteGalleries(query: string | null) {
    return this.galleryApiService.getFavoriteGalleries(query);
  }
  getSubject(uid: string) {
    return this.subjectApiService.getSubject(uid);
  }

  getGalleryCollection(collectionUid: string) {
    return this.collectionApiService.getCollection(collectionUid);
  }

  getSubjects(query: string | null) {
    return this.subjectApiService.getSubjects(query);
  }
}
