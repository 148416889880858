import { Injectable } from '@angular/core';
import {
  ScraperArticleRequest,
  ScraperResponse,
} from 'src/app/shared/models/scraper.model';
import { ScraperApiService } from 'src/app/shared/services/scraper.api.service';

@Injectable({
  providedIn: 'root',
})
export class ScrapersService {
  constructor(private scraperApiService: ScraperApiService) {}

  public getArticles(
    request: ScraperArticleRequest,
    queryParams: any
  ): Promise<ScraperResponse> {
    return this.scraperApiService.post('/article', request, queryParams);
  }
}
