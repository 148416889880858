import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { handleValidationErrorMessage } from 'src/app/shared/utils/validators/validators';

@Component({
  selector: 'app-atom-date-range-picker',
  templateUrl: './atom-date-range-picker.component.html',
  styleUrls: ['./atom-date-range-picker.component.scss'],
})
export class AtomDateRangePickerComponent {
  @Input() label: string = '';
  @Input() placeholder!: string;
  @Input() controls!: AbstractControl[];

  get formControlStart() {
    return this.controls[0] as FormControl;
  }

  get formControlEnd() {
    return this.controls[1] as FormControl;
  }

  get isRequired() {
    return (
      this.formControlStart.hasValidator(Validators.required) ||
      this.formControlEnd.hasValidator(Validators.required)
    );
  }

  public errorHandler = handleValidationErrorMessage;
}
