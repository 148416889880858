<div class="tile">
    <div class="tile-header">{{ 'profile.user.title' |
        translate }}</div>
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="0ms" class="list-tabs">
        <mat-tab [label]="'profile.user.tabs.data' | translate">
            <ng-container>
                <div class="content">
                    <div class="fields">
                        <div class="field nickname">
                            <small class="label">{{'common.formFields.text.nickname.label' |
                                translate}}</small>
                            <div class="value">{{data.user.nickname!}}</div>
                        </div>
                        <div class="field email">
                            <small class="label">{{'common.formFields.text.email.label' |
                                translate}}</small>
                            <div class="value">{{data.user.email!}}</div>
                        </div>
                        <div class="field phone">
                            <small class="label">{{'common.formFields.text.phone.label' |
                                translate}}</small>
                            <div class="value">{{data.user.phoneNumber!}}</div>
                        </div>
                        <div class="field streetNumber">
                            <small class="label">{{'common.formFields.text.streetNumber.label' |
                                translate}}</small>
                            <div class="value">{{data.user.addressDto?.streetNo}}</div>
                        </div>
                        <div class="field localNumber">
                            <small class="label">{{'common.formFields.text.localNumber.label' |
                                translate}}</small>
                            <div class="value">{{data.user.addressDto?.localNo}}</div>
                        </div>
                        <div class="field postalCode">
                            <small class="label">{{'common.formFields.text.postalCode.label' |
                                translate}}</small>
                            <div class="value">{{data.user.addressDto?.postalCode}}</div>
                        </div>
                        <div class="field status">
                            <small class="label">{{'common.formFields.select.status.label' |
                                translate}}</small>
                            <div class="value">{{data.user.status.publicName}}</div>
                        </div>
                        <div class="field firstName">
                            <small class="label">{{'common.formFields.text.firstName.label' |
                                translate}}</small>
                            <div class="value">{{data.user.firstName!}}</div>
                        </div>
                        <div class="field lastName">
                            <small class="label">{{'common.formFields.text.lastName.label' |
                                translate}}</small>
                            <div class="value">{{data.user.lastName}}</div>
                        </div>
                        <div class="field street">
                            <small class="label">{{'common.formFields.text.street.label' |
                                translate}}</small>
                            <div class="value">{{data.user.addressDto?.street}}</div>
                        </div>
                        <div class="field city">
                            <small class="label">{{'common.formFields.text.city.label' |
                                translate}}</small>
                            <div class="value">{{data.user.addressDto?.city}}</div>
                        </div>
                        <div class="field country">
                            <small class="label">{{'common.formFields.text.country.label' |
                                translate}}</small>
                            <div class="value">{{data.user.addressDto?.country}}</div>
                        </div>
                        <div class="field note">
                            <small class="label">{{'common.formFields.text.note.label' |
                                translate}}</small>
                            <div class="value">{{data.user.notes}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </mat-tab>
        <mat-tab [label]="'profile.user.tabs.settings' | translate">
            <ng-container>
                <div class="content">
                    <div class="fields">
                        <div class="field currency">
                            <small class="label">{{'common.formFields.select.currency.label' |
                                translate}}</small>
                            <div class="value">{{data.user.currency.publicName}}</div>
                        </div>
                        <div class="field language">
                            <small class="label">{{'common.formFields.select.language.label' |
                                translate}}</small>
                            <div class="value">{{data.user.defaultLanguage.publicName}}</div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </mat-tab>
    </mat-tab-group>
</div>