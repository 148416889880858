<atom-spinner *ngIf="isLoading else content"></atom-spinner>
<ng-template #content>
    <div *ngIf="articles.length" class="content" [ngClass]="mode">
        <div class="header">
            <div class="title">{{ 'article.title' | translate }}</div>
            <div class="subtitle">{{ 'article.subtitle' | translate }}</div>
        </div>
        <div class="articles">
            <div *ngFor="let article of articles" class="article" (click)="goToArticle(article)">
                <div class="text">
                    <div class="title">{{ article.title }}</div>
                    <div class="subtitle">{{ article.contentShort }}
                    </div>
                </div>
                <div class="image">
                    <img [src]="article.imageUrl">
                </div>
            </div>
        </div>
    </div>
</ng-template>