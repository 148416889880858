import { Component, Input } from '@angular/core';
import { TileDescriptionData } from 'src/app/shared/models/tile.model';

@Component({
  selector: 'tile-description',
  templateUrl: './tile-description.component.html',
  styleUrls: ['./tile-description.component.scss'],
})
export class TileDescriptionComponent {
  @Input() data!: TileDescriptionData;
}
