import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SaleResponse } from 'src/app/cms/features/sales/models/sale.model';
import { GalleryImage } from 'src/app/shared/models/gallery-image.model';
import { StaticService } from 'src/app/shared/services/static.service';

@Component({
  selector: 'app-sale-card-horizontal',
  templateUrl: './sale-card-horizontal.component.html',
  styleUrls: ['./sale-card-horizontal.component.scss'],
})
export class SaleCardHorizontalComponent {
  @Input() saleData!: SaleResponse;
  @Input() saleType!: string;
  galleryImages: GalleryImage[] = [];

  constructor(
    private staticService: StaticService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.galleryImages = this.saleData.subjects.map((subject) => {
      if (subject.mainPhoto) {
        return {
          url: this.staticService.getStaticUrl(subject.mainPhoto.miniatureUrl),
          filename: subject.mainPhoto.filename,
        };
      } else {
        return {
          url: '/assets/icons/placeholder.svg',
          filename: subject.name,
        };
      }
    });
  }

  goToSaleDetails(): void {
    this.router.navigate(['/sales', this.saleData.uid]);
  }
}
