import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from '@angular/core';
import { SimpleTableColumn } from '../../models/table.model';
import { MatTableDataSource } from '@angular/material/table';
import { StaticService } from '../../services/static.service';

@Component({
  selector: 'simple-table',
  templateUrl: './simple-table.component.html',
  styleUrls: ['./simple-table.component.scss'],
})
export class SimpleTableComponent implements OnChanges {
  @Input() isLoading: boolean = false;
  @Input() data: any[] = [];
  @Input() columns: SimpleTableColumn[] = [];
  @Input() allowActions: boolean = false;
  @Input() allowEdit: boolean = false;
  @Input() allowDelete: boolean = false;
  @Input() checkboxMode: CheckboxMode = 'none';
  @Input() selectedUids: string[] = [];
  @Output() editClicked: EventEmitter<string> = new EventEmitter();
  @Output() deleteClicked: EventEmitter<string> = new EventEmitter();
  @Output() selectedRowsChanged: EventEmitter<string[]> = new EventEmitter();

  actionRowUid: string = '';
  selectedRowUids: string[] = [];

  dataSource: MatTableDataSource<any[]> = new MatTableDataSource<any[]>([]);
  displayedColumns: string[] = [];

  constructor(public staticService: StaticService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['data']) {
      this.initDataSource();
    }
    if (changes['columns']) {
      this.initDisplayedColumns();
    }
    if (changes['selectedUids']) {
      this.selectedRowUids = this.selectedUids;
    }
  }

  initDataSource() {
    this.dataSource = new MatTableDataSource<any[]>(this.data);
  }

  initDisplayedColumns() {
    if (this.checkboxMode !== 'none') {
      this.displayedColumns.push('checkbox');
    }
    this.columns.forEach((column) => {
      this.displayedColumns.push(column.fieldName);
    });
    if (this.allowActions) {
      this.displayedColumns.push('actions');
    }
  }

  onCheckboxChangeAll() {
    if (this.selectedRowUids.length === this.data.length) {
      this.selectedRowUids = [];
    } else {
      this.selectedRowUids = this.data.map((row) => row.uid);
    }
    this.selectedRowsChanged.emit(this.selectedRowUids);
  }

  onCheckboxChange(rowUid: string) {
    if (this.selectedRowUids.includes(rowUid)) {
      this.selectedRowUids = this.selectedRowUids.filter(
        (uid) => uid !== rowUid
      );
    } else {
      if (this.checkboxMode === 'single') {
        this.selectedRowUids = [];
      }
      this.selectedRowUids.push(rowUid);
    }
    this.selectedRowsChanged.emit(this.selectedRowUids);
  }

  onEdit() {
    this.editClicked.emit(this.actionRowUid);
  }

  onDelete() {
    this.deleteClicked.emit(this.actionRowUid);
  }
}

export type CheckboxMode = 'single' | 'multiple' | 'none';
