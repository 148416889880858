<div (click)="close()" class="content" [ngClass]="[data.type]">
  <div class="icon-wrapper mr-6">
    <span *ngIf="data.type === 'success'"
      ><img width="32px" height="32px" src="assets/icons/toastr_success.png" alt="Success icon"
    /></span>
    <span *ngIf="data.type === 'error'"
      ><img width="32px" height="32px" src="assets/icons/toastr_error.png" alt="Error icon"
    /></span>
    <span *ngIf="data.type === 'warning'"
      ><img width="32px" height="32px" src="assets/icons/toastr_warning.png" alt="Error icon"
    /></span>
  </div>
  <div class="info" [ngClass]="[data.type]">
    <p *ngIf="data.type === 'success'" class="title success">{{ 'toastr.success' | translate }}</p>
    <p *ngIf="data.type === 'error'" class="title">{{ 'toastr.error' | translate }}</p>
    <p *ngIf="data.type === 'warning'" class="title">{{ 'toastr.warning' | translate }}</p>

    <p>{{ data.message | translate: messageArgs }}</p>
  </div>
</div>
