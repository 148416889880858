import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { PermissionService } from 'src/app/auth/services/permission.service';
import { ToastrService } from 'src/app/shared/utils/services/toastr.service';
import { TileActionData, TileActionType } from 'src/app/shared/models/tile.model';

@Component({
  selector: 'tile-actions',
  templateUrl: './tile-actions.component.html',
  styleUrls: ['./tile-actions.component.scss'],
})
export class TileActionsComponent {
  @Input() data!: TileActionData[];
  @Output() favToggled = new EventEmitter();
  @Output() publicToggled = new EventEmitter();

  constructor(
    public permissionService: PermissionService,
    private authService: AuthService,
    private toastrService: ToastrService,
    private router: Router
  ) {}

  get isAuthenticated() {
    return this.authService.isAuthenticated;
  }

  click(actionType: TileActionType) {
    if (actionType === 'fav') {
      if (this.isAuthenticated) {
        this.favToggled.emit();
      } else {
        this.toastrService.error('common.actions.toasts.loginFirst');
      }
    }
    if (actionType === 'public') {
      this.publicToggled.emit();
    }
    if (actionType === 'sale') {
      this.router.navigate(['/sales']);
    }
    if (actionType === 'valuation') {
      window.open('https://artguard.app/#contact', '_blank');
    }
    if (actionType === 'wallet') {
      this.router.navigate(['/wallet']);
    }
  }
}
