import { DictionaryWord } from './dictionary.model';
import { AnonymizedUserResponse } from './user.model';

export interface MessageResponse {
  uid: string;
  messageTranslatedBody: string;
  foreignUid: string;
  userAddressedTo: AnonymizedUserResponse;
  messageType: DictionaryWord;
  foreignName: string;
  messageObjectEnum: MessageObject;
}

export enum MessageObject {
  Sale = 'SALE',
  Subject = 'SUBJECT',
  Report = 'REPORT',
}
