<div class="field-wrapper">
    <div class="field">
        <label *ngIf="label">{{label | translate}}
            <span *ngIf="isRequired" class="required">*</span>
        </label>
        <input [placeholder]="placeholder | translate" [formControl]="formControl" [ngxMatDatetimePicker]="picker"
            (click)="picker.open()" [attr.readonly]="true" #input>
        <ngx-mat-datetime-picker #picker (closed)="onPickerClose()"></ngx-mat-datetime-picker>
    </div>
    <div class="error">
        <mat-error *ngIf="control?.touched && control.status === 'INVALID'">
            {{ errorHandler(control) | translate }}
        </mat-error>
    </div>
</div>