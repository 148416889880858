import { Component, Input } from '@angular/core';
import { StaticService } from 'src/app/shared/services/static.service';

@Component({
  selector: 'atom-avatar',
  templateUrl: './atom-avatar.component.html',
  styleUrls: ['./atom-avatar.component.scss'],
})
export class AtomAvatarComponent {
  @Input() label!: string;
  @Input() imageSrc!: string;
  @Input() avatarType!: AvatarType;
  @Input() sizePx: number = 36;
  @Input() fontSizePx: number = 16;

  constructor(public staticService: StaticService) {}
}

export type AvatarType = 'initials' | 'firstLetter' | 'image';
