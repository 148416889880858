import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-eu-funds',
  templateUrl: './eu-funds.component.html',
  styleUrls: ['./eu-funds.component.scss'],
})
export class EuFundsComponent {
  @Input() offWhite?: boolean = false;
  @Input() large?: boolean = false;
}
