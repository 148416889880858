import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { ApiService } from 'src/app/core/api/api.service';
import {
  CollectionCreateRequest,
  CollectionDetailsResponse,
  CollectionListResponse,
  CollectionUpdateRequest,
} from '../models/collection.model';
import { ApiPagingResponseModel } from 'src/app/core/api/api-paging-response.model';

@Injectable({
  providedIn: 'root',
})
export class CollectionApiService extends ApiService {
  apiPrefix = '/api/v1/collection';

  getCollections(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<CollectionListResponse>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<CollectionListResponse>>
    >(`${this.apiPrefix}/thin/mine` + query, null);

    return lastValueFrom(request);
  }

  getMyCollections(
    query: string | null
  ): Promise<
    ApiResponseModel<ApiPagingResponseModel<CollectionDetailsResponse>>
  > {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<CollectionDetailsResponse>>
    >(`${this.apiPrefix}/mine` + query, null);

    return lastValueFrom(request);
  }

  getCollection(
    collectionUid: string
  ): Promise<ApiResponseModel<CollectionDetailsResponse>> {
    const request = this.get<ApiResponseModel<CollectionDetailsResponse>>(
      `${this.apiPrefix}/uid/${collectionUid}`,
      null
    );

    return lastValueFrom(request);
  }

  createCollection(
    collectionData: CollectionCreateRequest
  ): Promise<ApiResponseModel<CollectionDetailsResponse>> {
    const request = this.post<ApiResponseModel<CollectionDetailsResponse>>(
      `${this.apiPrefix}`,
      collectionData
    );

    return lastValueFrom(request);
  }

  updateCollection(
    collectionUid: string,
    collectionData: CollectionUpdateRequest
  ): Promise<ApiResponseModel<CollectionDetailsResponse>> {
    const request = this.put<ApiResponseModel<CollectionDetailsResponse>>(
      `${this.apiPrefix}/${collectionUid}`,
      collectionData
    );

    return lastValueFrom(request);
  }

  deleteCollection(
    collectionUid: string
  ): Promise<ApiResponseModel<CollectionDetailsResponse>> {
    const request = this.delete<ApiResponseModel<CollectionDetailsResponse>>(
      `${this.apiPrefix}/${collectionUid}`
    );

    return lastValueFrom(request);
  }
}
