<div class="field-wrapper">
    <div class="field">
        <label *ngIf="label">{{label | translate}}
            <span *ngIf="isRequired" class="required">*</span>
        </label>
        <ng-container *ngIf="!disabled">
            <input [autocomplete]="autocomplete" [type]="type" [min]="0" [placeholder]="placeholder | translate"
                [formControl]="formControl">
        </ng-container>
        <ng-container *ngIf="disabled">
            <input [autocomplete]="autocomplete" [type]=" type" [min]="0" [placeholder]="placeholder | translate"
                [formControl]="formControl" [attr.readonly]="true">
        </ng-container>
    </div>
    <div class="error">
        <mat-error *ngIf="control?.touched && control.status === 'INVALID'">
            {{ errorHandler(control) | translate }}
        </mat-error>
    </div>
</div>