import { Component, Input } from '@angular/core';
import { SubjectDetailsResponse } from 'src/app/cms/features/collections/models/subject.model';
import { StaticService } from '../../services/static.service';
import { GalleryService } from 'src/app/cms/features/gallery/services/gallery.service';
import { ToastrService } from '../../utils/services/toastr.service';
import { ApiHelper } from 'src/app/core/api/api-helper';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gallery-card',
  templateUrl: './gallery-card.component.html',
  styleUrls: ['./gallery-card.component.scss'],
})
export class GalleryCardComponent {
  @Input() galleryData!: SubjectDetailsResponse;
  @Input() widthPx: string = 'auto';
  @Input() imageHeightPx: string = 'auto';

  constructor(
    public staticService: StaticService,
    private galleryService: GalleryService,
    private toastrService: ToastrService,
    private router: Router
  ) {}
}
