import { Injectable } from '@angular/core';
import { UserConfigApiService } from './user-config.api.service';
import { UserConfigResponse } from '../models/user-config.model';
import { BehaviorSubject } from 'rxjs';
import { TableColumn, TableState } from '../models/table.model';

@Injectable({
  providedIn: 'root',
})
export class UserConfigService {
  configPrefix = 'artguard';
  configTypePageSize = 'page_size';
  configTypeColumns = 'columns';

  pageSizeConfig!: number | null;
  columnsConfig!: TableColumn[] | null;

  private _pageSizeConfigSubject = new BehaviorSubject<number | null>(null);
  pageSizeConfig$ = this._pageSizeConfigSubject.asObservable();

  private _columnsConfigSubject = new BehaviorSubject<TableColumn[] | null>(
    null
  );
  columnsConfig$ = this._columnsConfigSubject.asObservable();

  constructor(private userConfigApiService: UserConfigApiService) {}

  loadPageSizeConfig(configRoute: string): Promise<number | null> {
    const configKey = `${this.configPrefix}_${configRoute}_${this.configTypePageSize}`;
    return this.userConfigApiService
      .getUserTableConfig(configKey)
      .then((response) => {
        const json = JSON.parse(response.result.configJSON);
        const pageSizeConfig = parseInt(json.pageSize);
        return pageSizeConfig;
      })
      .catch((error) => {
        return null;
      });
  }

  loadColumnsConfig(configRoute: string): Promise<TableColumn[] | null> {
    const configKey = `${this.configPrefix}_${configRoute}_${this.configTypeColumns}`;
    return this.userConfigApiService
      .getUserTableConfig(configKey)
      .then((response) => {
        const columnConfig = JSON.parse(response.result.configJSON);
        return columnConfig;
      })
      .catch((error) => {
        return null;
      });
  }

  async getUserPageSizeConfig(configRoute: string): Promise<number | null> {
    try {
      const pageSizeConfig = await this.loadPageSizeConfig(configRoute);
      return pageSizeConfig;
    } catch (error) {
      return null;
    }
  }

  async getUserColumnsConfig(
    configRoute: string
  ): Promise<TableColumn[] | null> {
    try {
      const columnsConfig = await this.loadColumnsConfig(configRoute);
      return columnsConfig;
    } catch (error) {
      return null;
    }
  }

  savePageSizeConfig(configRoute: string, pageSize: number, config: any) {
    const configKey = `${this.configPrefix}_${configRoute}_${this.configTypePageSize}`;
    const configData = {
      configKey: configKey,
      configJSON: JSON.stringify({
        pageSize: pageSize.toString(),
      }),
    };
    if (config) {
      this.userConfigApiService.updateUserTableConfig(configKey, configData);
    } else {
      this.userConfigApiService.createUserTableConfig(configData);
    }
  }

  saveColumnsConfig(configRoute: string, columns: TableColumn[], config: any) {
    const configKey = `${this.configPrefix}_${configRoute}_${this.configTypeColumns}`;
    const configData = {
      configKey: configKey,
      configJSON: JSON.stringify(columns),
    };
    if (config) {
      this.userConfigApiService.updateUserTableConfig(configKey, configData);
    } else {
      this.userConfigApiService.createUserTableConfig(configData);
    }
  }
}
