<div class="content" (click)="goToSaleDetails()">
    <div class="image-gallery">
        <app-sale-card-gallery [images]="galleryImages" [borderRadiusTop]="true"
            [borderRadiusBottom]="true"></app-sale-card-gallery>
        <div class="label">
            <app-sale-card-label [saleTypeIN]="saleData.type.internalName"></app-sale-card-label>
        </div>
    </div>
    <div class="data">
        <app-sale-card-data [saleData]="saleData" [borderRadiusTop]="true" [borderRadiusBottom]="true"
            [saleType]="saleType" width="400px" [isHorizontal]="true"></app-sale-card-data>
    </div>
</div>