<div class="content">
    <div class="sidebar-header">{{ 'admin.title' | translate }}</div>
    <ul class="sidebar-items">
        <li class="sidebar-item" (click)="onClick('users')">
            <div class="icon-container">
                <svg-icon src="/assets/icons/users.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
            </div>
            <span>{{ 'admin.menu.users' | translate }}</span>
        <li class="sidebar-item" (click)="onClick('roles')">
            <div class="icon-container">
                <svg-icon src="/assets/icons/roles.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
            </div>
            <span>{{ 'admin.menu.roles' | translate }}</span>
        </li>
        <li class="sidebar-item" (click)="onClick('categories')">
            <div class="icon-container">
                <svg-icon src="/assets/icons/categories.svg"
                    [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
            </div>
            <span>{{ 'admin.menu.categoriesAndSubcategories' | translate }}</span>
        </li>
        <li class="sidebar-item" (click)="onClick('dictionaries')">
            <div class="icon-container">
                <svg-icon src="/assets/icons/dictionaries.svg"
                    [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
            </div>
            <span>{{ 'admin.menu.dictionaries' | translate }}</span>
        </li>
        <li class="sidebar-item" (click)="onClick('dynamic-config')">
            <div class="icon-container">
                <svg-icon src="/assets/icons/configuration.svg"
                    [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
            </div>
            <span>{{ 'admin.menu.dynamicConfig' | translate }}</span>
        </li>
        <li class="sidebar-item" (click)="onClick('system-logs')">
            <div class="icon-container">
                <svg-icon src="/assets/icons/logs.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
            </div>
            <span>{{ 'admin.menu.systemLogs' | translate }}</span>
        </li>
        <li class="sidebar-item" (click)="onClick('authors')">
            <div class="icon-container">
                <svg-icon src="/assets/icons/authors.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
            </div>
            <span>{{ 'admin.menu.authors' | translate }}</span>
        </li>
        <li class="sidebar-item" (click)="onClick('subjects')">
            <div class="icon-container">
                <svg-icon src="/assets/icons/subjects.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
            </div>
            <span>{{ 'admin.menu.subjects' | translate }}</span>
        </li>
        <li class="sidebar-item" (click)="onClick('sales')">
            <div class="icon-container">
                <svg-icon src="/assets/icons/offers.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
            </div>
            <span>{{ 'admin.menu.sales' | translate }}</span>
        </li>
    </ul>
</div>