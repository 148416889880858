import { Injectable } from '@angular/core';
import {
  UserConfigCreateRequest,
  UserConfigResponse,
  userConfigUpdateRequest,
} from '../models/user-config.model';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { ApiService } from 'src/app/core/api/api.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserConfigApiService extends ApiService {
  apiPrefix = '/api/v1/admin/user/user-table-config';

  getUserTableConfig(
    configKey: string
  ): Promise<ApiResponseModel<UserConfigResponse>> {
    const request = this.get<ApiResponseModel<UserConfigResponse>>(
      `${this.apiPrefix}/${configKey}`,
      null
    );

    return lastValueFrom(request);
  }

  getUserTableConfigs(): Promise<ApiResponseModel<UserConfigResponse[]>> {
    const request = this.get<ApiResponseModel<UserConfigResponse[]>>(
      `${this.apiPrefix}`,
      null
    );

    return lastValueFrom(request);
  }

  createUserTableConfig(
    configData: UserConfigCreateRequest
  ): Promise<ApiResponseModel<UserConfigResponse>> {
    const request = this.post<ApiResponseModel<UserConfigResponse>>(
      `${this.apiPrefix}`,
      configData
    );

    return lastValueFrom(request);
  }

  updateUserTableConfig(
    configKey: string,
    configData: userConfigUpdateRequest
  ): Promise<ApiResponseModel<UserConfigResponse>> {
    const request = this.put<ApiResponseModel<UserConfigResponse>>(
      `${this.apiPrefix}/${configKey}`,
      configData
    );

    return lastValueFrom(request);
  }
}
