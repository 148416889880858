<div class="tile padding">
    <div class="content">
        <div class="avatar">
            <atom-avatar [label]="data.avatarLabel!" [imageSrc]="data.avatarImage!"
                [avatarType]="data.avatarType"></atom-avatar>
        </div>
        <div class="data">
            <div class="title">
                <span>{{ data.title }}</span>
            </div>
            <div class="subtitle">
                <span *ngIf="data.subtitle">{{ data.subtitle }}</span>
                <span *ngIf="data.subjectsCount">{{ 'collection.numberOfSubjects' | translate }}: {{ data.subjectsCount
                    }}</span>
            </div>
        </div>
        <div class="buttons">
            <div *ngIf="data.allowOther" class="button other" (click)="onOther()">
                <button>
                    <img src="/assets/icons/offers.svg" width="18" height="18">
                </button>
            </div>
            <div *ngIf="data.allowEdit" class="button edit" (click)="onEdit()">
                <button>
                    <img src="/assets/icons/edit_yellow.svg" width="18" height="18">
                </button>
            </div>
            <div *ngIf="data.allowDelete" class="button delete" (click)="onDelete()">
                <button>
                    <img src="/assets/icons/delete_red.svg" width="18" height="18">
                </button>
            </div>
        </div>
    </div>
</div>