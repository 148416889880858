<div class="field-wrapper">
    <div class="field textarea">
        <label *ngIf="label">{{label | translate }}
            <span *ngIf="isRequired" class="required">*</span>
        </label>
        <textarea [placeholder]="placeholder | translate" [rows]="rows" [formControl]="formControl"></textarea>
    </div>
    <div class="error">
        <mat-error *ngIf="control?.touched && control.status === 'INVALID'">
            {{ errorHandler(control) | translate}}
        </mat-error>
    </div>
</div>