<a [routerLink]="'/sales/'+saleData.uid" class="content">
    <div class="image-gallery-wrapper">
        <div class="image-gallery">
            <app-sale-card-gallery [images]="galleryImages" [borderRadiusTop]="true"></app-sale-card-gallery>
            <div class="label">
                <app-sale-card-label [saleTypeIN]="saleData.type.internalName"></app-sale-card-label>
            </div>
        </div>
    </div>
    <div class="data">
        <app-sale-card-data [saleData]="saleData" [borderRadiusBottom]="true"
            [saleType]="saleType"></app-sale-card-data>
    </div>
</a>
