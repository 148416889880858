import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CmsLayoutComponent } from './cms-layout/cms-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [CmsLayoutComponent, AuthLayoutComponent],
  imports: [CommonModule, RouterModule, SharedModule, NgOptimizedImage],
  exports: [CmsLayoutComponent, AuthLayoutComponent],
})
export class LayoutsModule {}
