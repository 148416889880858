import { FormControl } from '@angular/forms';

export const MIN_INPUT_LENGTH = 2;
export const MAX_INPUT_LENGTH = 100;

export const MIN_PASSWORD_LENGTH = 8;
export const MAX_TEXTAREA_LENGTH = 1000;
export const MAX_FILE_SIZE = 10;

export const noWhitespaceValidator = (control: FormControl) => {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
};

export const ErrorMessages = [
  {
    field: 'login',
    errors: [
      {
        error: 'required',
        message: 'errorMessages.login.required',
      },
    ],
  },
  {
    field: 'email',
    errors: [
      {
        error: 'required',
        message: 'errorMessages.email.required',
      },
      {
        error: 'email',
        message: 'errorMessages.email.email',
      },
    ],
  },
  {
    field: 'temporaryPassword',
    errors: [
      {
        error: 'required',
        message: 'errorMessages.temporaryPassword.required',
      },
      {
        error: 'password',
        message: 'errorMessages.temporaryPassword.password',
      },
    ],
  },
  {
    field: 'password',
    errors: [
      {
        error: 'required',
        message: 'errorMessages.password.required',
      },
      {
        error: 'password',
        message: 'errorMessages.password.password',
      },
    ],
  },
  {
    field: 'newPassword',
    errors: [
      {
        error: 'required',
        message: 'errorMessages.newPassword.required',
      },
      {
        error: 'password',
        message: 'errorMessages.newPassword.password',
      },
    ],
  },
  {
    field: 'confirmPassword',
    errors: [
      {
        error: 'required',
        message: 'errorMessages.confirmPassword.required',
      },
      {
        error: 'equalToFieldValue',
        message: 'errorMessages.confirmPassword.equalToFieldValue',
      },
    ],
  },
  {
    field: 'newPasswordConfirmation',
    errors: [
      {
        error: 'required',
        message: 'errorMessages.newPasswordConfirmation.required',
      },
      {
        error: 'equalToFieldValue',
        message: 'errorMessages.newPasswordConfirmation.equalToFieldValue',
      },
    ],
  },
];

export const DEFAULT_ERROR_MESSAGES = {
  required: (): string => `errorMessages.newPasswordConfirmation.required`,
};
