import { Component, Input } from '@angular/core';

@Component({
  selector: 'atom-spinner',
  templateUrl: './atom-spinner.component.html',
  styleUrls: ['./atom-spinner.component.scss'],
})
export class AtomSpinnerComponent {
  @Input() diameter: string = '50';
}
