<div class="content">
    <ul class="menu" [style.color]="color" [ngClass]="isMobile ? 'isMobile' : ''">
        <ng-container *ngIf="isAuthenticated">
            <li [matMenuTriggerFor]="auctionsMenu" class="item">
                <span>{{'header.menu.sales.title' | translate}}</span>
                <svg-icon src="assets/icons/chevron-down.svg"
                    [svgStyle]="{ 'fill': color === 'black' ? '#DEDCD3' : 'white', 'width.px' : 12, 'height.px' : 12 }"></svg-icon>
            </li>
            <li [matMenuTriggerFor]="galleriesMenu" class="item">
                <span>{{'header.menu.galleries.title' | translate}}</span>
                <svg-icon src="assets/icons/chevron-down.svg"
                    [svgStyle]="{ 'fill': color === 'black' ? '#DEDCD3' : 'white', 'width.px' : 12, 'height.px' : 12 }"></svg-icon>
            </li>
            <li class="item">
                <a routerLink="/collections">
                    <span>{{'header.menu.collections.title' | translate}}</span>
                </a>
            </li>
            <li *ngIf="permissionService.hasPermission('WalletManager')" class="item">
                <a routerLink="/wallet">
                    <span>{{'header.menu.wallet.title' | translate}}</span>
                </a>
            </li>
            <li *ngIf="canAccessReports()" class="item">
                <a routerLink="/reports">
                    <span>{{'header.menu.reports.title' | translate}}</span>
                </a>
            </li>
        </ng-container>
        <ng-container *ngIf="!isAuthenticated">
            <li class="item">
                <a routerLink="/sales">
                    <span>{{'header.menu.sales.title' | translate}}</span>
                </a>
            </li>
            <li class="item">
                <a routerLink="/gallery">
                    <span>{{'header.menu.galleries.title' | translate}}</span>
                </a>
            </li>
        </ng-container>
        <li class="item">
            <a routerLink="/articles">
                <span>{{'header.menu.news.title' | translate}}</span>
            </a>
        </li>
    </ul>
</div>
<mat-menu #auctionsMenu="matMenu">
    <div class="header-dropdown">
        <app-dropdown [items]="salesMenuItems"></app-dropdown>
    </div>
</mat-menu>
<mat-menu #galleriesMenu="matMenu">
    <div class="header-dropdown">
        <app-dropdown [items]="galleriesMenuItems"></app-dropdown>
    </div>
</mat-menu>
