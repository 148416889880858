import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Filter } from '../models/filter.model';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private _sidebarOpen = new BehaviorSubject<Filter[]>([]);
  sidebarOpen$ = this._sidebarOpen.asObservable();

  private _sidebarClose = new BehaviorSubject<void>(void 0);
  sidebarClose$ = this._sidebarClose.asObservable();

  private _applyFilters = new BehaviorSubject<Filter[]>([]);
  applyFilters$ = this._applyFilters.asObservable();

  constructor() { }

  openSidebar(filters: Filter[]) {
    this._sidebarOpen.next(filters);
  }

  closeSidebar() {
    this._sidebarClose.next();
  }

  applyFilters(filters: Filter[]) {
    this._applyFilters.next(filters);
  }
}
