import { Component, Inject } from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { ToastrData } from './toastr.model';

@Component({
  selector: 'app-toastr-dialog',
  templateUrl: './toastr-dialog.component.html',
  styleUrls: ['./toastr-dialog.component.scss'],
})
export class ToastrDialogComponent {
  messageArgs: object = {};
  constructor(
    public toastrRef: MatSnackBarRef<ToastrDialogComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: ToastrData
  ) {}

  ngOnInit() {
    this.messageArgs = this.data.messageArgs || {};
  }

  close() {
    this.toastrRef.dismiss();
  }
}
