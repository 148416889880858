import { Injectable } from '@angular/core';
import { MessageApiService } from './message-api.service';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { ApiPagingResponseModel } from 'src/app/core/api/api-paging-response.model';
import { MessageResponse } from '../models/message.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private messageApiService: MessageApiService) {}

  getMyMessages(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<MessageResponse>>> {
    return this.messageApiService.getMyMessages(query);
  }

  getMessagesCount(query: string | null): Promise<ApiResponseModel<number>> {
    return this.messageApiService.getMessagesCount(query);
  }

  markAsRead(messageUid: string): Promise<ApiResponseModel<MessageResponse>> {
    return this.messageApiService.markAsRead(messageUid);
  }
}
