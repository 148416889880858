import { Component, Input } from '@angular/core';
import { ToastrData } from 'src/app/shared/dialogs/toastr-dialog/toastr.model';
import { TileToastrService } from 'src/app/shared/utils/services/tile-toastr.service';

@Component({
  selector: 'tile-toastr',
  templateUrl: './tile-toastr.component.html',
  styleUrls: ['./tile-toastr.component.scss'],
})
export class TileToastrComponent {
  @Input() toastr: ToastrData | null = null;

  constructor(private tileToastrService: TileToastrService) {}

  onClick() {
    this.tileToastrService.clear();
  }
}
