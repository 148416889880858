<div class="content" [class.hidden]="!toastr" (click)="onClick()">
    <ng-container *ngIf="toastr">
        <div class="wrapper" [ngClass]="toastr.type">
            <div class="icon-wrapper">
                <div class="icon">
                    <svg-icon [src]="'/assets/icons/tile-toastr-' + toastr.type + '.svg'"
                        [svgStyle]="{ 'fill': '#fff', 'height.px' : 15 }"></svg-icon>
                </div>
            </div>
            <div class="details">
                <div class="title"> {{ 'toastr.' + toastr.type | translate }} </div>
                <div class="message">{{ toastr.message | translate }} </div>
            </div>
        </div>
    </ng-container>
</div>