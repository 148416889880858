import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import {
  MessageObject,
  MessageResponse,
} from 'src/app/shared/models/message.model';
import { TableState } from 'src/app/shared/models/table.model';
import { MessageService } from 'src/app/shared/services/message.service';
import {
  DEFAULT_TABLE_STATE,
  HeaderElementsColor,
} from 'src/app/shared/utils/common';
import { TableStateService } from 'src/app/shared/utils/services/table-state.service';

@Component({
  selector: 'app-header-messages',
  templateUrl: './header-messages.component.html',
  styleUrls: ['./header-messages.component.scss'],
})
export class HeaderMessagesComponent {
  @Input() color: HeaderElementsColor = 'black';
  isLoading: boolean = false;
  messagesCount: number = 0;
  messages: MessageResponse[] = [];
  tableState: TableState = DEFAULT_TABLE_STATE;
  messageObjectEnum = MessageObject;

  constructor(
    private messageService: MessageService,
    private tableStateService: TableStateService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.tableState.pageSize = 99;
    this.getUnreadMessagesCount();
  }

  getUnreadMessagesCount(): void {
    this.messageService.getMessagesCount('?read=false').then((response) => {
      this.messagesCount = response.result;
    });
  }

  getUnreadMessages(): void {
    this.isLoading = true;
    const query = this.tableStateService.createQueryParams(this.tableState, [
      {
        internalName: 'read',
        value: 'false',
      },
    ]);
    this.messageService
      .getMyMessages(query)
      .then((response) => {
        this.messages = response.result.results;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  redirectToObjectPage(message: MessageResponse) {
    this.messageService.markAsRead(message.uid).then(() => {
      this.getUnreadMessagesCount();
      switch (message.messageObjectEnum) {
        case this.messageObjectEnum.Sale:
          this.router.navigateByUrl(`/sales/${message.foreignUid}`);
          break;
        case this.messageObjectEnum.Subject:
          this.router.navigateByUrl(`/gallery/${message.foreignUid}`);
          break;
        case this.messageObjectEnum.Report:
          this.router.navigateByUrl(`/reports/${message.foreignUid}`);
          break;
        default:
          return;
      }
    });
  }
}
