import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DropdownItem } from '../../models/dropdown.model';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() items: DropdownItem[] = [];
  @Input() itemWidth: string = '200px';

  @Output() click = new EventEmitter<string>();

  onClick(route: string) {
    this.click.emit(route);
  }
}
