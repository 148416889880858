import { Component, Input } from '@angular/core';
import { UserCard, UserWithSubcategoriesResponse } from '../../models/user.model';
import { StaticService } from '../../services/static.service';
import { HostListener } from '@angular/core';

@Component({
  selector: 'user-card',
  templateUrl: './user-card.component.html',
  styleUrls: ['./user-card.component.scss'],
})
export class UserCardComponent {
  @Input() userData!: UserCard;
  @Input() widthPx: string = 'auto';
  @Input() backgroundColor: string = 'white';
  @Input() navigateToList?: 'sales' | 'gallery';
  screenHeight: number = 0;
  screenWidth: number = 0;
  isMobile: boolean = false;

  constructor(public staticService: StaticService) {
    this.getScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;

    if (this.screenWidth < Number(this.widthPx.replace('px', '')) * 2) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }
}
