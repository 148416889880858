<a class="content" [routerLink]="'/gallery/' + galleryData.uid">
  <div class="top">
    <div class="seller">
      <div class="avatar">
        <img
          [src]="
            galleryData.owner.mainPhoto
              ? staticService.getStaticUrl(galleryData.owner.mainPhoto.url)
              : '/assets/icons/user.svg'
          "
        />
      </div>
      <div class="name">{{ galleryData.owner.anonymizedName }}</div>
    </div>
  </div>
  <div class="image">
    <img
      class="blurred"
      [src]="
        galleryData.mainPhoto
          ? staticService.getStaticUrl(galleryData.mainPhoto.miniatureUrl)
          : '/assets/icons/placeholder.svg'
      "
    />
    <img
      [src]="
        galleryData.mainPhoto
          ? staticService.getStaticUrl(galleryData.mainPhoto.miniatureUrl)
          : '/assets/icons/placeholder.svg'
      "
    />
  </div>
  <div class="bottom">
    <div class="author" *ngIf="galleryData.authors.length">{{ galleryData.authors[0].name }}</div>
    <div class="title">{{ galleryData.name }}</div>
  </div>
</a>
