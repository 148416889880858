import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/core/api/api.service';
import { SubjectDetailsResponse } from '../../collections/models/subject.model';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { ApiPagingResponseModel } from 'src/app/core/api/api-paging-response.model';
import { lastValueFrom } from 'rxjs';
import { UserWithSubcategoriesResponse } from 'src/app/shared/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class GalleryApiService extends ApiService {
  apiPrefix = '/api/v1/gallery';

  getGalleries(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>> {
    const request = this.get<ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>>(
      `${this.apiPrefix}` + query,
      null
    );
    return lastValueFrom(request);
  }
  getRecommendedGalleries(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>> {
    const request = this.get<ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>>(
      `${this.apiPrefix}/recommended` + query,
      null
    );
    return lastValueFrom(request);
  }

  toggleFavorite(uid: string): Promise<ApiResponseModel<SubjectDetailsResponse>> {
    const request = this.post<ApiResponseModel<SubjectDetailsResponse>>(
      `${this.apiPrefix}/${uid}/toggle-favourite`,
      null
    );
    return lastValueFrom(request);
  }

  getPopularUsers(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<UserWithSubcategoriesResponse>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<UserWithSubcategoriesResponse>>
    >(`${this.apiPrefix}/popular-users` + query, null);
    return lastValueFrom(request);
  }

  getFavoriteGalleries(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>> {
    const request = this.get<ApiResponseModel<ApiPagingResponseModel<SubjectDetailsResponse>>>(
      `${this.apiPrefix}/favourite` + query,
      null
    );
    return lastValueFrom(request);
  }
}
