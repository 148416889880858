import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TileTableData } from 'src/app/shared/models/tile.model';

@Component({
  selector: 'tile-table',
  templateUrl: './tile-table.component.html',
  styleUrls: ['./tile-table.component.scss'],
})
export class TileTableComponent {
  @Input() isLoading: boolean = false;
  @Input() data!: TileTableData;
  @Input() selectedUids: string[] = [];
  @Output() selectedRowsChanged: EventEmitter<string[]> = new EventEmitter();

  onSelectedRowsChange(selectedRowUids: string[]) {
    this.selectedRowsChanged.emit(selectedRowUids);
  }
}
