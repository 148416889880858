import { ToastrData } from '../../dialogs/toastr-dialog/toastr.model';
import { ToastrDialogComponent } from '../../dialogs/toastr-dialog/toastr-dialog.component';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

const DURATION = 5000;

@Injectable({
  providedIn: 'root',
})
export class ToastrService {
  constructor(private snackBar: MatSnackBar) {}

  error(message: string, messageArgs: object = {}) {
    const data: ToastrData = {
      message,
      messageArgs,
      type: 'error',
    };

    this.snackBar.openFromComponent(ToastrDialogComponent, {
      duration: DURATION,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['toastr-panel'],
      data,
    });
  }

  success(message: string, messageArgs: object = {}) {
    const data: ToastrData = {
      message,
      messageArgs,
      type: 'success',
    };

    this.snackBar.openFromComponent(ToastrDialogComponent, {
      duration: DURATION,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['toastr-panel'],
      data,
    });
  }

  warning(message: string, messageArgs: object = {}) {
    const data: ToastrData = {
      message,
      messageArgs,
      type: 'warning',
    };

    this.snackBar.openFromComponent(ToastrDialogComponent, {
      duration: DURATION,
      verticalPosition: 'top',
      horizontalPosition: 'right',
      panelClass: ['toastr-panel'],
      data,
    });
  }
}
