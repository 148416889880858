import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TileButtonData } from 'src/app/shared/models/tile.model';

@Component({
  selector: 'tile-button',
  templateUrl: './tile-button.component.html',
  styleUrls: ['./tile-button.component.scss'],
})
export class TileButtonComponent {
  @Input() data!: TileButtonData;
  @Output() clicked: EventEmitter<any> = new EventEmitter<any>();

  onClick() {
    this.clicked.emit();
  }
}
