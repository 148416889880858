import { ActivatedRoute } from '@angular/router';
import { TableState, TableStateArticles } from '../models/table.model';
import { MatDateFormats } from '@angular/material/core';

export const STORAGE_PUBLIC_KEY = 'artguard';
export const PAGE_SIZES: number[] = [24, 48, 96];
export const DEFAULT_PAGE_SIZE = 24;
export const DEFAULT_PAGE = 0;
export const DEFAULT_ORDER_BY = 'createdAt';
export const DEFAULT_ORDER_TYPE = 'desc';
export const DEFAULT_SEARCH = '';
export const DEFAULT_TABLE_STATE: TableState = {
  search: DEFAULT_SEARCH,
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_PAGE_SIZE,
  total: 0,
  orderBy: DEFAULT_ORDER_BY,
  orderType: DEFAULT_ORDER_TYPE,
};
export const DEFAULT_TABLE_STATE_ARTICLES: TableStateArticles = {
  page: 1,
  pageSize: DEFAULT_PAGE_SIZE,
  total: 1,
};
export const DEFAULT_SIMPLE_PAGE_SIZE = 1000;
export const DEFAULT_SIMPLE_TABLE_STATE: TableState = {
  search: DEFAULT_SEARCH,
  page: DEFAULT_PAGE,
  pageSize: DEFAULT_SIMPLE_PAGE_SIZE,
  total: 0,
  orderBy: DEFAULT_ORDER_BY,
  orderType: DEFAULT_ORDER_TYPE,
};
export const ORDER_TYPES: string[] = ['asc', 'desc'];
export const DEBOUNCE_TIME = 800;
export const COOKIE_KEY = 'artguard_';

export const DEFAULT_SUBJECT_TYPE_INTERNAL_NAME = 'Default';
export const DEFAULT_SUBJECT_STATUS_INTERNAL_NAME = 'Private';
export const DEFAULT_SUBJECT_CATEGORY_INTERNAL_NAME = 'DefaultCategory';
export const DEFAULT_MAIN_PHOTO_TYPE_INTERNAL_NAME = 'MainPhoto';
export const DEFAULT_OTHER_PHOTO_TYPE_INTERNAL_NAME = 'OtherPhoto';
export const DEFAULT_STANDARD_USER_PUBLIC_ROLE_INTERNAL_NAME = 'StandardUser';
export const DEFAULT_ARTIST_USER_PUBLIC_ROLE_INTERNAL_NAME = 'ArtistUser';
export const DEFAULT_BUSINESS_OWNER_PUBLIC_ROLE_INTERNAL_NAME = 'BusinessOwner';
export const SUBJECT_STATUS_PUBLIC_INTERNAL_NAME = 'Public';
export const SUBJECT_STATUS_PRIVATE_INTERNAL_NAME = 'Private';

export const DEFAULT_ADMIN_USER_ROLE_INTERNAL_NAME = 'Administrator';
export const DICTIONARY_SUBJECT_CATEGORIES_INTERNAL_NAME = 'SubjectCategories';
export const DICTIONARY_SUBJECT_SUBCATEGORIES_INTERNAL_NAME = 'SubjectSubcategories';

export const SALE_STATUS_CREATED_INTERNAL_NAME = 'Created';
export const SALE_STATUS_STARTED_INTERNAL_NAME = 'Started';
export const SALE_STATUS_ENDED_INTERNAL_NAME = 'Ended';
export const SALE_STATUS_RESERVED_INTERNAL_NAME = 'Reserved';

export const DEFAULT_SALE_ACTIVE_STATUSES_INTERNAL_NAMES = [
  SALE_STATUS_CREATED_INTERNAL_NAME,
  SALE_STATUS_STARTED_INTERNAL_NAME,
  SALE_STATUS_RESERVED_INTERNAL_NAME,
];
export const DEFAULT_SALE_ENDED_STATUSES_INTERNAL_NAMES = [SALE_STATUS_ENDED_INTERNAL_NAME];

export const DEFAULT_CURRENCY_INTERNAL_NAME = 'PLN';

export const DEFAULT_RANKING_PERIOD_INTERNAL_NAME = 'Day';

export const SALE_UNIT_TYPE_WHOLE_COLLECTION_INTERNAL_NAME = 'WholeCollection';
export const SALE_UNIT_TYPE_SINGLE_SUBJECT_INTERNAL_NAME = 'SingleSubject';
export const SALE_UNIT_TYPE_MULTIPLE_SUBJECTS_INTERNAL_NAME = 'MultipleSubjects';

export const SALE_TYPE_BUY_NOW_INTERNAL_NAME = 'BuyNow';
export const SALE_TYPE_AUCTION_STANDARD_INTERNAL_NAME = 'AuctionStandard';
export const SALE_TYPE_AUCTION_LIVE_INTERNAL_NAME = 'AuctionLive';
export const SALE_TYPE_RENT_INTERNAL_NAME = 'Rent';

export const DEFAULT_LOCALE = 'pl-PL';
export const DEFAULT_DATE_FORMAT = 'DD-MM-yyyy';
export const DEFAULT_MAT_DATE_FORMAT: MatDateFormats = {
  parse: {
    dateInput: `${DEFAULT_DATE_FORMAT} HH:mm`,
  },
  display: {
    dateInput: `${DEFAULT_DATE_FORMAT} HH:mm`,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export const DEFAULT_MIN_FILE_DIMENSIONS = 1000;

export type InputType = 'text' | 'password' | 'email' | 'number' | 'tel' | 'url';

export type FileType = 'image' | 'document';

export type HeaderElementsColor = 'black' | 'white';

export function getActiveRoute(route: ActivatedRoute) {
  let activeRoute = null;
  if (route.snapshot['routeConfig']?.path) {
    activeRoute = route.snapshot['routeConfig'].path.split('/')[0].split('?')[0];
  } else {
    const routesArr = (route.snapshot as any)['_routerState'].url.split('/');
    activeRoute = routesArr[routesArr.length - 1].split('?')[0];
  }
  return activeRoute;
}

export function formatBytes(bytes: number, decimals = 2): string {
  if (bytes === 0) return '0 B';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
