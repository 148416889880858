import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class StaticService {
  getStaticUrl(fileUrl: string): string {
    return `${environment.staticUrl}/${fileUrl}`;
  }
}
