<div class="container">
  <div class="auth-content">
    <div class="card">
      <router-outlet></router-outlet>
    </div>
  </div>
  <footer>
    <app-eu-funds></app-eu-funds>
  </footer>
</div>
