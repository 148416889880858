import { Injectable } from '@angular/core';
import { ToastrData } from '../../dialogs/toastr-dialog/toastr.model';

@Injectable({
  providedIn: 'root',
})
export class TileToastrService {
  public toastr: ToastrData | null = null;

  constructor() {}

  error(message: string, timeout?: number) {
    this.toastr = {
      message,
      type: 'error',
    };

    if (timeout) {
      setTimeout(() => {
        this.clear();
      }, timeout);
    }
  }

  success(message: string, timeout?: number) {
    this.toastr = {
      message,
      type: 'success',
    };

    if (timeout) {
      setTimeout(() => {
        this.clear();
      }, timeout);
    }
  }

  clear() {
    this.toastr = null;
  }
}
