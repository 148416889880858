import { Component, Input } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { UploadedFile } from 'src/app/shared/models/file.model';
import { ToastrService } from 'src/app/shared/utils/services/toastr.service';
import { NgxCroppedEvent, NgxPhotoEditorService } from 'ngx-photo-editor';
import { DEFAULT_MIN_FILE_DIMENSIONS } from '../../../utils/common';

@Component({
  selector: 'atom-file-selector',
  templateUrl: './atom-file-selector.component.html',
  styleUrls: ['./atom-file-selector.component.scss'],
})
export class AtomFileSelectorComponent {
  @Input() fileType: string = 'image';
  @Input() array!: FormArray<FormControl<UploadedFile | null>>;
  @Input() label!: string;
  @Input() multiple: boolean = true;
  @Input() maxFiles: number = Infinity;
  output?: NgxCroppedEvent;

  constructor(
    private toastrService: ToastrService,
    private service: NgxPhotoEditorService
  ) {}
  onDrop(event: DragEvent) {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files && files.length > 0) {
      if (this.checkIfMaxFilesReached()) {
        this.toastrService.error('fileSelector.toasts.maxFileQtyReached');
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (this.fileType === 'avatar') {
          this.fileChangeHandler(files[i]);
        } else {
          this.readImageFile(files[i]);
        }
      }
    }
  }

  onFilePicked(event: Event) {
    const files = (event.target as HTMLInputElement)?.files;
    if (files) {
      if (this.checkIfMaxFilesReached()) {
        this.toastrService.error('fileSelector.toasts.maxFileQtyReached');
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (this.fileType === 'avatar') {
          this.fileChangeHandler(files[i]);
        } else {
          this.readImageFile(files[i]);
        }
      }
    }
  }

  checkIfMaxFilesReached() {
    return this.array.value.filter((file) => !file?.isDeleted).length >= this.maxFiles;
  }

  onDragOver(event: Event) {
    event.preventDefault();
  }

  onDragLeave(event: Event) {
    event.preventDefault();
  }

  private readImageFile(file: File, base64?: any) {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = (event: any) => {
      let dataUrl = reader.result;
      if (base64) {
        dataUrl = base64;
      }

      let hasCorrectWidthHeight = false;

      var image = new Image();
      image.src = event.target.result;
      image.onload = function () {
        if (
          image.width > DEFAULT_MIN_FILE_DIMENSIONS &&
          image.height > DEFAULT_MIN_FILE_DIMENSIONS
        ) {
          hasCorrectWidthHeight = true;
        }
      };

      setTimeout(() => {
        if (!hasCorrectWidthHeight && this.fileType == 'image') {
          this.toastrService.error('fileSelector.toasts.fileSizeError', {
            minDimensions: DEFAULT_MIN_FILE_DIMENSIONS,
          });
        }

        if ((dataUrl && hasCorrectWidthHeight) || (dataUrl && this.fileType !== 'image')) {
          if (!this.checkFileType(file)) {
            this.toastrService.error('fileSelector.toasts.wrongFileType');
            return;
          }
          const uploadedFile: UploadedFile = {
            file,
            isNew: true,
            url: dataUrl.toString(),
            name: file.name,
            isDeleted: false,
            isMain: this.array.value.filter((file) => !file?.isDeleted).length === 0,
          };
          this.array.push(new FormControl(uploadedFile));
          this.toastrService.success('fileSelector.toasts.success');
        }
      }, 500);
    };
  }

  fileChangeHandler(event: any) {
    this.service
      .open(event, {
        aspectRatio: 1 / 1,
        autoCropArea: 1,
      })
      .subscribe((data) => {
        this.output = data;
        this.readImageFile(data.file as any, data.base64);
      });
    return this.output;
  }

  private checkFileType(file: File) {
    const validImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
    const validDocumentTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];
    const validImportTypes = [
      'application/pdf',
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ];

    let isFileTypeValid = false;

    if (
      (this.fileType === 'image' && validImageTypes.includes(file.type)) ||
      (this.fileType === 'avatar' && validImageTypes.includes(file.type))
    ) {
      isFileTypeValid = true;
    } else if (this.fileType === 'document' && validDocumentTypes.includes(file.type)) {
      isFileTypeValid = true;
    } else if (this.fileType === 'import' && validImportTypes.includes(file.type)) {
      isFileTypeValid = true;
    }

    return isFileTypeValid;
  }

  onUploadedFileRemove(index: number) {
    const uploadedFile = this.array.value[index];
    if (uploadedFile) {
      uploadedFile.isDeleted = true;
      if (
        (this.fileType === 'image' && uploadedFile.isMain) ||
        (this.fileType === 'avatar' && uploadedFile.isMain)
      ) {
        const newMainFile = this.array.value.filter((file) => !file?.isDeleted)[0];
        if (newMainFile) {
          newMainFile.isMain = true;
        }
      }
    }
  }

  handleMainFileChange(index: number) {
    if (this.fileType === 'image' || this.fileType === 'avatar') {
      this.array.value.forEach((file) => {
        if (file) {
          file.isMain = false;
        }
      });
      const newMainFile = this.array.value[index];
      if (newMainFile) {
        newMainFile.isMain = true;
      }
    }
  }
}
