import { Component, Inject, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ArticlesResponse } from 'src/app/cms/features/articles/models/sale.model';
import { GalleryImage } from 'src/app/shared/models/gallery-image.model';
import { StaticService } from 'src/app/shared/services/static.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-article-card-vertical',
  templateUrl: './article-card-vertical.component.html',
  styleUrls: ['./article-card-vertical.component.scss']
})
export class ArticleCardVerticalComponent {
  @Input() articlesData!: ArticlesResponse;
  @Input() saleType!: string;
  galleryImages: GalleryImage[] = [];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    public staticService: StaticService,
    private router: Router
  ) {}

  ngOnInit(): void {}
}
