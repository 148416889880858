import { Injectable } from '@angular/core';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { ApiService } from 'src/app/core/api/api.service';
import { SubscriptionResponse } from '../models/subscription.model';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionApiService extends ApiService {
  apiPrefix = '/api/v1/payment-for-subscription';
  apiPrefix2 = '/api/v1/user/cancel-subscription';

  getSubscriptionLimits(
    subscriptionInternalName: string
  ): Promise<ApiResponseModel<SubscriptionResponse>> {
    const request = this.get<ApiResponseModel<SubscriptionResponse>>(
      `${this.apiPrefix}/limits/${subscriptionInternalName}`,
      null
    );

    return lastValueFrom(request);
  }


  cancelSubscriptions(): Promise<any> {
    const request = this.delete<any
    >(this.apiPrefix2, null);
    return lastValueFrom(request);
  }
}
