<div class="field-wrapper">
    <div class="field" [ngClass]="{'isDisabled': disabled, 'valueChosen': isAddingNew || valueSelected}">
        <label *ngIf="label">{{label | translate}}
            <span *ngIf="isRequired" class="required">*</span>
        </label>
        <input #inputControl [type]="type" [placeholder]="placeholder | translate" [formControl]="nameControl"
            [matAutocomplete]="auto" (keyup)="onManualChange()" (blur)="onInputBlur($event)" [disabled]="true">
        <button  #addButton type="button" class="btn btn-primary input-add-btn" [class]="disabled || valueSelected ? 'disabled' : ''" (click)="onAddButton()">
            <ng-template [ngIf]="isAddingNew" [ngIfElse]="addSpan">
                <svg-icon src="assets/icons/x.svg" [svgStyle]="{ 'fill': 'white', 'height.px' : 12 }"></svg-icon>
                <span>{{ 'common.buttons.cancel' | translate }}</span>
            </ng-template>

            <ng-template #addSpan>
                <svg-icon src="assets/icons/plus.svg" [svgStyle]="{ 'fill': 'white', 'height.px' : 12 }"></svg-icon>
                <span>{{ 'common.buttons.add' | translate }}</span>
            </ng-template>
        </button>
        <svg-icon *ngIf="formControl.value" class="input-clear" (click)="onClear()" src="/assets/icons/x.svg"
            [svgStyle]="{ 'fill': '#000', 'height.px' : 18, 'width.px' : 18 }"></svg-icon>
    </div>
    <div class="error">
        <mat-error *ngIf="control?.touched && control.status === 'INVALID'">
            {{ errorHandler(control) | translate }}
        </mat-error>
    </div>
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onValueSelect($event)">
        <ng-template [ngIf]="!isAddingNew">
            <mat-option *ngIf="isLoading" class="spinner-option" disabled>
                <atom-spinner [diameter]="'25'"></atom-spinner>
            </mat-option>
            <mat-option *ngFor="let option of filteredOptions" [value]="option">
                {{ option.name }}
            </mat-option>
            <mat-option *ngIf="isLoadingMore" class="spinner-option" disabled>
                <atom-spinner [diameter]="'25'"></atom-spinner>
            </mat-option>
        </ng-template>
    </mat-autocomplete>
</div>
