import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderElementsColor } from 'src/app/shared/utils/common';

@Component({
  selector: 'app-header-login',
  templateUrl: './header-login.component.html',
  styleUrls: ['./header-login.component.scss'],
})
export class HeaderLoginComponent {
  @Input() color: HeaderElementsColor = 'black';

  constructor(private router: Router) {}

  onLogin() {
    this.router.navigateByUrl('/auth/login');
  }
}
