import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButtonModule } from '@angular/material/button';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTabsModule } from '@angular/material/tabs';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDatepickerModule } from '@angular/material/datepicker';
import {
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
} from '@angular-material-components/datetime-picker';
import {
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatNativeDateModule,
} from '@angular/material/core';
import {
  DEFAULT_LOCALE,
  DEFAULT_MAT_DATE_FORMAT,
} from '../shared/utils/common';

const modules = [
  CommonModule,
  MatInputModule,
  MatCheckboxModule,
  MatButtonModule,
  MatSnackBarModule,
  MatProgressSpinnerModule,
  MatTabsModule,
  MatMenuModule,
  MatTableModule,
  DragDropModule,
  MatDialogModule,
  MatAutocompleteModule,
  MatSelectModule,
  MatSidenavModule,
  MatDatepickerModule,
  MatNativeDateModule,
  NgxMatDatetimePickerModule,
  NgxMatTimepickerModule,
  NgxMatNativeDateModule,
];

@NgModule({
  declarations: [],
  imports: [modules],
  exports: [modules],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: DEFAULT_MAT_DATE_FORMAT },
    { provide: MAT_DATE_LOCALE, useValue: DEFAULT_LOCALE },
  ],
})
export class MaterialModule {}
