<button (click)="onClick()" [disabled]="disabled" [type]="type"
    [ngClass]="{'default': theme === 'default', 'submit': theme === 'submit', 'action': theme === 'action', 'option': theme === 'option',
'cancel': theme === 'cancel', 'delete': theme === 'delete', 'save': theme === 'save',
'warning': theme === 'warning', 'white': theme === 'white', 'notification': theme === 'notification', 'loading': isLoading}">
    <img *ngIf="icon" height="20" [src]="icon">
    <ng-container *ngIf="!isLoading; else loader">
        <span>{{label | translate}}</span>
    </ng-container>
    <ng-template #loader>
        <mat-spinner [diameter]="25"></mat-spinner>
    </ng-template>
</button>