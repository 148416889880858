import { Injectable } from '@angular/core';
import { ApiResponseModel } from 'src/app/core/api/api-response.model';
import { ApiService } from 'src/app/core/api/api.service';
import {
  Dictionary,
  DictionaryWord,
  DictionaryWordCreateRequest,
  DictionaryWordFull,
  DictionaryWordUpdateRequest,
} from '../models/dictionary.model';
import { lastValueFrom } from 'rxjs';
import { ApiPagingResponseModel } from 'src/app/core/api/api-paging-response.model';
import {
  CategoryCreateRequest,
  CategoryUpdateRequest,
} from 'src/app/cms/admin/modules/admin-categories/models/category.model';
import { SubcategoryCreateRequest } from 'src/app/cms/admin/modules/admin-categories/models/subcategory.model';
import { MetadataTypeForSubcategoryCreateRequest } from 'src/app/cms/admin/modules/admin-categories/models/metadata-type-for-subcategory.model';

@Injectable({
  providedIn: 'root',
})
export class DictionaryApiService extends ApiService {
  apiPrefix = '/api/v1/dictionary';

  getCurrencies(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/currency`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectTypes(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/subject-type`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectStyles(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/subject-style`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectStatuses(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/subject-status`,
      null
    );
    return lastValueFrom(request);
  }

  getPhotoTypes(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/photo-type`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectMetadataTypesBySubjectTypeUid(
    subjectTypeUid: string
  ): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/subject-metadata-type/${subjectTypeUid}`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectCategories(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/subject-category`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectSubcategories(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/subject-subcategory`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectSubcategoriesByCategory(
    categoryUid: string
  ): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/subject-subcategory-for-category/${categoryUid}?fullDto=true`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectMetadataTypes(): Promise<
    ApiResponseModel<ApiPagingResponseModel<DictionaryWord>>
  > {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<DictionaryWord>>
    >(`${this.apiPrefix}/subject-metadata-type`, null);
    return lastValueFrom(request);
  }

  getSubjectMetadataTypesBySubcategory(
    subjectSubcategoryUid: string
  ): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/subject-metadata-type-for-subcategory/${subjectSubcategoryUid}`,
      null
    );
    return lastValueFrom(request);
  }

  getSaleStatuses(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/sale-status`,
      null
    );
    return lastValueFrom(request);
  }

  getSaleTypes(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/sale-type`,
      null
    );
    return lastValueFrom(request);
  }

  getSaleUnitTypes(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/sale-unit-type`,
      null
    );
    return lastValueFrom(request);
  }

  getLanguages(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/languages`,
      null
    );
    return lastValueFrom(request);
  }

  getSubscriptions(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/subscription`,
      null
    );
    return lastValueFrom(request);
  }

  // metody do zarządzania
  getSubjectCategoriesAdvanced(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<DictionaryWord>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<DictionaryWord>>
    >(`${this.apiPrefix}/subject-category/find` + query, null);
    return lastValueFrom(request);
  }

  getSubjectSubcategoriesAdvanced(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<DictionaryWord>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<DictionaryWord>>
    >(`${this.apiPrefix}/subject-subcategory/find` + query, null);
    return lastValueFrom(request);
  }

  getSubjectCategory(
    subjectCategoryUid: string
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.get<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-category/${subjectCategoryUid}?fullDto=true`,
      null
    );
    return lastValueFrom(request);
  }

  getSubjectSubcategory(
    subjectSubcategoryUid: string
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.get<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-subcategory/${subjectSubcategoryUid}?fullDto=true`,
      null
    );
    return lastValueFrom(request);
  }

  createSubjectCategory(
    subjectCategory: CategoryCreateRequest
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.post<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-category`,
      subjectCategory
    );
    return lastValueFrom(request);
  }

  createSubjectSubcategory(
    data: SubcategoryCreateRequest
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.post<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-subcategory`,
      data
    );
    return lastValueFrom(request);
  }

  updateSubjectCategory(
    subjectCategoryUid: string,
    data: CategoryUpdateRequest
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.put<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-category/${subjectCategoryUid}`,
      data
    );
    return lastValueFrom(request);
  }

  updateSubjectSubcategory(
    subjectSubcategoryUid: string,
    data: SubcategoryCreateRequest
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.put<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-subcategory/${subjectSubcategoryUid}`,
      data
    );
    return lastValueFrom(request);
  }

  deleteSubjectCategory(
    subjectCategoryUid: string
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.delete<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-category/${subjectCategoryUid}`,
      null
    );
    return lastValueFrom(request);
  }

  deleteSubjectSubcategory(
    subjectSubcategoryUid: string
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.delete<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-subcategory/${subjectSubcategoryUid}`,
      null
    );
    return lastValueFrom(request);
  }

  createSubjectMetadataTypeForSubcategory(
    data: MetadataTypeForSubcategoryCreateRequest
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.post<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-metadata-type-for-subcategory`,
      data
    );
    return lastValueFrom(request);
  }

  deleteSubjectMetadataTypeForSubcategory(
    subjectSubcategoryUid: string,
    metadataTypeUid: string
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.delete<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/subject-metadata-type-for-subcategory/${subjectSubcategoryUid}`,
      {
        subjectMetadataTypeUids: [metadataTypeUid],
      }
    );
    return lastValueFrom(request);
  }

  getDictionaries(
    query: string | null
  ): Promise<ApiResponseModel<ApiPagingResponseModel<Dictionary>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<Dictionary>>
    >(`${this.apiPrefix}` + query, null);
    return lastValueFrom(request);
  }

  getDictionaryWordsForDictionary(
    query: string | null,
    dictionaryUid: string
  ): Promise<ApiResponseModel<ApiPagingResponseModel<DictionaryWordFull>>> {
    const request = this.get<
      ApiResponseModel<ApiPagingResponseModel<DictionaryWordFull>>
    >(
      `${this.apiPrefix}/full-dictionary-words-for-dictionary${query}&dictionaryUid=${dictionaryUid}`,
      null
    );
    return lastValueFrom(request);
  }

  getDictionaryWord(
    dictionaryWordUid: string
  ): Promise<ApiResponseModel<DictionaryWordFull>> {
    const request = this.get<ApiResponseModel<DictionaryWordFull>>(
      `${this.apiPrefix}/full-dictionary-word/${dictionaryWordUid}`,
      null
    );
    return lastValueFrom(request);
  }

  createDictionaryWord(
    dictionaryWord: DictionaryWordCreateRequest
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.post<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/dictionary-word`,
      dictionaryWord
    );
    return lastValueFrom(request);
  }

  updateDictionaryWord(
    dictionaryWordUid: string,
    dictionaryWord: DictionaryWordUpdateRequest
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.put<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/dictionary-word/${dictionaryWordUid}`,
      dictionaryWord
    );
    return lastValueFrom(request);
  }

  deleteDictionaryWord(
    dictionaryWordUid: string
  ): Promise<ApiResponseModel<DictionaryWord>> {
    const request = this.delete<ApiResponseModel<DictionaryWord>>(
      `${this.apiPrefix}/dictionary-word/${dictionaryWordUid}`,
      null
    );
    return lastValueFrom(request);
  }

  getBusinessRoles(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/business-role`,
      null
    );
    return lastValueFrom(request);
  }

  getPublicRoles(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/public-role`,
      null
    );
    return lastValueFrom(request);
  }

  getAccessGroups(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/access-groups`,
      null
    );
    return lastValueFrom(request);
  }

  getRankingPeriods(): Promise<ApiResponseModel<DictionaryWord[]>> {
    const request = this.get<ApiResponseModel<DictionaryWord[]>>(
      `${this.apiPrefix}/ranking-period`,
      null
    );
    return lastValueFrom(request);
  }
}
