import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.scss'],
})
export class AdminSidebarComponent {
  @Output() click = new EventEmitter();

  constructor(private router: Router) {}

  onClick(route: string) {
    this.click.emit();
    this.router.navigate([`/admin/${route}`]);
  }
}
