<div class="dialog">
    <div class="dialog-header">
        <span> {{ 'subscription.dialog.title' | translate }} </span>
        <div class="dialog-close" (click)="onClose()">
            <svg-icon src="/assets/icons/x.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
        </div>
    </div>
    <div class="dialog-body">
        <div class="dialog-content">
            <atom-spinner *ngIf="isLoading"></atom-spinner>
            <ng-container *ngIf="!isLoading">
                <ng-container *ngIf="subscription">
                    <div class="details">
                        <div class="name">{{subscription.subscriptionInternalName}}</div>
                        <div class="description">
                            {{subscription.subscriptionDescription}}
                        </div>
                        <div class="price">
                            <span class="amount">{{subscription.amountForMonth}}</span>
                            <span class="currency">{{subscription.currencyInternalName}}</span>/<span
                                class="time-period">{{ 'subscription.timePeriodMonth' | translate }}</span>
                        </div>
                        <div class="price">
                            <span class="amount">{{subscription.amountForYear}}</span>
                            <span class="currency">{{subscription.currencyInternalName}}</span>/<span
                                class="time-period">{{ 'subscription.timePeriodYear' | translate }}</span>
                        </div>
                        <div class="charts">
                            <div class="title">
                                {{ 'subscription.dialog.charts.title' | translate }}
                            </div>
                            <ng-container *ngFor="let chart of subscribeFutures.chartFutures.allowed">
                                <div class="chart">
                                    {{ chart | translate}}
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let chart of subscribeFutures.chartFutures.notAllowed">
                                <div class="chart notAllowed" >
                                    {{ chart | translate}}
                                </div>
                            </ng-container>
                        </div>
                        <div class="reports">
                            <div class="title">
                                {{ 'subscription.dialog.reports.title' | translate }}
                            </div>
                            <ng-container *ngFor="let report of subscribeFutures.reportFutures.allowed">
                                <div class="report">
                                    {{ report | translate}}
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let report of subscribeFutures.reportFutures.notAllowed">
                                <div class="report notAllowed" >
                                    {{ report | translate}}
                                </div>
                            </ng-container>
                        </div>
                        <div class="transaction-types">
                            <div class="title">
                                {{ 'subscription.dialog.transactionTypes.title' | translate }}
                            </div>
                            <ng-container *ngFor="let transactionType of subscribeFutures.transactionTypeFutures.allowed">
                                <div class="transactionType">
                                    {{ transactionType | translate}}
                                </div>
                            </ng-container>
                            <ng-container *ngFor="let transactionType of subscribeFutures.transactionTypeFutures.notAllowed">
                                <div class="transactionType notAllowed" >
                                    {{ transactionType | translate}}
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

        </div>
    </div>
    <div *ngIf="showButtons()" class="dialog-actions">
        <div class="dialog-buttons-container">
            <div class="dialog-buttons">
                <atom-button label="common.buttons.buyForMonth" type="button" theme="submit" [isLoading]="isLoading"
                    (click)="buyMonthly(subscription)"></atom-button>
                <atom-button label="common.buttons.buyForYear" type="button" theme="submit" [isLoading]="isLoading"
                    (click)="buyAnnual(subscription)"></atom-button>
            </div>
        </div>
    </div>
</div>