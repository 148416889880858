<div class="content">
    <div class="actions">
        <ng-container *ngFor="let action of data">
            <div class="action"
                *ngIf="!action.requiredPermissionGroup || (action.requiredPermissionGroup && permissionService.hasPermission(action.requiredPermissionGroup))"
                [class.disabled]="action.disabled" (click)="click(action.type)">
                <ng-container *ngIf="action.type === 'fav'">
                    <ng-container *ngIf="action.isFav">
                        <svg-icon src="/assets/icons/heart_full_blue.svg" [svgStyle]="{ 'height.px' : 20 }"></svg-icon>
                        <span>{{ 'common.actions.removeFromFavorites' | translate }}</span>
                    </ng-container>
                    <ng-container *ngIf="!action.isFav">
                        <svg-icon src="/assets/icons/heart_black.svg" [svgStyle]="{ 'height.px' : 20 }"></svg-icon>
                        <span>{{ 'common.actions.addToFavorites' | translate }}</span>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="action.type === 'public'">
                    <ng-container *ngIf="action.isPublic">
                        <svg-icon src="/assets/icons/lock_open.svg" [svgStyle]="{ 'height.px' : 20 }"></svg-icon>
                        <span>{{ 'common.actions.hide' | translate }}</span>
                    </ng-container>
                    <ng-container *ngIf="!action.isPublic">
                        <svg-icon src="/assets/icons/lock.svg" [svgStyle]="{ 'height.px' : 20 }"></svg-icon>
                        <span>{{ 'common.actions.publish' | translate }}</span>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="action.type === 'share'">
                    <svg-icon src="/assets/icons/share.svg"
                        [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.share' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="action.type === 'sale'">
                    <svg-icon src="/assets/icons/sell.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.sale' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="action.type === 'rent'">
                    <svg-icon src="/assets/icons/rent.svg" [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.rent' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="action.type === 'valuation'">
                    <svg-icon src="/assets/icons/quotation.svg"
                        [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.valuation' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="action.type === 'wallet'">
                    <svg-icon src="/assets/icons/wallet.svg"
                        [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.wallet' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="action.type === 'pdf'">
                    <svg-icon src="/assets/icons/print.svg"
                        [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.print' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="action.type === 'auctionLive'">
                    <svg-icon src="/assets/icons/gavel.svg"
                        [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.auctionLive' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="action.type === 'collections'">
                    <svg-icon src="/assets/icons/collections.svg"
                        [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.collections' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="action.type === 'galleries'">
                    <svg-icon src="/assets/icons/gallery_board.svg"
                        [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.galleries' | translate }}</span>
                </ng-container>
                <ng-container *ngIf="action.type === 'reports'">
                    <svg-icon src="/assets/icons/report.svg"
                        [svgStyle]="{ 'fill': '#000', 'height.px' : 20 }"></svg-icon>
                    <span>{{ 'common.actions.galleries' | translate }}</span>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>