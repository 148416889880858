import { Component, OnInit } from '@angular/core';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
} from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/auth/services/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'ArtGuard';

  get userInfo() {
    return this.authService.userData;
  }

  constructor(translate: TranslateService, private authService: AuthService) {
    const browserLang = navigator.language;

    translate.setDefaultLang('en'); // fallback language
    
    if (browserLang.includes('pl')) {
      translate.use('pl');
    } else {
      translate.use('en'); // current language
    }
  }

  private dragoverHandler = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };
  private dropHandler = (event: DragEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  ngOnInit() {
    document.addEventListener('dragover', this.dragoverHandler);
    document.addEventListener('drop', this.dropHandler);
  }

  ngOnDestroy() {
    document.removeEventListener('dragover', this.dragoverHandler);
    document.removeEventListener('drop', this.dropHandler);
  }
}
