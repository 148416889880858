import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ScraperResponse } from '../models/scraper.model';

@Injectable({
  providedIn: 'root',
})
export class ScraperApiService {
  constructor(private http: HttpClient) {}

  public post(url: string, body: any, queryParams: any): Promise<ScraperResponse> {
    const httpOptions = {
      headers: {
        Authorization: 'Bearer ' + environment.scrapersApi.token,
      },
    };
    return lastValueFrom(
      this.http.post<ScraperResponse>(
        environment.scrapersApi.url + url + queryParams,
        body,
        httpOptions
      )
    );
  }
}
