import { Component, Inject } from '@angular/core';
import { StripeService } from '../../services/stripe.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SubscriptionMappedResponse, SubscriptionFutures } from '../../models/subscription.model';
import { UserInfoResponse } from 'src/app/auth/models/user.model';

@Component({
  selector: 'app-subscription-dialog',
  templateUrl: './subscription-dialog.component.html',
  styleUrls: ['./subscription-dialog.component.scss'],
})
export class SubscriptionDialogComponent {
  isLoading: boolean = false;
  subscription!: SubscriptionMappedResponse;
  subscribeFutures!: SubscriptionFutures;
  userData!: UserInfoResponse;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      subscription: SubscriptionMappedResponse;
      userData: UserInfoResponse;
      subscribeFutures: SubscriptionFutures;
    },
    private stripeService: StripeService,
    private dialogRef: MatDialogRef<SubscriptionDialogComponent>
  ) {}

  ngOnInit(): void {
    this.setDialogData();
  }

  setDialogData() {
    this.subscription = this.data.subscription;
    this.userData = this.data.userData;
    
    let futuresList = {
      charts: {all:  this.subscription.allChartsTranslated, allowed: this.subscription.allowedChartsTranslated},
      report: {all:  this.subscription.allReportsTranslated, allowed: this.subscription.allowedReportsTranslated},
      transactions: {all:  this.subscription.allTransactionTypesTranslated, allowed: this.subscription.allowedTransactionTypesTranslated}
    }

    this.subscribeFutures = {
      chartFutures: {
        allowed: futuresList.charts.all.filter(element => futuresList.charts.allowed.includes(element)), 
        notAllowed:  futuresList.charts.all.filter(element => !futuresList.charts.allowed.includes(element)),
      },
      reportFutures: {
        allowed: futuresList.report.all.filter(element => futuresList.report.allowed.includes(element)), 
        notAllowed:  futuresList.report.all.filter(element => !futuresList.report.allowed.includes(element)),
      },
      transactionTypeFutures: {
        allowed: futuresList.transactions.all.filter(element => futuresList.transactions.allowed.includes(element)), 
        notAllowed:  futuresList.transactions.all.filter(element => !futuresList.transactions.allowed.includes(element)),
      },
    }
  }

  showButtons(): boolean {
    const isFree =
      this.subscription.amountForMonth === 0 &&
      this.subscription.amountForYear === 0;
    const isCurrent = this.userData.currentActiveSubscriptions.includes(
      this.subscription.subscriptionInternalName
    );
    return !isFree && !isCurrent;
  }

  isTransactionFuture(type : "chart" | "report" | "transaction", name: string): boolean {
    switch (type) {
      case "chart":
        return this.subscription.allowedChartsTranslated.includes(name);
      case "report":
        return this.subscription.allowedReportsTranslated.includes(name);
      case "transaction":
        return this.subscription.allowedTransactionTypesTranslated.includes(name);
    }
}

  buyMonthly(subscription: SubscriptionMappedResponse) {
    const paymentLink = this.stripeService.buyMonthly(
      subscription.subscriptionInternalName
    );
    window.open(
      `${paymentLink}?prefilled_email=${this.userData.email}`,
      '_blank'
    );
  }

  buyAnnual(subscription: SubscriptionMappedResponse) {
    const paymentLink = this.stripeService.buyAnnual(
      subscription.subscriptionInternalName
    );
    window.open(
      `${paymentLink}?prefilled_email=${this.userData.email}`,
      '_blank'
    );
  }

  onClose() {
    this.dialogRef.close();
  }
}
