import { Component, Input } from '@angular/core';
import { TileUserInfoData } from 'src/app/shared/models/tile.model';

@Component({
  selector: 'tile-user-info',
  templateUrl: './tile-user-info.component.html',
  styleUrls: ['./tile-user-info.component.scss'],
})
export class TileUserInfoComponent {
  @Input() data!: TileUserInfoData;
  @Input() navigateToList?: 'sales' | 'gallery';
}
